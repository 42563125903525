import Vuex from 'vuex'
import Vue from 'vue'

//Modules
import {auth} from './store/auth'
import {user} from './store/user'
import {caravan} from "@/store/caravan";
import {snackbar} from "@/store/snackbar";
import {poi} from "@/store/poi";
import { routerLocation } from "@/store/routerLocation";
import {footer} from "@/store/footer";
import {easydriver} from "@/store/easydriver";
import {sensors} from "@/store/sensors";
import {poiComments} from "@/store/poiComments";

Vue.use(Vuex)
export const store = new Vuex.Store({
    modules: {
        "auth": auth,
        "user": user,
        "caravan": caravan,
        "snackbar": snackbar,
        "poi": poi,
        "routerLocation": routerLocation,
        "footer": footer,
        "easydriver": easydriver,
        "sensors": sensors,
        "poiComments": poiComments
    }
})


var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.issueData !== '')?_c('v-container',[_c('dialog-custom',{ref:"dialog"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Detailed description'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" ")])],1)],1),_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticStyle:{"width":"30%"}},[_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t('Filter by issue status'))+" ")]),_c('v-select',{attrs:{"items":_vm.filters},on:{"change":function($event){return _vm.fetchNewPage(true)}},model:{value:(_vm.paginationInfo.filter),callback:function ($$v) {_vm.$set(_vm.paginationInfo, "filter", $$v)},expression:"paginationInfo.filter"}})],1)])]),_c('v-container',[_c('v-layout',{staticStyle:{"height":"75vh"},attrs:{"column":""}},[_c('div',{staticStyle:{"overflow":"auto"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.issueData,"hide-default-footer":"","items-per-page":_vm.issueData.length},scopedSlots:_vm._u([{key:"item.message",fn:function(ref){
var item = ref.item;
return [(item.message.length < 100)?_c('span',[_vm._v(" "+_vm._s(item.message)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.message))+" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.displayDialog(item)}}},[_vm._v("mdi-dots-horizontal")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ma-4",on:{"click":function($event){return _vm.fireDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.resolver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"150px"}},[(!item.issueResolver || !item.resolved)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('This problem has not been solved by anyone'))+" ")]):_c('span',[_vm._v(_vm._s(item.issueResolver.user_email))])])]}},{key:"item.resolved",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticStyle:{"width":"150px"},attrs:{"input-value":!item.resolved,"color":"red","label":_vm.getStatus(item),"success":item.resolved},on:{"click":function($event){return _vm.updateIssue(item)}}})]}}],null,false,167752941)})],1)])],1),(_vm.issueToDelete)?_c('v-row',{staticClass:"justify-center"},[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"320"}},[_c('dialog-confirmer',{attrs:{"positive":_vm.$t('Delete issue'),"negative":_vm.$t('Dismiss'),"title":_vm.$t('Delete issue'),"text":_vm.$t('Do you really want to delete the problem?')},on:{"result":function (res) { return _vm.onDialogResult(res); }}})],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container id="login" class="pa-0 ma-0" fluid>

    <div>
      <ForgotPassword :dialogVisible="dialogVisible" @close="dialogVisible=false"/>
    </div>
    <v-row class="fill-height pa-0 ma-0">
      <v-col class="col-md-7 col-12 fill-height login_picture pa-0" :style="bgImage">
        <v-container class="ma-auto" fluid text-xs-center fill-height>
          <v-layout row wrap align-center>
            <v-fade-transition :enter="5000">
              <div class="logo_container">
                <h1 class="logo_text">MyCaravan</h1>
              </div>
            </v-fade-transition>

            <div class="footer" :style="getStyle">
              <p>Powered by</p>
              <div class="image_container">
                <img src="@/assets/solvesall_logo.png" class="image">
              </div>
            </div>
          </v-layout>
        </v-container>
      </v-col>
      <v-col class="col-md-5 col-12 fill-height form_content pa-0">
          <v-container class="main_content_header">
            <h1 class="ml-3 main_content_header_title pa-1 mt-15">
              {{$t('Log in')}}
            </h1>
          </v-container>
          <v-container fluid class="mt-15">
            <template>
              <v-form
                  ref="form"
                  lazy-validation
              >
                <v-text-field
                    rounded
                    class="ma-5"
                    outlined
                    v-model="email"
                    :label="$t('Email address')"
                    autocomplete="null"
                    placeholder=" "
                    persistent-placeholder
                    required
                ></v-text-field>

                <v-text-field
                    rounded
                    class="ma-5"
                    type="password"
                    autocomplete="null"
                    placeholder=" "
                    persistent-placeholder
                    outlined
                    v-model="password"
                    :label="$t('Password')"
                    required
                ></v-text-field>
                <p class="ma-5 main_content_reset_password" @click="dialogVisible = true">
                  {{$t('Forgot your password')}}
                </p>

                <v-container class="main_content_button_container">
                  <v-btn class="ma-auto main_content_login_button" @click="loginUser" rounded >
                    {{$t('SIGN UP')}}
                  </v-btn>
                </v-container>
              </v-form>

            </template>
          </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ForgotPassword from "@/components/ForgotPassword";
  export default {
    name: 'HelloWorld',
    components: {ForgotPassword},
    data: () => ({
      "email": "",
      "password": "",
      "error": false,
      "errorMessage": "",
      "logo": false,
      "dialogVisible": false,
      "style": {
        "large": {
          "width": '60%'
        },
        "small": {
          "width": '100%',
          "position": 'relative',
          "margin-top": '100%'
        }
      }
    }),
    methods: {
      "loginUser": function () {
        this.$store.dispatch("auth/loginUser", {email: this.email, password:this.password})
            .then(() => {
              this.$router.push({
                path: "/dashboard"
              })
            })
            .catch(() => {
              this.$store.dispatch("auth/logoutUser")
              .then(() => {
                this.$store.dispatch('snackbar/fireAlert', {
                  message: this.$t('Login could not be performed'),
                  color: 'error'
                })
              })
            })
      }
    },
    mounted() {
      setTimeout(()=>{
        this.logo = true
      },500)
    },
    computed: {
      getStyle() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return this.style.small
          case 'sm':
            return this.style.small
          case 'md':
            return this.style.large
          case 'lg':
            return this.style.large
          case 'xl':
            return this.style.large
          default:
            return this.style.large

        }
      },
      bgImage() {
        return {
          backgroundImage: `url(${process.env.VUE_APP_API_URL}/static/background.jpeg)`
        };
      }
    }
  }
</script>

<style scoped>

  a {
    text-decoration: none;
  }

  .logo_text {
    text-align: center;
    color: white;
    font-weight: lighter;
    font-size: 4rem;
  }
  .main_content_header {

  }
  .main_content_reset_password {
    text-decoration: none;
    font-size: small;
    font-weight: normal;
    color: gray;
  }
  .main_content_reset_password:hover{
    cursor: pointer;
    font-weight: bolder;
  }

  .main_content_header_title {
    text-align: center;
    color: gray;
    font-weight: lighter;
    font-size: 4em ;
  }
  .main_content_button_container {
    margin-left: 25%;
  }
  .main_content_login_button {
    width: 50%;
  }
  .login_picture{
    background-position: center;
    background-size: cover;
    height: 100vh
  }
  .form_content {
    background-color: whitesmoke;
    height: 100vh;
  }
  #login {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
  }
  .footer {
    position: absolute;
    bottom: 0;
    float: bottom;
    text-align: center;
    color: white;
  }
  .image_container {
    width: 100%;
    margin: auto;
  }
  .image {
    max-width: 200px;
  }
  .logo_container {
    text-align: center;
    width: 100%;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.initialized)?_c('v-container',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"max-width":"500px"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Add new document group'))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"col-lg-8",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"required":"","label":_vm.$t('Title of the document group')},model:{value:(_vm.documentGroup.title),callback:function ($$v) {_vm.$set(_vm.documentGroup, "title", $$v)},expression:"documentGroup.title"}}),_c('v-text-field',{attrs:{"required":"","label":_vm.$t('Document file name Document')},on:{"keyup":_vm.snakeCase},model:{value:(_vm.documentGroup.filename),callback:function ($$v) {_vm.$set(_vm.documentGroup, "filename", $$v)},expression:"documentGroup.filename"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"ma-4",attrs:{"disabled":!_vm.legalDocument},on:{"click":_vm.addNewDocumentGroup}},[_vm._v(" "+_vm._s(_vm.$t('ADD NEW GROUP'))+" ")]),_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" "+_vm._s(_vm.$t('DISMISS'))+" ")])],1)],1)],1),_c('v-container',[_c('v-layout',{staticStyle:{"height":"85vh"},attrs:{"column":""}},[_c('div',{staticStyle:{"overflow":"auto"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{staticClass:"elevation-1 cursorTable",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.documentArray,"items-per-page":_vm.documentArray.length,"hide-default-footer":"","loading":!_vm.initialized},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_c('v-text-field',{on:{"keydown":function($event){item.change=true}},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["id"])+" ")])]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["filename"])+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.change)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.editDocument(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!item.change)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.fireDialogConfirm(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.change)?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.updateDocumentGroup(item)}}},[_vm._v(" mdi-content-save-outline ")]):_vm._e()]}}],null,false,1977284669)})],1)])],1),_c('div',{staticClass:"button_container"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"#282424"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-dialog-bottom-transition',[_c('dialog-confirmer',{attrs:{"title":_vm.$t('Are you sure you want to delete the entire document group?'),"text":_vm.$t('Group delete warning'),"positive":_vm.$t('DELETE'),"negative":_vm.$t('DISMISS')},on:{"result":function (res) { return _vm.deleteDocumentGroup(res); }}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
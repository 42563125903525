<template>
  <div class="wrapper">
    <div class='mobile-phone'>
      <div class='brove'><span class='speaker'></span></div>
      <div class='screen'>
        <v-row class="pushNotification">
          <v-col class="col-lg-10" style="background-color: white; max-height: 25%">
            <p class="pa-0 ma-0"><b class="notificationTitle">
              {{notificationOBJ.pushNotificationTitle !== '' ? notificationOBJ.pushNotificationTitle : $t('Push notification title')}}
            </b></p>
            <p class="pa-0 ma-0 notificationText">
              {{notificationOBJ.pushNotificationContent !== '' ? notificationOBJ.pushNotificationContent : $t('Push notification content')}}
            </p>
          </v-col>
          <v-col class="col-lg-2" style="background-color: white; max-height: 25%">
            <v-img src="https://www.gstatic.com/mobilesdk/180130_mobilesdk/images/image_placeholder.png" height="50px" width="50px">
            </v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobilePhone",
  props: [
      'notificationOBJ'
  ]
}
</script>

<style scoped>
.wrapper {
  max-height: 150px;
  overflow-y: hidden;
}
h1 {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50px;
}

.mobile-phone {
  margin: auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  width: 350px;
  height: 400px;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
}

.pushNotification {
  padding: 7px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.notificationTitle {
  font-size: 0.75rem;
}
.notificationText {
  font-size: 0.75rem;
}

.screen {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

.brove {
  width: 150px;
  height: 20px;
  background: white;
  position: absolute;
  margin: 0 100px;
  border-radius: 0 0 20px 20px;
}

.speaker {
  width: 60px;
  height: 5px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 20px;
}

</style>

<template>
  <v-card>
    <div style="overflow: auto" v-if="easydriver_logs">
      <v-data-table
          :headers="headers"
          :items="easydriver_logs"
          :items-per-page="easydriver_logs.length"
          hide-default-footer
          class="elevation-1 cursorTable"
          selectable-key="id"
      >

        <template v-slot:item.actions="{ item }">
          <v-icon
              class="ml-2"
              @click="deleteLogs(item)">
            mdi-delete
          </v-icon>
          <v-btn
              class="ma-2"
              outlined
              icon
              @click="downloadFile(item)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.time_created="{ item }" @click.stop>
          <table-entry>
            {{item["time_created"]}}
          </table-entry>
        </template>
      </v-data-table>
    </div>
    <v-card-actions class="justify-center">
      <v-btn class="ma-5 error" @click="emitClose">
        {{$t('DISMISS')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  name: "LogsModal",
  props: ["caravanId"],
  data: function () {
    return{
      "headers": [
        { text: this.$t('Date'), value: 'time_created'},
        { text: this.$t('Actions'), value: 'actions'}
      ],
      easydriver_logs: [],
    }
  },
  async mounted() {
    this.easydriver_logs = (await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${this.$props.caravanId}/easydriver-logs`)).data
  },
  methods: {
    async deleteLogs(item) {
      let delete_data = {filename: item.filename, caravanId: this.$props.caravanId}
      await this.$store.dispatch('caravan/deleteLogs', delete_data)
      this.easydriver_logs = this.easydriver_logs.filter(it => it.filename !== item.filename)
    },
    emitClose() {
      this.$emit('onClose', true);
    },
    async downloadFile(item) {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-carvan-profile/download/${this.$props.caravanId}/${item.filename}` , {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
          'Content-Disposition': 'form-data'
        },
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          const currentProgress = Math.round( (progressEvent.loaded * 100) / totalLength )
          this.loadingValue = currentProgress
        }

      })
      // TODO: Test if .bin files are correctly processed
      var blob = new Blob([res.data])
      saveAs(blob, item.filename)
    }
  }
}
</script>

<style scoped>

</style>
import httpClient from "@/utils/httpClient";

/**
 * Gets easydriver caravan profile
 * @param caravanId - caravan identifier
 */
export const getEasydriverCaravanProfile = async (caravanId) => {
    try {
        const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${caravanId}`)
        return res.data
    }catch (e) {
        return null
    }
}

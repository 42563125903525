<template>
  <v-card class="overflow-y-auto overflow-x-hidden" max-width="500px">
    <v-card-title>
      {{$t('Update document')}}
    </v-card-title>
    <v-row class="justify-center">
      <v-col class="col-lg-8" cols="12">
        <v-form ref="createDocumentForm">
          <v-file-input
              accept="image/png, image/jpeg, image/jpg"
              :placeholder="$t('Pick a thumbnail')"
              prepend-icon="mdi-camera"
              :label="$t('Thumbnail')"
              v-model="uploadData.thumbnail"
          />
          <v-file-input
              v-model="uploadData.document"
              accept=".pdf"
              :placeholder="$t('Pick a document')"
              prepend-icon="mdi-pdf-box"
              :label="$t('Document')"
          />
        </v-form>

      </v-col>
      <v-card-actions class="justify-center">
        <v-btn class="ma-2" @click="uploadDocument">
          {{$t('Update document')}}
        </v-btn>
        <v-btn class="ma-2" @click="$emit('close', true)">
          {{$t('Dismiss')}}
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-card-actions class="justify-center mt-5" v-if="loadingValue>0">
      <v-progress-circular
          class="ma-auto"
          :rotate="360"
          :size="100"
          :width="15"
          :value="loadingValue"
          color="teal"
      >
        {{loadingValue}}
      </v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UploaderModal",
  props: ['docOBJ'],
  data: function () {
    return {
      'uploadData': {
        'document': '',
        'thumbnail': ''
      },
      loadingValue: 0
    }
  },
  methods: {
    emitData() {
      this.$emit('data', this.uploadData)
    },
    async uploadDocument() {
      const iso_old = this.docOBJ.document_lang_iso.iso
      const iso_new = this.docOBJ.updateOBJ.iso.iso

      const formData = new FormData()
      if (this.uploadData.thumbnail) {
        formData.append('preview', this.uploadData.thumbnail)
      }
      if (this.uploadData.document) {
        formData.append('document', this.uploadData.document)
      }

      const res = await this.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-documents/${this.docOBJ.document_uuid}/${iso_old}?newIso=${iso_new}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          const currentProgress = Math.round( (progressEvent.loaded * 100) / totalLength )
          this.loadingValue = currentProgress
        }
      })
      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('New document successfully added'),
        color: 'success'
      })
      this.$emit('success', res.data)
      this.$emit('close')
      this.loadingValue = 0

    }
  }
}
</script>

<style scoped>

</style>

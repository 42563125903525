export const routerLocation = {
    namespaced: true,

    state: () => ({
        position: 0
    }),
    mutations: {
        changePosition(state, index) {
            state.position = index
        }
    },
    actions: {
        changePosition(context, index) {
            context.commit('changePosition', index)
        }
    },
    getters: {
        position: state => state.position
    }

}



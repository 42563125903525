var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.initialized)?_c('div',{staticClass:"mt-15"},[_c('v-container',[_c('v-layout',{staticStyle:{"height":"80vh"},attrs:{"column":""}},[_c('div',{ref:"myScroll",staticStyle:{"overflow":"auto"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{staticClass:"elevation-1 cursorTable",attrs:{"headers":_vm.headers,"items":_vm.commentsArr,"items-per-page":_vm.commentsArr.length,"hide-default-footer":"","selectable-key":"id","loading":!_vm.initialized},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.fireDeleteDialog(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.navigateToPoiInfo(item)}}},[_vm._v(" mdi-map-marker-outline ")])]}},{key:"item.poi_rating_stars",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{staticClass:"table_entry",attrs:{"value":item.poi_rating_stars,"color":"amber","dense":"","half-increments":"","readonly":"","size":"15"},on:{"click":function (e) { return e.stopPropagation(); }}})]}},{key:"item.poi_rating_text",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.poi_rating_text,50, ' ...'))+" ")])]}},{key:"item.user_login",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item['user_login'])+" ")])]}},{key:"item.poi_name",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item['poi_name'])+" ")])]}}],null,false,3887057638)})],1)])],1)],1):_vm._e(),(_vm.commentToDelete)?_c('v-row',{staticClass:"justify-center"},[_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"350"}},[_c('dialog-confirmer',{attrs:{"positive":_vm.$t('Delete comment'),"negative":_vm.$t('Dismiss'),"title":_vm.$t('Delete comment'),"text":_vm.$t('Do you really want to delete the comment?')},on:{"result":function (res) { return _vm.onDialogResult(res); }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
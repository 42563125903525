<template>
  <div>
    <DialogConfirmerPromise
        ref="mDecisionDialog"
    />
    <DialogConfirmerPromise
        ref="deleteDialog"
    />
    <v-row class="align-center pa-0 ma-0 mt-3">
      <v-col class="col-lg-6">
        <v-select
            :loading="!easyDriverTypes"
            :placeholder="$t('Easydriver hardware type')"
            :label="$t('Easydriver hardware type')"
            color="rgba(0, 0, 0, 0.5)"
            :items="easyDriverTypes ? easyDriverTypes : []"
            @change="handleEasyDriverChange"
            :value="easyDriverType ? easyDriverType : ''"
            return-object
            item-text="type"
            clearable
        >
          <template v-slot:selection="{ item }">
            {{getTypeString(item.type)}}
          </template>
          <template v-slot:item="{item}">
            {{getTypeString(item.type)}}
          </template>
        </v-select>
      </v-col>
      <v-col class="col-lg-5 side_title">
        <v-icon small>
          mdi-information
        </v-icon>
        <span class="ml-1">
          {{$t("Select easy driver type")}}
        </span>
      </v-col>
    </v-row>
    <v-data-table
        v-if="fwData"
        :headers="headers"
        :items="fwData"
        :items-per-page="fwData.length"
        hide-default-footer
    >

      <template v-slot:item.value="{ item }">
        <span class="idItem">
          {{ item.version }}
        </span>
      </template>

      <template v-slot:item.date="{ item }">
        <span class="idItem">
          {{ getHumanReadableDate(item.date) }}
        </span>
      </template>

      <template v-slot:item.easyDriverType="{ item }">
        <span class="idItem">
          {{ getTypeString(item.easyDriverType.type) }}
        </span>
      </template>

      <template v-slot:item.updateType="{ item }">
        <span class="idItem">
          {{ item.updateType.type }}
        </span>
      </template>

      <template v-slot:item.userReady="{ item }">
        <div style="display: flex; align-items: center; justify-content: center">
          <ReichCheckbox
              :value="item.userReady"
              text=""
              @valueChange="() => handleUserReadyChange(item)"
          />
        </div>
      </template>

      <template v-slot:item.serviceReady="{ item }">
        <div style="display: flex; align-items: center; justify-content: center">
          <ReichCheckbox
              :value="item.serviceReady"
              text=""
              @valueChange="() => handleServiceReadyChange(item)"
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            icon
            @click="issueFwDownload(item)"
        >
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>

        <v-btn
            icon
            @click="issueFwDelete(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

  </div>
</template>

<script>
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
import {formatToHuReadable} from "@/utils/date";
import ReichCheckbox from "@/components/DismissableCheckpoint";
import {hardwareTypeString} from "@/utils/shared";

export default {
  name: "EasyDriverFirmware",
  components: {ReichCheckbox, DialogConfirmerPromise},
  props: ['easyDriverTypes', 'easyDriverType', 'fwData'],
  methods: {
    handleEasyDriverChange(item) {
      this.$emit('typeChange', item);
    },
    getHumanReadableDate(seconds) {
      return formatToHuReadable(seconds);
    },
    async issueFwDelete(item) {
      const result = await this.$refs.deleteDialog.fireAlert(
          this.$t('Yes'),
          this.$t('No'),
          this.$t('Deleting firmware version'),
          this.$t('Are you sure you want to delete firmware version ?')
      );
      if (result) this.$emit('fwDelete', item);
    },
    issueFwDownload(item) {
      this.$emit('fwDownload', item);
    },
    handleUserReadyChange(item) {
      this.$emit('userReadyChange', item)
    },
    handleServiceReadyChange(item) {
      this.$emit('serviceReadyChange', item)
    },
    getTypeString(item) {
      return hardwareTypeString(item)
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$t("Version"),
          value: 'version',
          align: 'start',
          sortable: false
        },
        {
          text: this.$t("Date"),
          value: 'date',
          sortable: false
        },
        {
          text: this.$t('Hardware type'),
          value: 'easyDriverType',
          sortable: false
        },
        {
          text: this.$t('Update type'),
          value: 'updateType',
          sortable: false
        },
        {
          text: this.$t('User ready'),
          value: 'userReady',
          sortable: false,
        },
        {
          text: this.$t('Service ready'),
          value: 'serviceReady',
          sortable: false,
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          sortable: false,
          align: 'center'
        }
      ]
    }
  }
}
</script>

<style scoped>
.side_title {
  font-weight: 400;
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.5);
}

.idItem {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}
</style>

export const poi = {
    namespaced: true,
    state: () => ({
        poiPaginationInfo: {
            "page": 1,
            "pageCount": 0,
            "itemPerPage": 6,
            "totalCount": 0,
            "initialized": false,
            "filter": 'all',
            "offset": 0,
            "limit": 10,
        },
        currentPOIs: [],
        chosenPOI: null,
        filters: {
            poiNameFilter: '',
            poiAuthorFilter: ''
        },
        scrollY: 0,
        controller: undefined
    }),
    mutations: {
        setPoi (state, payload) {
            state.poiPaginationInfo.page = Math.ceil((payload.offset - 1) / payload.limit) + 1
            state.poiPaginationInfo.pageCount = Math.ceil(payload.totalCount / payload.limit)
            state.poiPaginationInfo.totalCount = payload.totalCount
            state.poiPaginationInfo.initialized = true
            state.poiPaginationInfo.itemPerPage = payload.limit

            state.poiPaginationInfo.offset = payload.offset
            state.poiPaginationInfo.limit = payload.limit
            state.poiPaginationInfo.filter = payload.filter
        },
        setCurrentPOIs (state, payload) {
            state.currentPOIs = payload.currentPOIs
        },
        setChosenPOI(state, payload) {
            state.chosenPOI = payload.chosenPOI;
        },
        setFilters(state, { poiNameFilter, poiAuthorFilter }) {
            console.log('poi name filter', poiNameFilter, 'poi author filter', poiAuthorFilter)
            state.filters.poiNameFilter = poiNameFilter
            state.filters.poiAuthorFilter = poiAuthorFilter
        },
        setScrollY(state, scrollY) {
            state.scrollY = scrollY
        },
        cleanCache(state) {
            state.poiPaginationInfo = {
                "page": 1,
                    "pageCount": 0,
                    "itemPerPage": 6,
                    "totalCount": 0,
                    "initialized": false,
                    "filter": 'all',
                    "offset": 0,
                    "limit": 10,
            }
            state.currentPOIs = []
            state.chosenPOI = null
            state.filters = {
                poiNameFilter: '',
                poiAuthorFilter: ''
            }
            state.scrollY = 0
        },
        setController(state, controller) {
            state.controller = controller
        }
    },
    actions: {
        getPoi(context, payload) {
            const controller = new AbortController()
            if (context.state.controller) {
                context.state.controller.abort()
                context.commit('setController', undefined)
            }
            context.commit('setController', controller)

            return new Promise((resolve, reject) => {
                this._vm.$httpQuite
                    .get(`${process.env.VUE_APP_API_URL}/admin/easydriver-poi?offset=${payload.offset}&limit=${payload.limit}&filter=${payload.filter}&authorName=${encodeURIComponent(payload.authorName)}&poiName=${encodeURIComponent(payload.poiName)}`,
                        {withCredentials:true, signal: controller.signal})
                    .then((res) => {
                        context.commit('setPoi', {
                            offset: payload.offset,
                            limit: payload.limit,
                            users: res.data,
                            totalCount: parseInt(res.headers['x-total-count']),
                            filter: payload.filter
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        setPOIs(context, payload) {
            context.commit('setCurrentPOIs', payload)
        },
        setLoadFromCache(context, payload) {
            context.commit('setLoadFromCache', payload)
        },
        setChosenPOI(context, payload) {
            context.commit('setChosenPOI', payload);
        },
        setFilters(context, payload) {
            context.commit('setFilters', payload)
        },
        setScrollY(context, scrollY) {
            context.commit('setScrollY', scrollY)
        },
        cleanCache(context) {
            context.commit('cleanCache')
        }
    },
    getters: {
        paginationInfo: (state) => {
            return state.poiPaginationInfo;
        },
        chosenPOI: (state) => {
            return state.chosenPOI;
        },
        currentPOIs: (state) => {
            return state.currentPOIs;
        },
        filters: (state) => {
            return state.filters
        },
        scrollY: state => {
            return state.scrollY
        }
    }
}

<template>
  <v-container v-if="vehicles" class="mt-15">
    <DialogConfirmerPromise ref="dialog"/>
    <v-layout column style="height: 85vh">
      <div style="overflow: auto">
        <v-data-table
            :headers="headers"
            :items="vehicles"
            :items-per-page="vehicles.length"
            hide-default-footer
            class="elevation-1 cursorTable"
            selectable-key="id"
            @click:row="handleRowClick"
        >

          <template v-slot:item.actions="{ item }">
            <v-icon
                class="ml-2"
                @click.stop="deleteVehicle(item)">
              mdi-delete
            </v-icon>
            <v-icon
                class="ml-2"
                @click.stop="showInfo(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.caravan_profile_uuid="{ item }" @click.stop>
            <table-entry>
              {{item["caravan_profile_uuid"]}}
            </table-entry>
          </template>

          <template v-slot:item.caravan_profile_title="{ item }" @click.stop>
            <table-entry>
              {{item["caravan_profile_title"]}}
            </table-entry>
          </template>

          <template v-slot:item.caravan_profile_type="{ item }" @click.stop>
            <table-entry>
              {{item["caravan_profile_type"]}}
            </table-entry>
          </template>


        </v-data-table>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
import TableEntry from "@/components/TableEntry";
export default {
  name: "UserVehicles",
  components: {TableEntry, DialogConfirmerPromise},
  data: function () {
    return{
      "headers": [
        { text: this.$t('ID'), value: 'caravan_profile_uuid'},
        { text: this.$t('Title'), value: 'caravan_profile_title'},
        { text: this.$t('Type'), value: 'caravan_profile_type' },
        { text: this.$t('Actions'), value: 'actions'}
      ],
      vehicles: '',
    }
  },
  async mounted() {
    this.vehicles = (await this.$http.get(`${process.env.VUE_APP_API_URL}/coreuser-user/${this.$route.params.userId}/easydriver-caravan-profile`)).data
  },
  methods: {
    async deleteVehicle(item) {
      const res = await this.$refs.dialog.fireAlert(this.$t('Agree'),
          this.$t('Disagree'),
          this.$t('Do you really want to delete the vehicle?'),
          this.$t('Do you really want to delete the vehicle with the ID?', [item.caravan_profile_uuid])
      );
      if (res) {
        await this.$store.dispatch('caravan/deleteVehicle', item.caravan_profile_uuid)
        this.vehicles = this.vehicles.filter(it => it.caravan_profile_uuid !== item.caravan_profile_uuid)
      }
    },
    async showInfo(item) {
      await this.$router.push({name: 'VehicleInfo', params: {'vehicleOBJ': item, 'vehicleId': item.caravan_profile_uuid}})
    },
    handleRowClick(item) {
      this.showInfo(item)
    }
  }
}
</script>

<style scoped>

</style>

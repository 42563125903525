export const caravan = {
    namespaced: true,

    state: () => ({
        vehiclePaginationInfo: {
            "page": 0,
            "pageCount": 0,
            "itemsPerPage": 40,
            "totalCount": 0,
            "initialized": false
        },
        manufacturers: null,
        products: null,
        cachedValues: [],
        positionY: 0,
        namePattern: '',
        sensorPattern: ''
    }),
    mutations: {
        setVehicles (state, payload) {
            const currentPage = Math.ceil((payload.offset - 1)/ payload.limit) + 1
            state.vehiclePaginationInfo.page = currentPage

            state.vehiclePaginationInfo.pageCount = Math.ceil(payload.totalCount / payload.limit)
            state.vehiclePaginationInfo.totalCount = payload.totalCount
            state.vehiclePaginationInfo.initialized = true
            state.vehiclePaginationInfo.itemsPerPage = payload.limit
        },
        setManufacturers(state, manufacturers) {
            state.manufacturers = manufacturers
        },
        setProducts (state, products) {
            state.products = products
        },
        setCachedValues(state, values) {
            state.cachedValues = values
        },
        setPositionY(state, positionY) {
            state.positionY = positionY
        },
        cleanCache(state) {
            state.cachedValues = []
            state.positionY = 0
        },
        setPatterns(state, { namePattern, sensorPattern }) {
            state.namePattern = namePattern
            state.sensorPattern = sensorPattern
        }
    },
    actions: {
        uploadEasydriverUpdate(context, file) {
            return new Promise((resolve, reject) => {
                this._vm.$http.post(`${process.env.VUE_APP_API_URL}/easydriver/upload/${file.easydriver_type}`, file.file, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getVehicles(context, payload) {
            return new Promise((resolve, reject) => {
                const sensorPattern = payload.sensorPattern ? payload.sensorPattern : ''
                const namePattern = payload.namePattern ? payload.namePattern : ''
                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile?offset=${payload.offset}&limit=${payload.limit}&sensor=${encodeURIComponent(sensorPattern)}&name=${encodeURIComponent(namePattern)}`, {withCredentials:true})
                    .then((res) => {
                        context.commit('setVehicles', {
                            offset: payload.offset,
                            limit: payload.limit,
                            vehicles: res.data,
                            totalCount: parseInt(res.headers['x-total-count'])
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteVehicle(context, UUID) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${UUID}`, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateVehicle(context, vehicle) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${vehicle.caravan_profile_uuid}`, vehicle,  {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteLogs(context, delete_data) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${delete_data.caravanId}/logs/${delete_data.filename}`,  {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateRemoteMaintenance(context, vehicle) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${vehicle.UUID}/remote-maintenance/${vehicle.enabled}`, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        getManufacturers(context) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-manufacturer`, {withCredentials: true})
                    .then((res) => {
                        context.commit("setManufacturers", res.data)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getProducts(context) {
            return new Promise((resolve, reject) => {

                if (context.getters.getProductsList != null) {
                    resolve(context.getters.getProductsList)
                }

                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-product`, {withCredentials: true})
                    .then((res) => {
                        context.commit('setProducts', res.data)
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        registerProduct(context, pairingProduct) {
            return new Promise ((resolve, reject) => {
                this._vm.$http.post(`${process.env.VUE_APP_API_URL}/easydriver-product-pairing`, pairingProduct, {withCredentials: true})
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteProduct(context, payload) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-product-pairing/${payload.caravan_profile_uuid}?product_name=${encodeURIComponent(payload.product_name)}&product_code=${encodeURIComponent(payload.product_code)}`,
                    {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        setCachedValues(context, values) {
            context.commit('setCachedValues', values)
        },
        setPositionY(context, positionY) {
            context.commit('setPositionY', positionY)
        },
        cleanCache(context) {
            context.commit('cleanCache')
        },
        setPatterns(context, { namePattern, sensorPattern }) {
            context.commit('setPatterns', {
                namePattern,
                sensorPattern
            })
        }
    },
    getters: {
        isLoggedIn: state => {
            return state.isLoggedIn
        },
        getVehiclePaginationInfo: state => {
            return state.vehiclePaginationInfo
        },
        getManufacturersList : state => {
            return state.manufacturers
        },
        getProductsList : state => {
            return state.products
        },
        getCachedValues: state => {
            return state.cachedValues
        },
        getScrollPosition: state => {
            return state.positionY
        },
        patterns: state => {
            return {
                namePattern: state.namePattern,
                sensorPattern: state.sensorPattern
            }
        }
    }
}

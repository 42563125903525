var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vehicles)?_c('v-container',{staticClass:"mt-15"},[_c('DialogConfirmerPromise',{ref:"dialog"}),_c('v-layout',{staticStyle:{"height":"85vh"},attrs:{"column":""}},[_c('div',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1 cursorTable",attrs:{"headers":_vm.headers,"items":_vm.vehicles,"items-per-page":_vm.vehicles.length,"hide-default-footer":"","selectable-key":"id"},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.deleteVehicle(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.showInfo(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.caravan_profile_uuid",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_uuid"])+" ")])]}},{key:"item.caravan_profile_title",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_title"])+" ")])]}},{key:"item.caravan_profile_type",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_type"])+" ")])]}}],null,false,165337990)})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
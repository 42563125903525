var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"#282424"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('Upload new version of firmware'))+" ")])],1),_c('v-card-text',{staticClass:"pa-6"},[_c('v-row',{},[_c('v-col',{staticClass:"col-lg-6"},[(_vm.dialog)?_c('v-form',{ref:"easyDriverFwForm"},[_c('v-text-field',{attrs:{"color":"rgba(0, 0, 0, 0.5)","label":_vm.$t('Version name'),"placeholder":_vm.$t('Version name'),"hint":_vm.$t('Version name should be in following format example. 1.0'),"rules":[function (v) { return /^[0-9]{1,2}\.[0-9]{1,2}$/.test(v) || _vm.$t('Version must be in correct format'); }]},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c('v-select',{attrs:{"items":_vm.easyDriverTypes,"placeholder":_vm.$t('Easydriver hardware type'),"label":"Easydriver hardware type","color":"rgba(0, 0, 0, 0.5)","item-text":"type","rules":[function (v) { return !!v || _vm.$t('Easydriver hardware type must be selected'); }],"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeString(item.type))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeString(item.type))+" ")]}}],null,false,1206170076),model:{value:(_vm.easydriver_type_id),callback:function ($$v) {_vm.easydriver_type_id=$$v},expression:"easydriver_type_id"}}),_c('v-select',{attrs:{"items":_vm.firmwareUpdateTypes,"placeholder":_vm.$t('Easydriver firmware update type'),"label":_vm.$t('Easydriver firmware update type'),"color":"rgba(0, 0, 0, 0.5)","item-text":"type","rules":[function (v) { return !!v || _vm.$t('Easydriver firmware update type must be selected'); }],"return-object":""},model:{value:(_vm.firmware_update_type_id),callback:function ($$v) {_vm.firmware_update_type_id=$$v},expression:"firmware_update_type_id"}}),_c('v-file-input',{attrs:{"accept":".hex","color":"rgba(0, 0, 0, 0.5)","placeholder":_vm.$t('Firmware binary file (HEX)'),"label":_vm.$t('Firmware binary file (HEX)'),"prepend-icon":null,"show-size":"","rules":[
                     function (v) { return !!v || _vm.$t('File is required'); },
                     function (v) { return (v && v.size > 0) || _vm.$t('File is required'); } ]},model:{value:(_vm.firmware),callback:function ($$v) {_vm.firmware=$$v},expression:"firmware"}}),_c('v-checkbox',{attrs:{"color":"rgba(0, 0, 0, 0.5)","hint":_vm.$t('If firmware is user ready mark as selected'),"label":_vm.$t('User ready')},model:{value:(_vm.userReady),callback:function ($$v) {_vm.userReady=$$v},expression:"userReady"}}),_c('v-checkbox',{attrs:{"color":"rgba(0, 0, 0, 0.5)","hint":_vm.$t('If firmware is service ready mark as selected'),"label":_vm.$t('Service ready')},model:{value:(_vm.serviceReady),callback:function ($$v) {_vm.serviceReady=$$v},expression:"serviceReady"}})],1):_vm._e()],1),_c('v-col',{staticClass:"col-lg-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Update description:'))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('In order to add description for given language enter it into input fields down bellow'))+" ")])])],1),(_vm.dialog)?_c('div',{staticClass:"supportedLanguagesForm mb-5 mt-2"},_vm._l((_vm.supportedLanguages),function(item){return _c('EasyDriverLanguageFlag',{key:item.name,staticClass:"ma-2",attrs:{"country":item.country,"name":item.name,"supported":_vm.langData[item.country] && _vm.langData[item.country].description},on:{"onLanguage":_vm.handleLanguage}})}),1):_vm._e(),(_vm.langErr)?_c('div',{staticClass:"errMsgContainer"},[_vm._v(" "+_vm._s(_vm.$t('* German and English language support is necessary, please add them !'))+" ")]):_vm._e()])],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"rgba(0, 0, 0, 0.5)","dark":""},on:{"click":_vm.uploadFirmware}},[_vm._v(" "+_vm._s(_vm.$t("Upload firmware"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="ml-0 pl-0">
    <div v-if="initialized" class="mt-1">
      <v-row>
        <v-col class="col-md-3 col-8">
          <v-text-field
              v-model="namePattern"
              :placeholder="$t('Name')"
              @input="debounceName"
          />
        </v-col>
        <v-col class="col-md-3 col-8">
          <v-text-field
              v-model="emailPattern"
              :placeholder="$t('Email')"
              @input="debounceEmail"
          />
        </v-col>

        <v-col class="col-md-3 float-right col-2">
          <v-tooltip
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mt-2 ml-15"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  bottom
              >
                <v-icon color="grey lighten-1">
                  mdi-information
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{
                $t('To search for users by email or name, just type it in the input box and the query will automatically fire')
              }}
            </span>
          </v-tooltip>
        </v-col>

      </v-row>

      <v-container class="ml-0 pl-0">
        <v-layout column style="height: 80vh" class="ml-0 pl-0">
          <div style="overflow:auto" @scroll="onScroll" id="myScroll" ref="myScroll">

            <v-data-table
                :headers="headers"
                :items="users"
                :items-per-page="users.length"
                hide-default-footer
                class="elevation-1 cursorTable"
                selectable-key="id"
                fixed-header
                :loading="!initialized"
                :disable-sort="true"
                @click:row="handleRowClick"
            >
              <template v-slot:item.user_guid="{ item }" @click.stop>
                <table-entry>
                  <div style="max-width: 70px; font-size: 0.8rem">
                    {{ item["user_guid"] }}
                  </div>
                </table-entry>
              </template>

              <template v-slot:item.user_login="{ item }" @click.stop>
                <table-entry>
                  <div style="max-width: 100px; font-size: 0.75rem">
                    {{ item["user_login"] }}
                  </div>
                </table-entry>
              </template>
              <template v-slot:header.user_login @click.stop>
                <TableHeaderItem
                    :table-header="headers[1].text"
                    :activated="sortBy.user_login !== null"
                    @onSortChange="(val) => onSortChange(headers[1].value, val)"
                    @onSortActivated="onSortActivated('user_login')"
                />
              </template>

              <template v-slot:item.user_email="{ item }" @click.stop>
                <div style="max-width: 120px; font-size: 0.8rem">
                  <table-entry>
                    {{ item["user_email"] }}
                  </table-entry>
                </div>
              </template>
              <template v-slot:header.user_email @click.stop>
                <TableHeaderItem
                    :table-header="headers[2].text"
                    :activated="sortBy.user_email !== null"
                    @onSortChange="(val) => onSortChange(headers[2].value, val)"
                    @onSortActivated="onSortActivated('user_email')"
                />
              </template>

              <template v-slot:item.user_active="{item}">
                <div style="font-size: 0.8rem;">
                  <v-switch
                      style="width: 100px"
                      :input-value="item.user_active === 0"
                      color="red"
                      :label="getStatus(item.user_active)"
                      :success="item.user_active === 1"
                      @click.stop="deactivateUser(item.user_guid)"
                  >
                  </v-switch>
                </div>
              </template>
              <template v-slot:header.user_active @click.stop>
                <TableHeaderItem
                    :table-header="headers[3].text"
                    :activated="sortBy.user_active !== null"
                    @onSortChange="(val) => onSortChange(headers[3].value, val)"
                    @onSortActivated="onSortActivated('user_active')"
                />
              </template>

              <template v-slot:item.user_time_create="{ item }">
                <table-entry>
                  <div style="font-size: 0.8rem;max-width: 90px">
                    <b>{{ item["user_time_create"] }}</b>
                  </div>
                </table-entry>
              </template>
              <template v-slot:header.user_time_create @click.stop>
                <TableHeaderItem
                    :table-header="headers[4].text"
                    :activated="sortBy.user_time_create !== null"
                    @onSortChange="(val) => onSortChange(headers[4].value, val)"
                    @onSortActivated="onSortActivated('user_time_create')"
                />
              </template>

              <template v-slot:item.user_time_update="{ item }">
                <table-entry>
                  <div style="font-size: 0.8rem;max-width: 90px">
                    <b>{{ item["user_time_update"] }}</b>
                  </div>
                </table-entry>
              </template>
              <template v-slot:header.user_time_update @click.stop>
                <TableHeaderItem
                    :table-header="headers[5].text"
                    :activated="sortBy.user_time_update !== null"
                    @onSortChange="(val) => onSortChange(headers[5].value, val)"
                    @onSortActivated="onSortActivated('user_time_update')"
                />
              </template>

              <template v-slot:item.experience="{ item }">
                <table-entry>
                  <div style="font-size: 0.8rem;max-width: 20px"
                       v-if="easyDriverExperience[item['user_guid']] && !easyDriverExperience[item['user_guid']].isFetching">
                    <reich-checkbox
                        :value="easyDriverExperience[item['user_guid']].content"
                        @valueChange="() => onEasyDriverExperienceChange(item['user_guid'], easyDriverExperience[item['user_guid']])"
                        text=""
                    />
                  </div>
                  <v-progress-circular indeterminate :size="20" color="success" v-else/>
                </table-entry>
              </template>

              <template v-slot:item.service="{ item }">
                <table-entry>
                  <div style="font-size: 0.8rem;max-width: 20px;">
                    <reich-checkbox
                        v-if="canBeChangedToService(parseInt(item.user_role_id))"
                        :value="parseInt(item.user_role_id) === 5"
                        @valueChange="() => onServiceChange(item)"
                        text=""
                    />
                  </div>
                </table-entry>
              </template>

              <template v-slot:item.actions="{ item }">
                <div style="max-width: 75px">
                  <v-icon
                      @click.stop="openUpdateDialog(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      @click.stop="fireDialogDelete(item)">
                    mdi-delete
                  </v-icon>
                  <v-icon
                      @click.stop="navigateToInfo(item)"
                  >
                    mdi-rv-truck
                  </v-icon>
                </div>
              </template>

            </v-data-table>

          </div>
        </v-layout>
      </v-container>

    </div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card rounded>
        <v-card-title style="background-color: gray; color: white; text-align: center">
          <span class="headline text-center font-weight-thin">
            {{ $t('Edit user') }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
                ref="updateUserForm"
                v-model="validForm"
                lazy-validation
            >
              <v-row class="justify-center">
                <v-col
                    cols="7"
                    md="7"
                >
                  <v-text-field
                      rquired
                      :label="$t('Login')"
                      v-model="userToUpdate.user_login"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="7"
                    md="7"
                >
                  <v-text-field
                      required
                      v-model="userToUpdate.user_email"
                      :label="$t('Email')"
                      :rules="rules.emailRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="7"
                    sm="7"
                >
                  <v-select
                      v-model="userToUpdate.user_lang"
                      persistent-hint
                      single-line
                      :items="languages"
                      :label="$t('Language')"
                  ></v-select>
                </v-col>
                <v-col
                    cols="7"
                    sm="7"
                    md="7"
                >
                  <v-select
                      :items="roles"
                      v-model="currentRole"
                      persistent-hint
                      return-object
                      single-line
                      item-value="user_role_id"
                      item-text="user_role_alias"
                      :label="$t('Access right')"
                  ></v-select>
                </v-col>
                <v-col
                    cols="7"
                    sm="7"
                    md="7"
                >
                  <v-text-field
                      :label="$t('Password')"
                      v-model="userToUpdate.user_passwd"
                      type="password"
                      :rules="rules.passwordRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="7"
                    sm="7"
                    md="7"
                >
                  <v-text-field
                      :label="$t('Repeat password')"
                      type="password"
                      v-model="userToUpdate.user_passwd_confirm"
                      :rules="rules.confirmPasswordRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
              class="ma-2"
              @click="dialog=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              class="ma-2"
              :disabled="!validForm"
              @click="updateUser"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
          v-model="dialogDelete"
          persistent
          max-width="310"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t('Do you really want to delete the user?') }}
          </v-card-title>
          <v-card-text>
            {{ $t('This action cannot be undone') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="dialogDelete = false"
            >
              {{ $t('Disagree') }}
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="deleteUser"
            >
              {{ $t('Agree') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import TableEntry from "@/components/TableEntry";
import _ from 'lodash';
import TableHeaderItem from "@/components/TableHeaderItem";
import ReichCheckbox from "@/components/DismissableCheckpoint";

export default {
  name: "Users",
  components: {ReichCheckbox, TableHeaderItem, TableEntry},
  data: function () {
    return {
      initialized: false,
      dialog: false,
      page: 0,
      pageCount: 4,
      itemsPerPage: 40,
      headers: [
        {text: this.$t('ID'), value: 'user_guid'},
        {text: this.$t('Name'), value: 'user_login'},
        {text: this.$t('Email'), value: 'user_email'},
        {text: this.$t('Status'), value: 'user_active'},
        {text: this.$t('Create time'), value: 'user_time_create'},
        {text: this.$t('Time update'), value: 'user_time_update'},
        {text: this.$t('Actions'), value: 'actions'},
        {text: this.$t('Experienced user'), value: 'experience'},
        {text: this.$t('Service user'), value: 'service'}
      ],
      sortBy: {
        'user_guid': null,
        'user_login': null,
        'user_email': null,
        'user_active': null,
        'user_time_create': null,
        'user_time_update': null
      },
      alert: false,
      alertMessage: "",
      type: "",
      users: [],
      userToUpdate: {
        "user_guid": "",
        "user_login": "",
        "user_email": "",
        "user_lang": "",
        "user_role_id": "",
        "user_passwd": "",
        "user_passwd_confirm": ""
      },
      userToDelete: null,
      currentRole: {},
      roles: null,
      languages: ['sl', 'fr', 'de', 'en'],
      rules: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          true
        ],
        confirmPasswordRules: [
          (value) =>
              value === this.userToUpdate.user_passwd || 'The password confirmation does not match.',
        ]
      },
      validForm: false,
      dialogDelete: false,
      emailPattern: '',
      namePattern: '',
      emailPatternDebounced: '',
      namePatternDebounced: '',
      fetchInProgress: false
    }
  },
  methods: {
    canBeChangedToService(roleId) {
      console.log(`Comparing role: ${roleId} with 4`)
      return roleId >= 4
    },
    onEasyDriverExperienceChange(userId, change) {
      this.$store.dispatch('user/updateUserExperience', {userId, experience: !change.content});
    },
    getNewPage(filterChange) {
      if (this.fetchInProgress) {
        return;
      }
      if (this.page > this.pageCount) {
        return
      }
      this.fetchInProgress = true
      this.$store.dispatch('user/getUsers', {
        offset: (this.page) * this.itemsPerPage,
        limit: this.itemsPerPage,
        emailPattern: this.emailPatternDebounced,
        namePattern: this.namePatternDebounced,
        sortBy: this.sortBy
      })
          .then((res) => {
            if (filterChange) {
              this.users = []
            }
            this.fetchInProgress = false;
            const paginationInfo = this.$store.getters['user/getUserPaginationInfo']
            this.page = this.page + 1
            this.users = this.users.concat(res.data)
            this.pageCount = paginationInfo.pageCount
            this.itemsPerPage = paginationInfo.itemPerPage
          })
          .catch(() => {
            this.fetchInProgress = false;
            this.$store.dispatch('snackbar/fireAlert', {
              "message": "Beim Abrufen von Benutzern aus der Datenbank ist ein Fehler aufgetreten",
              "color": "error"
            })
          })
    },
    async onSortChange(fieldName, sort) {
      this.sortBy[fieldName] = sort;
      await this.refetchCurrentState()
    },
    openUpdateDialog(item) {
      this.userToUpdate.user_login = item.user_login
      this.userToUpdate.user_email = item.user_email
      this.userToUpdate.user_lang = item.user_lang
      this.userToUpdate.user_role_id = item.user_role_id
      this.userToUpdate.user_guid = item.user_guid

      this.roles = this.$store.getters['user/getUserRoles']

      const filterFunction = (it) => {
        const userRoleId = parseInt(item.user_role_id)
        if (userRoleId < 4) {
          // Filter out service account
          if (parseInt(it.user_role_id) === 5) {
            return false
          }
        }
        return true
      }
      if (this.roles == null) {
        this.$store.dispatch('user/getUserRoles')
            .then((res) => {
              this.roles = res.filter((it) => {
                return filterFunction(it)
              })
              this.currentRole = this.getRoleFromId(item.user_role_id)
              this.dialog = true
            })
      } else {
        this.roles = this.roles.filter((it) => {
          return filterFunction(it)
        })
        this.currentRole = this.getRoleFromId(item.user_role_id)
        this.dialog = true
      }
    },
    async refetchCurrentState() {
      if (this.fetchInProgress) {
        return;
      }
      this.fetchInProgress = true;
      const res = await this.$store.dispatch('user/getUsers', {
        offset: 0,
        limit: (this.page) * this.itemsPerPage,
        emailPattern: this.emailPatternDebounced,
        namePattern: this.namePatternDebounced,
        sortBy: this.sortBy
      });
      this.users = res.data;
      this.fetchInProgress = false;
    },
    async onServiceChange(item) {
      const newRoleId = item.user_role_id < 5 ? 5 : 4

      this.$store.dispatch('user/updateUserRole', {
        guid: item.user_guid,
        updateUserRoleDTO: {
          user_role_id: newRoleId
        }
      })
          .then(() => {
            this.$store.dispatch('snackbar/fireAlert', {
              'message': 'Benutzer wurde erfolgreich aktualisiert',
              'color': 'success'
            })
            const user = this.users.find((user) => user.user_guid === item.user_guid)
            console.log(user)
            if (user) {
              user.user_role_id = newRoleId
            }
          })
    },
    updateUser() {
      this.userToUpdate.user_role_id = this.currentRole.user_role_id
      this.$store.dispatch('user/updateUser', {
        guid: this.userToUpdate.user_guid,
        userDTO: this.userToUpdate
      })
          .then(() => {
            this.dialog = false

            const user = this.users.find((user) => user.user_guid === this.userToUpdate.user_guid)

            user.user_login = this.userToUpdate.user_login
            user.user_email = this.userToUpdate.user_email
            user.user_lang = this.userToUpdate.user_lang
            user.user_role_id = this.userToUpdate.user_role_id
            user.user_time_update = new Date(Math.floor(Date.now())).toLocaleDateString("en-GB")

            this.$store.dispatch('snackbar/fireAlert', {
              "message": "Benutzer wurde erfolgreich aktualisiert",
              "color": "success"
            })
          })
          .catch(() => {
            this.dialog = false
          })
    },
    fireDialogDelete(user) {
      this.userToDelete = user
      this.dialogDelete = true
    },
    getStatus(item) {
      item = item.toString()
      if (item === "1") {
        return 'aktiv'
      } else if (item === "0") {
        return 'deaktiviert'
      } else {
        return 'Not available'
      }
    },
    getRoleFromId(id) {
      if (this.roles != null) {
        return this.roles.find(element => element.user_role_id.toString() === id.toString())
      }
    },
    deleteUser() {
      this.$store.dispatch('user/deleteUser', this.userToDelete.user_guid)
          .then(() => {
            this.dialogDelete = false
            this.users = this.users.filter((user) => user.user_guid !== this.userToDelete.user_guid)
            this.$store.dispatch('snackbar/fireAlert', {
              "message": "Benutzer erfolgreich gelöscht",
              "color": "success"
            })
          })
          .catch(() => {
            this.dialog = false
          })
    },
    deactivateUser(uuid) {
      this.$store.dispatch('user/toggleUser', uuid)
          .then(() => {
            const user = this.users.find((user) => user.user_guid === uuid)
            if (user.user_active === 1) {
              user.user_active = 0
            } else {
              user.user_active = 1
            }
          })
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getNewPage()
      }
    },
    getFilteredPage() {
      this.page = 0
      this.getNewPage(true)
    },
    async navigateToInfo(item) {
      let mPosition = document.getElementById('myScroll').scrollTop

      await this.$store.dispatch('user/setScrollPosition', mPosition)
      await this.$router.push({
        name: 'UserVehicles',
        params: {
          userId: item.user_guid
        }
      })
    },
    handleRowClick(item) {
      this.navigateToInfo(item)
    },
    debounceEmail: _.debounce(function (e) {
      this.emailPatternDebounced = e;
    }, 500),
    debounceName: _.debounce(function (e) {
      this.namePatternDebounced = e;
    }, 500),
    onSortActivated(item) {
      this.sortBy = {
        'user_guid': null,
        'user_login': null,
        'user_email': null,
        'user_active': null,
        'user_time_create': null,
        'user_time_update': null
      }
      this.sortBy[item] = true;
    }
  },
  mounted() {
    const searchQuery = this.$store.getters["user/getSearchQuery"]
    this.emailPattern = searchQuery.emailPattern
    this.namePattern = searchQuery.namePattern
    this.emailPatternDebounced = searchQuery.emailPattern
    this.namePatternDebounced = searchQuery.namePattern

    this.sortBy = searchQuery.sortBy
    const cachedValue = this.$store.getters['user/getCachedUsers']
    if (cachedValue.length > 0) {
      this.users = cachedValue
      const paginationInfo = this.$store.getters['user/getUserPaginationInfo']
      const scrollY = this.$store.getters['user/getScrollPositionY']
      this.page = paginationInfo.page
      this.pageCount = paginationInfo.pageCount
      this.itemsPerPage = paginationInfo.itemPerPage
      this.initialized = true
      setTimeout(() => {
        this.$refs.myScroll.scrollTo(0, scrollY)
      }, 100)
    } else {
      this.$store.dispatch('user/getUsers', {
        offset: 0,
        limit: 40,
        emailPattern: this.emailPatternDebounced,
        namePattern: this.namePatternDebounced,
        sortBy: this.sortBy
      })
          .then((res) => {
            this.users = res.data
            const paginationInfo = this.$store.getters['user/getUserPaginationInfo']

            this.page = paginationInfo.page
            this.pageCount = paginationInfo.pageCount
            this.itemsPerPage = paginationInfo.itemPerPage
            this.initialized = true

          })
    }

  },
  watch: {
    emailPatternDebounced(email, oldEmail) {
      if (oldEmail !== email) {
        this.getFilteredPage()
      }
    },
    namePatternDebounced(name, oldName) {
      if (oldName !== name) {
        this.getFilteredPage()
      }
    },
    users(newVal) {
      newVal.forEach((user) => {
        const userId = user["user_guid"]
        if (!this.easyDriverExperience[userId]) {
          this.$store.dispatch('user/getUserExperience', userId)
        }
      })
    }
  },
  computed: {
    easyDriverExperience() {
      return this.$store.getters['user/getUserExperienceMap'];
    }
  },
  beforeDestroy() {
    this.$store.dispatch('user/setCachedUsers', this.users)
  }
}
</script>

<style scoped>
.date {
  font-size: 0.8rem;
}

.row_entry {
  font-size: 0.8rem;
}

.v-table tr:hover {
  background: red !important;
  cursor: pointer;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>

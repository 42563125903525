import Vue from 'vue'
import App from './App.vue'
import {store} from './store'
import router from "./router";
import vuetify from './plugins/vuetify';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {i18n} from "@/i18n/i18n";

import { Icon } from 'leaflet';
import {setupInterceptor} from "@/utils/httpInterceptors";
import httpClient from "@/utils/httpClient";
import quiteHttpClient from '@/utils/httpBackgroundClient';

import CountryFlag from 'vue-country-flag'
import './registerServiceWorker'
Vue.component('country-flag', CountryFlag)

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


Vue.config.productionTip = false

//Storing axios global instance
Vue.prototype.$http = httpClient
Vue.prototype.$httpQuite = quiteHttpClient

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

const vm = new Vue({
  i18n,
  render: h => h(App),
  vuetify,
  created() {
    setupInterceptor(httpClient)(store)
    setupInterceptor(quiteHttpClient)(store, true)
  },
  store: store,
  router: router
}).$mount('#app')

export { vm }
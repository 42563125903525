<template>
  <div :class="activeClass" ref="downIcon">
    <v-img
        width="15px"
        height="15px"
        src="@/assets/up-arrow-ios.png"
    />
  </div>
</template>

<script>
export default {
  name: "SortIcon",
  props: ['activated', 'hovered', 'sort'],
  computed: {
    activeClass() {
      if (!this.activated && !this.hovered) {
        return 'notActive upIcon';
      } else if (!this.activated && this.hovered) {
        return 'notActiveHovered upIcon';
      }

      if (this.sort === 'DESC') {
        return 'upIcon';
      } else if (this.sort === 'ASC') {
        return 'downIcon';
      }

      return 'notActive';
    }
  },
  watch: {
    sort(newVal) {
      if (newVal === 'DESC') {
        this.$refs.downIcon.classList.add('upIcon');
        this.$refs.downIcon.classList.remove('downIcon');
      } else {
        this.$refs.downIcon.classList.add('downIcon');
        this.$refs.downIcon.classList.remove('upIcon');
      }
    }
  }
}
</script>

<style scoped>
.notActive {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.notActiveHovered {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.upIcon {
  display: inline-block;
  transition: 1s;
  animation: sortup 0.3s linear;
  animation-fill-mode: forwards;
}

.downIcon {
  display: inline-block;
  transition: 1s;
  animation: sortdown 0.3s linear;
  animation-fill-mode: forwards;
}

@keyframes sortup {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes sortdown {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
</style>

<template>
    <v-container class="Vehicles">
        <div class="mt-15">

            <v-row>
                <v-col class="col-md-3 col-8">
                    <v-text-field
                            :placeholder="$t('Title')"
                            :value="namePattern"
                            @input="nameFilterDebounce"
                    />
                </v-col>
                <v-col class="col-md-3 col-8">
                    <v-text-field
                            :placeholder="$t('SensorUID')"
                            :value="sensorPattern"
                            @input="sensorFilterDebounce"
                    />
                </v-col>

                <v-col class="col-md-3 float-right col-2">
                    <v-tooltip

                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    class="mt-2 ml-15"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                <v-icon color="grey lighten-1">
                                    mdi-information
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
              {{ $t("To search for vehicles by title or sensorUID, simply enter them in the input field and the query will be triggered automatically fire") }}
            </span>
                    </v-tooltip>
                </v-col>

            </v-row>

            <v-container>
                <v-layout column style="height: 75vh">
                    <div style="overflow: auto" @scroll="onScroll" ref="myScroll">
                        <v-data-table
                                :headers="headers"
                                :items="vehicles"
                                :items-per-page="vehicles.length"
                                hide-default-footer
                                class="elevation-1 cursorTable"
                                selectable-key="id"
                                @click:row="handleClick"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                        class="ml-2"
                                        @click.stop="fireDialogDelete(item)">
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                        class="ml-2"
                                        @click.stop="showInfo(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <template v-slot:item.caravan_profile_uuid="{ item }" @click.stop>
                                <table-entry>
                                    {{ item["caravan_profile_uuid"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.caravan_profile_title="{ item }" @click.stop>
                                <table-entry>
                                    {{ item["caravan_profile_title"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.caravan_profile_type="{ item }" @click.stop>
                                <table-entry>
                                    {{ item["caravan_profile_type"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.user.user_guid="{ item }" @click.stop>
                                <table-entry>
                                    {{ item["user"]["user_guid"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.user_email="{ item }" @click.stop>
                                <table-entry>
                                    {{ item["user"]["user_email"] }}
                                </table-entry>
                            </template>

                        </v-data-table>
                    </div>
                </v-layout>
            </v-container>

        </div>

        <v-row justify="center">
            <v-dialog
                    v-model="dialogDelete"
                    persistent
                    max-width="310"
            >
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('Do you really want to delete the vehicle?') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('Do you really want to delete the vehicle with the ID?', [this.vehicleToDeleteID]) }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="red darken-1"
                                text
                                @click="dialogDelete = false; vehicleToDeleteID=''"
                        >
                            {{ $t('Disagree') }}
                        </v-btn>
                        <v-btn
                                color="green darken-1"
                                text
                                @click="deleteVehicle"
                        >
                            {{ $t('Agree') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
import TableEntry from "@/components/TableEntry";
import _ from 'lodash';

export default {
    name: "Vehicles",
    components: {TableEntry},
    data: function () {
        return {
            "initialized": false,
            "headers": [
                {text: this.$t('ID'), value: 'caravan_profile_uuid'},
                {text: this.$t('Title'), value: 'caravan_profile_title'},
                {text: this.$t('Type'), value: 'caravan_profile_type'},
                {text: this.$t('User identification'), value: 'user.user_guid'},
                {text: this.$t('User email'), value: 'user_email'},
                {text: this.$t('Actions'), value: 'actions'}
            ],
            "page": 1,
            "pageCount": 4,
            "itemsPerPage": 40,
            "vehicles": [],
            "dialogDelete": false,
            "vehicleToDeleteID": "",
            "namePattern": "",
            "sensorPattern": "",
        }
    },
    methods: {
        getNewPage(filterChange) {
            this.$store.dispatch('caravan/getVehicles', {
                offset: (this.page - 1) * this.itemsPerPage,
                limit: this.itemsPerPage,
                namePattern: this.namePattern,
                sensorPattern: this.sensorPattern
            })
                .then(async (res) => {
                    // associate vehicles with users by obtaining additional info about user
                    const promises = this.associateVehiclesWithUser(res.data)
                    await Promise.all(promises)
                    if (filterChange) {
                        this.vehicles = []
                    }
                    this.vehicles = this.vehicles.concat(res.data)

                    const paginationInfo = this.$store.getters['caravan/getVehiclePaginationInfo']

                    this.page += 1
                    this.pageCount = paginationInfo.pageCount
                    this.itemsPerPage = paginationInfo.itemsPerPage

                })
        },
        associateVehiclesWithUser(vehicles) {
            const promises = []
            vehicles.forEach((vehicle) => {
                let userOBJ = this.$store.getters['user/getUserByID'](vehicle.user_guid)
                if (userOBJ == null) {
                    const promise = this.$store.dispatch('user/getUserByUUID', vehicle.user_guid)
                        .then((res) => {
                            userOBJ = res.data
                            vehicle.user = userOBJ
                        })
                    promises.push(promise)
                }
            })
            return promises
        },
        deleteVehicle() {
            this.$store.dispatch('caravan/deleteVehicle', this.vehicleToDeleteID)
                .then(() => {
                    this.vehicles = this.vehicles.filter((vehicle) => vehicle.caravan_profile_uuid !== this.vehicleToDeleteID)
                    this.dialogDelete = false
                })
        },
        fireDialogDelete(item) {
            this.dialogDelete = true
            this.vehicleToDeleteID = item.caravan_profile_uuid
        },
        showInfo(item) {
            const positionY = this.$refs.myScroll.scrollTop
            this.$store.dispatch('caravan/setPositionY', positionY)

            this.$router.push({
                name: 'VehicleInfo',
                params: {'vehicleOBJ': item, 'vehicleId': item.caravan_profile_uuid}
            })
        },
        onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.getNewPage()
            }
        },
        getNewFilteredPage() {
            this.page = 1
            this.getNewPage(true)
        },
        handleClick(item) {
            this.showInfo(item)
        },
        nameFilterDebounce: _.debounce(function (e) {
            this.namePattern = e;
        }, 500),
        sensorFilterDebounce: _.debounce(function (e) {
            this.sensorPattern = e
        }, 500)
    },
    mounted() {
        const cachedValues = this.$store.getters['caravan/getCachedValues']
        const positionY = this.$store.getters['caravan/getScrollPosition']
        const paginationInfo = this.$store.getters['caravan/getVehiclePaginationInfo']
        const patterns = this.$store.getters['caravan/patterns']
        if (cachedValues.length > 0) {
            this.vehicles = cachedValues
            this.page = paginationInfo.page
            this.pageCount = paginationInfo.pageCount
            this.itemsPerPage = paginationInfo.itemsPerPage
            this.initialized = paginationInfo.initialized
            this.namePattern = patterns.namePattern
            this.sensorPattern = patterns.sensorPattern

            setTimeout(() => {
                this.$refs.myScroll.scrollTo(0, positionY)
            }, 100)
        } else {
            this.getNewPage()
        }
    },
    watch: {
        namePattern(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getNewFilteredPage()
            }
        },
        sensorPattern(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getNewFilteredPage()
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('caravan/setCachedValues', this.vehicles)
        this.$store.dispatch('caravan/setPatterns', {
            namePattern: this.namePattern,
            sensorPattern: this.sensorPattern
        })
    }
}
</script>

<style>

</style>

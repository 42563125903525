export const filterMixin = {
    data: function () {
        return {
            filters : [],
            filterChangeCallback: null
        }
    },
    methods: {

    },
    watch: {
        filters: {
            handler(newVal, oldVal) {
                let filterChange = false;
                newVal.forEach((filter, index) => {
                    if (oldVal[index] != null) {
                        filterChange ||= (oldVal[index].debouncedValue === filter.debouncedValue && oldVal[index].value === filter.value);
                    }
                })
                if (this.filterChangeCallback !== null && filterChange) {
                    this.filterChangeCallback();
                }
            },
            deep: true
        }
    }
}

<template>
    <v-container>
        <div v-if="initialized" class="mt-15">
            <v-container>
                <v-layout column style="height: 80vh">
                    <div style="overflow: auto" @scroll="onScroll" ref="myScroll">

                        <v-data-table
                                :headers="headers"
                                :items="commentsArr"
                                :items-per-page="commentsArr.length"
                                hide-default-footer
                                class="elevation-1 cursorTable"
                                selectable-key="id"
                                :loading="!initialized"
                                @click:row="handleClick"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-icon @click.stop="fireDeleteDialog(item)">
                                    mdi-delete
                                </v-icon>

                                <v-icon @click.stop="navigateToPoiInfo(item)">
                                    mdi-map-marker-outline
                                </v-icon>
                            </template>

                            <template v-slot:item.poi_rating_stars="{ item }">
                                <v-rating
                                        class="table_entry"
                                        @click="(e) => e.stopPropagation()"
                                        :value="item.poi_rating_stars"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="15"
                                >
                                </v-rating>
                            </template>

                            <template v-slot:item.poi_rating_text="{ item }">
                                <table-entry>
                                    {{ item.poi_rating_text | truncate(50, ' ...') }}
                                </table-entry>
                            </template>

                            <template v-slot:item.user_login="{ item }">
                                <table-entry>
                                    {{ item['user_login'] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.poi_name="{ item }" class="table_entry">
                                <table-entry>
                                    {{ item['poi_name'] }}
                                </table-entry>
                            </template>

                        </v-data-table>

                    </div>
                </v-layout>
            </v-container>
        </div>

        <v-row class="justify-center" v-if="commentToDelete">
            <v-dialog
                    :value="true"
                    persistent
                    max-width="350"
            >
                <dialog-confirmer
                        @result="(res) => onDialogResult(res)"
                        :positive="$t('Delete comment')"
                        :negative="$t('Dismiss')"
                        :title="$t('Delete comment')"
                        :text="$t('Do you really want to delete the comment?')"
                />
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import DialogConfirmer from "@/components/DialogConfirmer";
import TableEntry from "@/components/TableEntry";

export default {
    name: "PoiComments",
    components: {TableEntry, DialogConfirmer},
    data: function () {
        return {
            initialized: false,
            headers: [
                {text: 'Benutzer-Anmeldung', value: 'user_login'},
                {text: 'Starst', value: 'poi_rating_stars'},
                {text: 'Text', value: 'poi_rating_text'},
                {text: 'Poi-Name', value: 'poi_name'},
                {text: 'Aktionen', value: 'actions'}
            ],
            paginationInfo: {
                page: 1,
                pageCount: 1,
                itemsPerPage: 40
            },
            commentsArr: [],
            commentToDelete: ''
        }
    },
    methods: {
        async getNewPage() {
            const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-poi/comment?offset=${(this.paginationInfo.page - 1) * this.paginationInfo.itemsPerPage}&limit=${this.paginationInfo.itemsPerPage}`)
            this.commentsArr = this.commentsArr.concat(res.data)

            this.paginationInfo.pageCount = Math.ceil(parseInt(res.headers['x-total-count']) / this.paginationInfo.itemsPerPage)
            this.initialized = true

            this.paginationInfo.page += 1
        },
        async toggleComment(item) {
            const user = this.commentsArr.find((user) => user.poi_comment_guid === item.poi_comment_guid)
            user.approved = !user.approved
        },
        async deleteComment(item) {
            await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-poi/comment/${item.poi_rating_uuid}`)
            this.commentsArr = this.commentsArr.filter((currItem) => item.poi_rating_uuid !== currItem.poi_rating_uuid)
            await this.$store.dispatch('snackbar/fireAlert', {
                'color': 'success',
                'message': 'Poi-Kommentar erfolgreich gelöscht'
            })
        },
        async navigateToPoiInfo(item) {
            const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/admin/easydriver-poi/${item.poi_uuid}`)
            let mPosition = this.$refs.myScroll.scrollTop
            await this.$store.dispatch('poiComments/setScrollPosition', mPosition)

            await this.$router.push({
                name: 'PoiInformation',
                params: {
                    'poiOBJ': res.data,
                    'useCache': false
                }
            })
        },
        onDialogResult(res) {
            if (res) {
                this.deleteComment(this.commentToDelete)
            }
            this.commentToDelete = ''
        },
        fireDeleteDialog(item) {
            this.commentToDelete = item
        },
        onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.getNewPage()
            }
        },
        handleClick(item) {
            this.navigateToPoiInfo(item)
        }
    },
    mounted() {
        const cachedValue = this.$store.getters['poiComments/getCachedValues']
        const paginationInfo = this.$store.getters['poiComments/paginationInfo']
        const scrollY = this.$store.getters['poiComments/getScrollPositionY']

        if (cachedValue.length === 0) {
            this.getNewPage()
        } else {
            this.initialized = true
            this.commentsArr = cachedValue
            if (paginationInfo) {
                this.paginationInfo = paginationInfo
            }

            setTimeout(() => {
                this.$refs.myScroll.scrollTo(0, scrollY)
            }, 100)
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix
            } else {
                return text
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('poiComments/setCachedValue', this.commentsArr)
        this.$store.dispatch('poiComments/setPaginationInfo', this.paginationInfo)
    }
}
</script>

<style>

.cursorTable tbody tr:hover {
    cursor: pointer !important;
}

.table_entry:hover {
    cursor: text;
}
</style>

<template>
  <v-row align="center" justify="center">

    <div :style="{position: 'absolute', top: '50px', left: '100px'}">
      <v-btn
          color="error"
          dark
          absolute
          bottom
          right
          fab
          @click="deletePoi(poiOBJ)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-col class="col-md-5 col-12">
      <DialogConfirmerPromise ref="modal"/>
      <v-card v-if="poiOBJ" class="scroll" height="90vh">
        <v-carousel
            v-model="currentIndex"
            v-if="poiOBJ" :show-arrows="poiOBJ.poi_images && poiOBJ.poi_images.length > 0">
          <template v-if="poiOBJ.poi_images && poiOBJ.poi_images.length > 0">
            <v-carousel-item
                v-for="imageOBJ in poiOBJ.poi_images"
                :key="imageOBJ.poi_image_guid"
                :src="getSource(imageOBJ)"
                reverse-transition="fade-transition"
                transition="fade-transition"
            >

            </v-carousel-item>
          </template>
          <template v-else>
            <v-carousel-item
            >
              <v-sheet
                  height="100%"
              >
                <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                >
                  <div class="text-h4">
                    {{$t('No images for specified POI')}}
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </template>
        </v-carousel>
        <v-list two-line v-if="poiOBJ.poi_images && poiOBJ.poi_images.length > 0">
          <v-list-item>
            <p><b>{{$t('Image id')}}</b>: {{poiOBJ.poi_images[currentIndex].poi_image_guid}}</p>
          </v-list-item>
          <v-list-item>
            <v-btn @click="deleteImage">
              {{$t('Delete image')}}
            </v-btn>
          </v-list-item>
        </v-list>

        <v-card-title>{{poiOBJ.poi_name}}</v-card-title>


        <v-expansion-panels>

          <v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('Description')}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  {{poiOBJ.poi_desc}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panel>


          <v-expansion-panel v-if="ratingsArr !== ''">

            <v-expansion-panel-header>
              {{$t('Rating assignment')}}
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="ratingsArr.attributes && ratingsArr.attributes.length > 0">
              <v-row align="center" class="mx-0"  v-for="rating in ratingsArr.attributes" :key="rating.poi_attribute_label">
                <v-rating
                    :value="calculateRatingValue(rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="20"
                >
                </v-rating>
                <div class="grey--text ml-4">
                  {{calculateRatingValue(rating)}} ({{rating.count_rating_attribute}}) - {{rating.poi_attribute_label}}
                </div>
              </v-row>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else>
              <v-row align="center" class="mx-0">
                <p class="grey--text ml-1">
                  {{$t("No reviews available")}}
                </p>
              </v-row>
            </v-expansion-panel-content>

          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-if="ratingsArr !== ''">
              {{$t("Reviews")}}
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else>
              {{$t("No reviews available")}}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="ratingsArr && ratingsArr.ratings.length > 0">
              <v-virtual-scroll
                  v-if="ratingsArr"
                  :items="ratingsArr.ratings"
                  :item-height="120"
                  height="200"
              >
                <template v-slot:default="{ item }">
                  <poi-comment :comment="item" @delete="handleDelete" @edit="handleEdit" />
                </template>
              </v-virtual-scroll>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else>
              <v-row align="center" class="mx-0">
                <p class="grey--text ml-1">
                  {{$t('No comments available')}}
                </p>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>

      </v-card>
    </v-col>

    <v-col class="col-12 col-md-7" v-if="poiOBJ">
      <v-card height="90vh">
          <l-map
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              style="height: 100%"
          >
            <l-tile-layer
                :url="url"
                :attribution="attribution"
            />
            <l-marker :lat-lng="center" />
          </l-map>
      </v-card>
    </v-col>

  </v-row>
</template>

<script>
import { latLng } from "leaflet";
import PoiComment from "@/components/PoiComment";
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";

export default {
  name: "PoiInfo",
  components: {DialogConfirmerPromise, PoiComment},
  props: ['poiOBJ', 'useCache'],
  data: function () {
    return {
      ratingsArr: '',
      center: latLng(this.poiOBJ.poi_location.lat, this.poiOBJ.poi_location.lon),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      commentsArr: '',
      currentIndex: 0
    }
  },
  methods: {
    getRatings: function () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-poi/${this.poiOBJ.poi_uuid}/rating`)
          .then((res) => {
            this.ratingsArr = res.data
          })
    },
    calculateRatingValue: function (rating) {
      if (rating.count_rating_attribute === 0) {
        return 0
      }
      return rating.sum_rating_attribute_stars/rating.count_rating_attribute
    },
    getSource(imageOBJ) {
      return `${process.env.VUE_APP_API_URL}/easydriver-poi/${this.poiOBJ.poi_uuid}/image/${imageOBJ.poi_image_guid}`
    },
    async deleteImage() {
      const poiID = this.poiOBJ.poi_uuid
      const imageId = this.poiOBJ.poi_images[this.currentIndex].poi_image_guid
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-poi/${poiID}/image/${imageId}`)
      this.poiOBJ.poi_images = this.poiOBJ.poi_images.filter((item) => item.poi_image_guid !== imageId)

      await this.$store.dispatch('snackbar/fireAlert', {
        "message": this.$t("You have successfully deleted the POI image"),
        "color": "success"
      })
    },
    async handleDelete(item) {
      const res = await this.$refs.modal.fireAlert(this.$t('Delete comment'), this.$t("Dismiss"), this.$t('Delete comment'), this.$t('Do you really want to delete the comment?'))
      if (res) {
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-poi/comment/${item.poi_rating_uuid}`);
        this.ratingsArr.ratings = this.ratingsArr.ratings.filter((it) => it.poi_rating_uuid !== item.poi_rating_uuid);
      }
    },
    async handleEdit(item) {
      await this.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-poi/comment/${item.poi_rating_uuid}`, {
        poi_rating_stars: item.poi_rating_stars,
        poi_rating_text: item.poi_rating_text
      })
      this.ratingsArr.ratings = this.ratingsArr.ratings.map((it) => {
        if (it.poi_rating_uuid === item.poi_rating_uuid) {
          return {
            ...it,
            poi_rating_stars: item.poi_rating_stars,
            poi_rating_text: item.poi_rating_text
          }
        }
      })
    },
    async deletePoi(item) {
      const res = await this.$refs.modal.fireAlert(this.$t('Delete POI'),
          this.$t('Dismiss'),
          this.$t('Delete POI'),
          this.$t('Do you really want to delete this POI?')
      )
      if (res) {
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-poi/${item.poi_uuid}`)
        await this.$router.push({
          name: 'PointOfInterest'
        })
      }
    }
  },
  mounted() {
    this.getRatings()
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>

import { vm } from '@/main'
import {deleteAllCookies} from "@/utils/cookie";

export const auth = {
    namespaced: true,

    state: () => ({
        isLoggedIn: false,
        sessionUser: null,

    }),
    mutations: {
        increment(state) {
            state.counter += 1
        },
        loginUser(state) {
            state.isLoggedIn = true
        },
        saveUser(state, sessionUser) {
            state.sessionUser = sessionUser
            state.isLoggedIn = true
        },
        logoutUser(state) {
            state.sessionUser = null
            state.isLoggedIn = false
        }
    },
    actions: {
        increment(context) {
            context.commit('increment')
        },
        loginUser (context, userDTO) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise((resolve, reject) => {
                this._vm.$http.post(`${process.env.VUE_APP_API_URL}/login`, userDTO, {withCredentials:true})
                    .then((res) => {
                        if(res.data.rightsnroles.user_role_id < 4) {
                            context.commit('loginUser', res.data)
                            resolve(res)
                        } else {
                            deleteAllCookies();
                            reject(null);
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getUserFromSession(context) {
            return new Promise((resolve, reject) => {
                    this._vm.$http.get(`${process.env.VUE_APP_API_URL}/session/info`, {withCredentials: true})
                        .then((res) => {
                            if (!res.data) {
                                reject(null)
                            }else {
                                context.commit('saveUser', res.data)
                                resolve(res.data)
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        })
            })
        },
        logoutUser(context) {
            return new Promise((resolve, reject) =>  {
                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/logout`, {withCredentials: true})
                    .then((res) => {
                        context.commit('logoutUser')
                        vm.$router.push({
                            path:"/login"
                        })
                        resolve(res.data)
                    })
                    .catch((err)=> {
                        reject(err)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: state => {
            return state.isLoggedIn
        }
    }
}

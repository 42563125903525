export const errorHandler = (error) => {
    let errorOBJ = {
        color: 'error',
        message: 'Unexpected error has occurred',
        code: -1
    }

    if (error.response) {
        // Request was made and server responded
        if (error.response.data.error_message) {
            errorOBJ.message = error.response.data.error_message
        }
        else {
            errorOBJ.message = 'Unexpected error has occurred'
        }

        // Check for error code expired session
        if (error.response.data.code) {
            const code = error.response.data.code
            errorOBJ.code = code
            if (code === 1037) { //session has expired !!
                errorOBJ.color = 'info'
                errorOBJ.message = 'Ihre Sitzung ist aufgrund von Inaktivität abgelaufen, bitte melden Sie sich erneut an'
            }
        }
    }
    else if (error.request) {
        //Request was made but no response was received
        errorOBJ.message = 'Request was sent but no response had been received'
    }

    return errorOBJ
}

import httpClient from "@/utils/httpClient";

export const easydriver = {
    namespaced: true,
    state: () => ({
        easyDriverLogs: [],
        easyDriverLogsPaginationInfo: {
            offset: 0,
            limit: 20
        },
        easyDriverLogsFetchInProgress: false,
        easyDriverLogsDeleteInProgress: false,
        vehicleId: '',

        easyDriverTypes: '',
        easyDriverSelectedType: '',

        easyDriverFirmware: [],
        easyDriverFirmwareFetchInProgress: false,

        firmwareUploadPending: false,
        firmwareUploadProgress: 0,

        easydriverFwUpdateTypes: ''
    }),
    mutations: {
        setEasyDriverLogsFetchInProgress(state, fetch) {
            state.easyDriverLogsFetchInProgress = fetch;
        },
        setEasyDriverLogsDeleteInProgress(state, fetch) {
            state.easyDriverLogsDeleteInProgress = fetch;
        },
        setEasyDriverLogs(state, logs) {
            state.easyDriverLogs = logs;
        },
        markNextPageEasyDriverLogs(state) {
            state.easyDriverLogsPaginationInfo = {
                offset: state.easyDriverLogsPaginationInfo.offset + state.easyDriverLogsPaginationInfo.limit,
                limit: state.easyDriverLogsPaginationInfo.limit
            };
        },
        resetToDefaultsEasyDriverLogs(state) {
            state.easyDriverLogsPaginationInfo = {
                offset: 0,
                limit: 20
            };
        },
        appendEasyDriverLogs(state, logs) {
            state.easyDriverLogs = [
                ...state.easyDriverLogs,
                ...logs
            ];
        },
        removeLogFromCache(state, logId) {
            state.easyDriverLogs = state.easyDriverLogs.filter((it) => it.fileName !== logId);
        },
        resetToDefaults(state, vehicleId) {
            state.easyDriverLogs = [];
            state.vehicleId = vehicleId;
            state.easyDriverLogsDeleteInProgress = false;
            state.easyDriverLogsFetchInProgress = false;
            state.easyDriverLogsPaginationInfo = {
                offset: 0,
                limit: 20
            }
        },
        setEasyDriverTypes(state, types) {
            state.easyDriverTypes = types;
        },
        setEasyDriverSelectedType(state, type) {
            state.easyDriverSelectedType = type;
        },
        setEasyDriverFirmwareFetchInProgress(state, fetch) {
            state.easyDriverFirmwareFetchInProgress = fetch;
        },
        setEasyDriverFirmware(state, firmware) {
            state.easyDriverFirmware = firmware;
        },
        setFirmwareUploadPending(state, pending) {
            state.firmwareUploadPending = pending;
        },
        setFirmwareUploadProgress(state, progress) {
            state.firmwareUploadProgress = progress;
        },
        setEasyDriverFwUpdateTypes(state, fwUpdateTypes) {
            state.easydriverFwUpdateTypes = fwUpdateTypes;
        },
        addFirmwareItem(state, fw) {
            state.easyDriverFirmware.push(fw)
        },
        removeFirmwareItem(state, fw) {
            state.easyDriverFirmware = state.easyDriverFirmware.filter((it) => it.id !== fw.id)
        },
        updateFirmwareItem(state, fw) {
            state.easyDriverFirmware = state.easyDriverFirmware
                .map((it) => {
                    if (it.id === fw.id) {
                        return {
                            ...it,
                            ...fw,
                        }
                    } else {
                        return it;
                    }
                })
        }
    },
    actions: {
        async fetchEasyDriverLogs(context) {
            try {
                await context.commit('setEasyDriverLogsFetchInProgress', true);
                const paginationInfo = context.state.easyDriverLogsPaginationInfo;
                const currentId = context.state.vehicleId;
                const idParam = currentId ? `&id=${currentId}` : '';

                const result = await httpClient.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/logs?offset=${paginationInfo.offset}&limit=${paginationInfo.limit}${idParam}`);
                context.commit('appendEasyDriverLogs', result.data.result);
                context.commit('markNextPageEasyDriverLogs');
            } catch (e) {
                console.log(e, "Error when fetching easy driver logs");
            } finally {
                await context.commit('setEasyDriverLogsFetchInProgress', false);
            }
        },
        async deleteLog(context, logId) {
            try {
                await context.commit('setEasyDriverLogsDeleteInProgress', true);
                await httpClient.delete(`${process.env.VUE_APP_API_URL}/admin/easydriver/logs/${logId}`);
                context.commit('removeLogFromCache', logId);
            } catch (e) {
                console.log(e, 'Error when deleting easy driver logs');
            } finally {
                await context.commit('setEasyDriverLogsFetchInProgress', false);
            }
        },
        async resetToDefault(context, vehicleId) {
            context.commit('resetToDefaults', vehicleId);
        },
        async fetchEasyDriverTypes(context) {
            try {
                const result = await httpClient.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/types`);
                await context.commit('setEasyDriverFirmwareFetchInProgress', true);
                const selectedItem = context.state.easyDriverSelectedType
                const firmware = await httpClient.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware?type=${selectedItem ? selectedItem.id : ''}`);

                await context.commit('setEasyDriverTypes', result.data);
                await context.commit('setEasyDriverFirmware', firmware.data);
            } catch (e) {
                console.log(e)
                await context.commit('setEasyDriverTypes', []);
            } finally {
                await context.commit('setEasyDriverFirmwareFetchInProgress', false);
            }
        },
        async setEasyDriverSelectedType(context, selectedType) {
            await context.commit('setEasyDriverSelectedType', selectedType);
            try {
                await context.commit('setEasyDriverFirmwareFetchInProgress', true);
                const firmware = await httpClient.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware?type=${selectedType ? selectedType.id : ''}`);

                await context.commit('setEasyDriverFirmware', firmware.data);
            } catch (e) {
                console.log(e)
            } finally {
                await context.commit('setEasyDriverFirmwareFetchInProgress', false);
            }

        },
        async uploadFirmware(context, { easy_driver_type_id, firmware_update_type_id, user, firmware, version, service , languageData }) {
            const formData = new FormData();
            const selectedType = context.state.easyDriverSelectedType;
            formData.append('easydriver_type_id', easy_driver_type_id);
            formData.append('firmware_update_type_id', firmware_update_type_id);
            formData.append('user', user);
            formData.append('service', service)
            formData.append('firmware', firmware);
            formData.append('version', version);
            formData.append("language", JSON.stringify(languageData))

            context.commit('setFirmwareUploadPending', true);

            const res = await this._vm.$http.post(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    const currentProgress = Math.round( (progressEvent.loaded * 100) / totalLength )
                    context.commit('setFirmwareUploadProgress', currentProgress);
                }
            });

            context.commit('setFirmwareUploadProgress', 0);
            context.commit('setFirmwareUploadPending', false);

            if (!selectedType) {
                context.commit('addFirmwareItem', res.data);
            } else if (selectedType.id === easy_driver_type_id) {
                context.commit('addFirmwareItem', res.data)
            }
        },
        async fetchEasyDriverFwUpdateTypes(context) {
            const res = await httpClient.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/fw-update-types`);
            context.commit('setEasyDriverFwUpdateTypes', res.data);
        },
        async deleteFirmwareVersion(context, item) {
            await httpClient.delete(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware/${item.id}`);
            context.commit('removeFirmwareItem', item);
        },
        async toggleServiceReady(context, item) {
            await httpClient.put(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware/${item.id}`, {
                serviceReady: !item.serviceReady,
                userReady: item.userReady
            });
            context.commit('updateFirmwareItem', {
                ...item,
                serviceReady: !item.serviceReady,
                userReady: item.userReady
            });
        },
        async toggleUserReady(context, item) {
            await httpClient.put(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware/${item.id}`, {
                userReady: !item.userReady,
                serviceReady: item.serviceReady
            });
            context.commit('updateFirmwareItem', {
                ...item,
                userReady: !item.userReady,
                serviceReady: item.serviceReady
            });
        }
    },
    getters: {
        isFetchingEasyDriverLogs: (state) => state.easyDriverLogsFetchInProgress,
        easyDriverLogs: (state) => state.easyDriverLogs,
        easyDriverLogsPaginationInfo: (state) => state.easyDriverLogsPaginationInfo,
        easyDriverLogsVehicleId: (state) => state.vehicleId,
        easyDriverTypes: (state) => state.easyDriverTypes,
        easyDriverSelectedType: (state) => state.easyDriverSelectedType,
        easyDriverUpdateTypes: (state) => state.easydriverFwUpdateTypes,
        easyDriverFwUploadProgress: (state) => state.firmwareUploadProgress,
        easyDriverFwUploadPending: (state) => state.firmwareUploadPending,
        easyDriverFirmware: (state) => state.easyDriverFirmware,
        easyDriverFwFetchInProgress: (state) => state.easyDriverFirmwareFetchInProgress
    }
}

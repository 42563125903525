<template>
  <div class="container">
    <div>
      <v-icon small>
        mdi-information
      </v-icon>
      <span class="infoText">
        {{ title }}
      </span>
    </div>
    <v-progress-linear class="progress mt-3" :value="progress" color="black"/>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: ['title', 'progress']
}
</script>

<style scoped>
.container {
  border-radius: 12px;
  background-color: whitesmoke;
  filter: drop-shadow(30px 10px 4px rgba(0, 0, 0, 0.07));
  min-width: 300px;
  padding: 15px;
}

.progress {
  width: 100%;
}

.infoText {
  font-weight: bold;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>
<template>
  <v-layout column style="height: 70vh">
    <v-row>
      <v-col class="col-md-5 col-5 mt-10">
        <v-text-field
            v-if="useFilter"
            @input="debounceUserName"
            :placeholder="$t('Name')"
        />
      </v-col>
      <v-col class="col-md-5 col-5 mt-10">
        <v-text-field
            v-if="useFilter"
            @input="debounceEmailAddress"
            :placeholder="$t('Email')"
        />
      </v-col>
    </v-row>
    <div style="overflow: auto; height: 60vh" class="mt-5" @scroll="onScroll">
      <v-data-table
          :headers="headers"
          :items="audience"
          hide-default-footer
          :class="useFilter ? ['elevation-1', 'cursorTable'].join(' ') : ['elevation-1', 'cursorTable', 'mt-11'].join(' ')"
          :items-per-page="audience.length"
          item-key="user.user_guid"
          @click:row="handleRowClick"
      >
        <template v-slot:item.firebaseAssociated="{item}">

          <v-tooltip right v-if="item.firebaseAssociated">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>{{$t('User will receive push notification')}}</span>
          </v-tooltip>
          <v-tooltip right v-else>
            <template v-slot:activator="{ on, attrs}">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-close
              </v-icon>
            </template>
            <span>{{$t('User will not receive push notification')}}</span>
          </v-tooltip>

        </template>
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
import Vue from "vue";
import {paginationMixin} from "@/mixins/paginationMixin";
import {filterMixin} from "@/mixins/filterMixin";
import {endIntersectMixin} from "@/mixins/endIntersectMixin";
import _ from 'lodash';
import {i18n} from "@/i18n/i18n";
export default Vue.extend({
  name: "AudienceTable",
  props: ['audience', 'useFilter'],
  mixins: [paginationMixin, filterMixin, endIntersectMixin],
  data: function () {
    return {
      headers: [
        {text: i18n.t('Email Address'), align: 'start', sortable: false, value: 'user.user_email'},
        {text: i18n.t('User Login'), align: 'start', sortable: false, value: 'user.user_login'},
        {text: i18n.t('Push notifications enabled'), align: 'center', sortable: false, value: 'firebaseAssociated'}
      ]
    }
  },
  created() {
    this.filters = [
      {
        name: 'emailAddress',
        value : ''
      },
      {
        name: 'userName',
        value: ''
      }
    ];
    this.filterChangeCallback = this.onFilterChange;
    this.onScrollCallback = this.endIntersect;
  },
  methods: {
    onFilterChange() {
      this.$emit('filterChange', this.filters)
      this.resetToDefaults();
    },
    endIntersect() {
      this.getNextPage();
      this.$emit('getNextPage', {
        offset: this.offset,
        limit: this.limit
      })
    },
    handleRowClick(item) {
      this.$emit('onParticipantAdd', item);
    },
    debounceUserName: _.debounce(function (e) {
      this.filters[1].value = e;
    }, 500),
    debounceEmailAddress: _.debounce(function (e) {
      this.filters[0].value = e;
    }, 500)
  },
  watch: {

  }
})
</script>

<style>
.row_entry {
  font-size: 0.8rem;
}
.v-table tr:hover {
  background: red !important;
  cursor: pointer;
}
</style>

import VueI18n from 'vue-i18n';
import strings from './strings.json';

import Vue from "vue";

Vue.use(VueI18n);

const messages = strings

const locale = localStorage.getItem("locale") || 'de';

export const i18n = new VueI18n({
    locale: locale,
    messages
})

<template>
  <div class="spinner" v-if="show" :class="largeScreen ? 'large' : 'small'">
      <v-layout align-center justify-center column fill-height>
        <v-flex row align-center>
          <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
        </v-flex>
      </v-layout>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  data: function () {
    return {
      show: false
    }
  },
  created() {
    this.$store.watch(
        state => state.snackbar.loading,
        () => {
          this.show = this.$store.state.snackbar.loading
        }
    )
  },
  computed: {
    largeScreen() {
      const screen = this.$vuetify.breakpoint.name;
      return screen !== 'xs' && screen !== 'sm'
    }
  }
}
</script>

<style scoped>
.spinner {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
}
.large {
  margin-left: 15%;
}
</style>

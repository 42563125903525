<template>
  <div>
    <DialogConfirmerPromise
        ref="decisionModal"
    />
    <v-row class="align-center pa-0 ma-0 mt-3">
      <v-col class="col-lg-6">
        <v-form ref="myForm">
          <v-text-field
              :label="$t('Vehicle ID')"
              :placeholder="$t('Vehicle ID')"
              color="rgba(0, 0, 0, 0.5)"
              :value="vehicleId ? vehicleId : ''"
              @input="debounceVehicleId"
          />
        </v-form>
      </v-col>
      <v-col class="col-lg-5 side_title">
        <v-icon small>
          mdi-information
        </v-icon>
        <span class="ml-1">
          {{$t("Search by vehicle ID")}}
        </span>
      </v-col>
    </v-row>
    <div
        class="mt-5 logsTable"
        @scroll="onScroll"
    >
      <v-data-table
          v-if="data"
          :headers="headers"
          :items="data"
          :items-per-page="data.length"
          hide-default-footer
      >

        <template v-slot:item.id = "{ item }">
          <span class="idItem">
             {{ item.caravanId }}
          </span>
        </template>

        <template v-slot:item.moverId = "{ item }">
          <span class="idItem">
            {{ item.moverId }}
          </span>
        </template>

        <template v-slot:item.date = "{ item }">
          <span class="idItem">
             {{ getHumanReadableDate(item.date) }}
          </span>
        </template>

        <template v-slot:item.type = "{ item }">
          <span class="idItem">
            {{ getTypeString(item.type.type) }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
              icon
              @click="issueLogDownload(item)"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>

          <v-btn
              icon
              @click="issueDeleteLog(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {formatToHuReadable} from "@/utils/date";
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
import {saveAs} from 'file-saver';
import _ from 'lodash';
import {hardwareTypeString} from "@/utils/shared";

export default {
  name: "EasydriverLogs",
  components: {DialogConfirmerPromise},
  props: ['isFetching', 'data', 'vehicleId'],
  data () {
    return {
      headers: [
        {
          text: this.$t('ID'),
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: this.$t('Easydriver ID'),
          value: 'moverId'
        },
        {
          text: this.$t('Date'),
          sortable: false,
          value: 'date'
        },
        {
          text: this.$t('Hardware type'),
          sortable: false,
          value: 'type'
        },
        {
          text: this.$t('Actions'),
          sortable: false,
          value: 'actions',
          align: 'center'
        },
      ],
      uuidRule: [
          v => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(v) || this.$t('Entered text must be valid UUID')
      ]
    }
  },
  methods: {
    async issueLogDownload(item) {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/logs/${item.fileName}`, {responseType: 'blob'});
      saveAs(res.data, `${item.moverId}/${this.getHumanReadableDate(item.date)}.bin`)
    },
    async issueDeleteLog(item) {
      const res = await this.$refs.decisionModal.fireAlert(
          this.$t('Yes'),
          this.$t('No'),
          this.$t('Deleting Easydriver logs'),
          this.$t('Are you sure you want to delete Easydriver log for mover:', [item.moverId])
      );

      if (res) {
        this.$emit('deleteLog', item)
      }
    },
    getHumanReadableDate(seconds) {
      return formatToHuReadable(seconds);
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('nextPage');
      }
    },
    debounceVehicleId: _.debounce(function (e) {
      this.$emit('idChange', e)
    }, 500),
    getTypeString(item) {
      return hardwareTypeString(item)
    }
  }
}
</script>

<style scoped>
.side_title {
  font-weight: 400;
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.5);
}
.logsTable {
  max-height: 70vh;
  overflow-y: auto;
}
.idItem {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}
</style>
<template>
  <v-container>
    <v-row class="justify-center mt-15">
      <v-col class="col-md-6 col-12" v-if="caravan && manufacturersList && manufacturer">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="caravan.caravan_profile_title"
              label="Titel"
              requried
          />
          <v-text-field
              v-model="caravan.caravan_profile_type"
              label="Art"
              required
          />
          <v-text-field
              v-model="caravan.caravan_profile_axle_type"
              label="Achstyp"
              required
          />
          <v-text-field
              v-model="caravan.caravan_profile_license_plate"
              label="Nummernschild"
              required
          />
          <v-text-field
              v-model="caravan.caravan_profile_fin"
              label="Wohnwagenflosse"
              required
          />
          <v-autocomplete
              v-model="manufacturer"
              item-text="name"
              :rules="[() => !!manufacturer|| 'This field is required']"
              :items="manufacturersList"
              label="Hersteller"
              required
              return-object
          ></v-autocomplete>
          <v-text-field
              v-model="caravan.caravan_profile_dot_no"
              label="Profile dot number"
              required
          />
          <v-layout justify-center>
            <v-card-actions>
              <v-btn primary  @click="updateCaravan">
                <span>Wohnwagen aktualisieren</span>
              </v-btn>

              <v-btn color="success" @click="$router.push({name:'Vehicles'})" class="ml-10">
                <span>Zurück navigieren</span>
              </v-btn>
            </v-card-actions>
          </v-layout>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditVehicle",
  data: () =>  ({
      "valid": true,
      "caravan": null,
      "menu1": false,
      "manufacturersList": null,
      "manufacturer": null
  }),
  methods: {
    updateCaravan: function () {
      const caravanOBJ = {
        "caravan_profile_title": this.caravan.caravan_profile_title,
        "caravan_profile_type": this.caravan.caravan_profile_type,
        "caravan_profile_axle_type": this.caravan.caravan_profile_axle_type,
        "caravan_profile_license_plate": this.caravan.caravan_profile_license_plate,
        "caravan_profile_fin": this.caravan.caravan_profile_fin,
        "manufacturer_id": this.manufacturer.id,
        "caravan_profile_manufacture_date": this.caravan.caravan_profile_manufacture_date || null,
        "caravan_profile_dot_no": this.caravan_profile_dot_no,
        "caravan_profile_uuid": this.caravan.caravan_profile_uuid,
        "dirty": true
      }
      this.$store.dispatch('caravan/updateVehicle', caravanOBJ)
      .then(() => {
        this.$store.dispatch('snackbar/fireAlert', {
          "message": "Fahrzeug erfolgreich aktualisiert\n",
          "color": "success"
        })
      })
    },
    setManufacturer() {
      this.manufacturersList.forEach((manufacturer) => {
        if (manufacturer.id === this.caravan.manufacturer_id) {
          this.manufacturer = manufacturer
        }
      })
    }
  },
  props: ['vehicleOBJ'],
  mounted() {
    this.caravan = this.vehicleOBJ
    if (this.$store.getters["caravan/getManufacturersList"] != null) {
      this.manufacturersList = this.$store.getters["caravan/getManufacturersList"]
      this.setManufacturer()
    }
    else {
      this.$store.dispatch('caravan/getManufacturers')
          .then((res) => {
            this.manufacturersList = res.data
            this.setManufacturer()
          })
    }
  }
}
</script>

<style scoped>

</style>

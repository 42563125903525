<template>
  <v-card>
    <v-card-title class="text-center">
      <span class="headline">
        {{ $t('Add new product to vehicle', [vehicleData.caravan_profile_title, vehicleData.caravan_profile_uuid]) }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>

          <v-form ref="form" :style="{width: '100%'}">

            <v-col class="col-md-12 col-12">
              <v-autocomplete
                  v-model="product"
                  item-text="name"
                  :rules="[() => !!product|| $t('This field is required')]"
                  :items="products"
                  :label="$t('Manufacturer')"
                  required
                  return-object
              ></v-autocomplete>
            </v-col>

            <v-col class="col-md-12 col-12 ma-0 align-self-center">
              <v-text-field
                  :rules="productCodeRule"
                  :label="$t('Product code')"
                  :hint="$t('Unique product code of the sensor')"
                  persistent-hint
                  v-model="newProduct.product_code"
              />
            </v-col>

            <template v-if="product && product.name === 'water_filter'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Type')"
                    :hint="$t('Type')"
                    :rules="typeRule"
                    persistent-hint
                    v-model="newProduct.extra_info.type"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('ID')"
                    :hint="$t('ID')"
                    :rules="idRule"
                    persistent-hint
                    v-model="newProduct.extra_info.id"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Batch')"
                    :hint="$t('Batch')"
                    :rules="batchRule"
                    persistent-hint
                    v-model="newProduct.extra_info.batch"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('UpdateTimeUTC')"
                    :hint="$t('UpdateTimeUTC')"
                    disabled
                    persistent-hint
                    v-model="newProduct.extra_info.updateTimeUTC"
                />
              </v-col>
            </template>

            <template v-if="product && product.name === 'mpc'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('ID')"
                    :hint="$t('ID')"
                    :rules="idRule"
                    persistent-hint
                    v-model="newProduct.extra_info.id"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Name')"
                    :hint="$t('Name')"
                    :rules="batchRule"
                    persistent-hint
                    v-model="newProduct.extra_info.name"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('UpdateTimeUTC')"
                    :hint="$t('UpdateTimeUTC')"
                    disabled
                    persistent-hint
                    v-model="newProduct.extra_info.updateTimeUTC"
                />
              </v-col>
            </template>

            <template v-if="product && product.name === 'mb'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('ID')"
                    :hint="$t('ID')"
                    :rules="idRule"
                    persistent-hint
                    v-model="newProduct.extra_info.id"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Name')"
                    :hint="$t('Name')"
                    :rules="batchRule"
                    persistent-hint
                    v-model="newProduct.extra_info.name"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('UpdateTimeUTC')"
                    :hint="$t('UpdateTimeUTC')"
                    disabled
                    persistent-hint
                    v-model="newProduct.extra_info.updateTimeUTC"
                />
              </v-col>
            </template>

            <template v-if="product && product.name === 'safetyre'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Position')"
                    :hint="$t('Position of the sensor')"
                    persistent-hint
                    :rules="positionRule"
                    type="number"
                    v-model="newProduct.extra_info.position"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Display name')"
                    :hint="$t('Display name')"
                    :rules="nameRule"
                    persistent-hint
                    v-model="newProduct.extra_info.anzeigename"
                />
              </v-col>

            </template>

            <template v-if="product && product.name === 'easydriver'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Type')"
                    :hint="$t('Type')"
                    :rules="typeRule"
                    persistent-hint
                    v-model="newProduct.extra_info.type"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Name')"
                    :hint="$t('Name')"
                    persistent-hint
                    v-model="newProduct.extra_info.name"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Address')"
                    :hint="$t('Address')"
                    persistent-hint
                    v-model="newProduct.extra_info.address"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-select
                    :items="easyDriverTypes"
                    v-model="easyDriverType"
                    :placeholder="$t('Easydriver hardware type')"
                    :label="$t('Easydriver hardware type')"
                    color="rgba(0, 0, 0, 0.5)"
                    item-text="type"
                    :rules="[v => !!v || this.$t('Easydriver hardware type must be selected')]"
                    return-object
                >
                  <template v-slot:selection="{ item }">
                    {{ getTypeString(item.type) }}
                  </template>
                  <template v-slot:item="{item}">
                    {{ getTypeString(item.type) }}
                  </template>
                </v-select>
              </v-col>
            </template>

            <template v-if="product && product.name === 'mopeka'">
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Mac address')"
                    :hint="$t('Mac address')"
                    :rules="requiredRule"
                    persistent-hint
                    v-model="newProduct.extra_info.mac_address"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Sensor type')"
                    :hint="$t('Sensor type')"
                    :rules="requiredRule"
                    persistent-hint
                    v-model="newProduct.extra_info.sensor_type"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Bottle height')"
                    :hint="$t('Bottle height')"
                    type="number"
                    :rules="bottleHeightRule"
                    persistent-hint
                    v-model="newProduct.extra_info.set_bottle_height"
                />
              </v-col>
              <v-col class="col-md-12 col-12">
                <v-switch
                    :label="$t('Gas Level Notification enabled')"
                    :hint="$t('Gas Level Notification enabled')"
                    persistent-hint
                    v-model="newProduct.extra_info.gas_level_notifications_enabled"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-switch
                    :label="$t('Battery notification enabled')"
                    :hint="$t('Battery notification enabled')"
                    persistent-hint
                    v-model="newProduct.extra_info.battery_notifications_enabled"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Calibration orientation pitch')"
                    :hint="$t('Calibration orientation pitch')"
                    type="number"
                    :rules="calibrationRule"
                    persistent-hint
                    v-model="newProduct.extra_info.calibration_orientation_pitch"
                />
              </v-col>

              <v-col class="col-md-12 col-12">
                <v-text-field
                    :label="$t('Calibration orientation roll')"
                    :hint="$t('Calibration orientation roll')"
                    type="number"
                    :rules="calibrationRule"
                    persistent-hint
                    v-model="newProduct.extra_info.calibration_orientation_roll"
                />
              </v-col>
            </template>

          </v-form>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn class="ma-5 success" @click="emitSensor">
            {{ $t('CREATE') }}
          </v-btn>
          <v-btn class="ma-5 error" @click="emitClose">
            {{ $t("DISMISS") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {hardwareTypeString} from "@/utils/shared";

export default {
  name: "ProductRegistrationForm",
  props: ['vehicleData'],
  async mounted() {
    await this.$store.dispatch('easydriver/fetchEasyDriverTypes');
  },
  data() {
    return {
      products: [
        {
          name: 'safetyre'
        },
        {
          name: 'easydriver'
        },
        {
          name: 'etsplus'
        },
        {
          name: 'water_filter'
        },
        {
          name: 'mpc'
        },
        {
          name: 'mopeka'
        },
        {
          name: 'mb'
        }
      ],
      mpcTypes: [
        {
          name: 'MB'
        }
      ],
      product: null,
      positionRule: [
        p => !!p || this.$t('Position is required'),
        p => p <= 5 || this.$t('Max position is 5'),
        p => p >= 0 || this.$t('No negative position'),
      ],
      productCodeRule: [
        p => !!p || this.$t('Product code is required')
      ],
      nameRule: [
        p => !!p || this.$t("Product name is required")
      ],
      typeRule: [
        p => !!p || this.$t("Type is required")
      ],
      idRule: [
        p => !!p || this.$t('Id is required')
      ],
      requiredRule: [
        p => !!p || this.$t('Field is required')
      ],
      bottleHeightAlarmRule: [
         p => !!p || this.$t('Field is required'),
         p => p < 100 || this.$t('Max value is 100'),
         p => p > 0 || this.$t('Min value is 0')
      ],
      bottleHeightRule: [
          p => !!p || this.$t('Field is required'),
          p => p > 0 || this.$t('Min value is 0')
      ],
      batchRule: [
        p => !!p || this.$t('Batch is required')
      ],
      calibrationRule: [
        p => !!p || this.$t('Filed is required')
      ],
      newProduct: {
        "product_name": "",
        "product_code": "",
        "caravan_profile_guid": "",
        "extra_info": {
          "position": "",
          "anzeigename": "",
          "type": "",
          "id": "",
          "batch": "",
          "updateTimeUTC": "",
          "name": "",
          "address": "",
          "mac_address": "",
          "sensor_type": "",
          "set_bottle_height": "",
          "gas_level_notifications_enabled": false,
          "battery_notifications_enabled": false,
          "calibration_orientation_roll": "",
          "calibration_orientation_pitch": ""
        }
      },
      easyDriverType: ''
    }
  },
  methods: {
    emitSensor() {
      if (!this.$refs.form.validate() || !this.product) {
        return;
      }

      let productBody = {};
      this.newProduct.caravan_profile_guid = this.vehicleData.caravan_profile_uuid;
      this.newProduct.product_name = this.product.name;
      if (this.newProduct.product_name === 'easydriver') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            type: this.newProduct.extra_info.type,
            name: this.newProduct.extra_info.name !== "" ? this.newProduct.extra_info.name : undefined,
            address: this.newProduct.extra_info.address !== "" ? this.newProduct.extra_info.address : undefined,
            hardwareType: this.easyDriverType ? this.easyDriverType.type : undefined
          }
        }
      } else if (this.newProduct.product_name === 'safetyre') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            position: this.newProduct.extra_info.position,
            anzeigename: this.newProduct.extra_info.anzeigename
          }
        }
      } else if (this.newProduct.product_name === 'water_filter') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            type: this.newProduct.extra_info.type,
            id: this.newProduct.extra_info.id,
            batch: this.newProduct.extra_info.batch,
            updateTimeUTC: Date.now()
          }
        }
      } else if (this.newProduct.product_name === 'etsplus') {
        productBody = {
          ...this.newProduct,
          extra_info: {}
        }
      } else if (this.newProduct.product_name === 'mpc') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            name: this.newProduct.extra_info.name,
            updateTimeUTC: Date.now()
          }
        }
      } else if (this.newProduct.product_name === 'mb') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            name: this.newProduct.extra_info.name,
            updateTimeUTC: Date.now()
          }
        }
      } else if (this.newProduct.product_name === 'mopeka') {
        productBody = {
          ...this.newProduct,
          extra_info: {
            mac_address: this.newProduct.extra_info.mac_address,
            sensor_type: this.newProduct.extra_info.sensor_type,
            set_bottle_height: parseInt(this.newProduct.extra_info.set_bottle_height),
            gas_level_notifications_enabled: this.newProduct.extra_info.gas_level_notifications_enabled,
            battery_notifications_enabled: this.newProduct.extra_info.battery_notifications_enabled,
            calibration_orientation_pitch: this.newProduct.extra_info.calibration_orientation_pitch,
            calibration_orientation_roll: this.newProduct.extra_info.calibration_orientation_roll
          }
        }
      }
      console.log(productBody, 'productBody')
      this.$emit('onProduct', productBody);
      this.emitClose()
    },
    emitClose() {
      this.$emit('onClose', true);
      this.$refs.form.resetValidation();
    },
    cleanProduct() {
      this.newProduct = {
        "product_name": "",
        "product_code": "",
        "caravan_profile_guid": "",
        "extra_info": {
          "position": "",
          "anzeigename": "",
          "type": "",
          "id": "",
          "batch": "",
          "updateTimeUTC": "",
          "name": "",
          "address": "",
          "mac_address": "",
          "product_version": "",
          "set_bottle_height": "",
          "notifications_enabled": "",
          "bottle_height_alarm_limit": ""
        }
      }
    },
    getTypeString(item) {
      return hardwareTypeString(item)
    }
  },
  watch: {
    product(newVal, oldVal) {
      if (newVal && oldVal && newVal.name !== oldVal.name) {
        this.cleanProduct();
        this.$refs.form.resetValidation();
      }
    }
  },
  computed: {
    easyDriverTypes() {
      return this.$store.getters['easydriver/easyDriverTypes'];
    }
  }
}
</script>

<style scoped>

</style>

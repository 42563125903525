export const formatToHuReadable = (seconds) => {

    const date = new Date();
    date.setTime(seconds * 1000);

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const hours = date.getHours()
    const minutes = date.getMinutes()

    return `${day}-${month}-${year} ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}`: minutes}`
}


// Helper function to extract date
export const getDateString = (seconds) => {
    const date = new Date(seconds * 1000);
    return `${date.getFullYear()}-${date.getUTCMonth() + 1}-${date.getDate()}`;
}

import httpClient from "@/utils/httpClient";
import {constructURL} from "@/utils/URL";

/**
 * Gets sensors based on search criteria
 * @param productName - product name
 * @param productUid - uid of product
 * @param userEmail - email address
 * @param offset - pagination offset
 * @param limit - pagination limit
 * @param abortController - abort controller for canceling request
 */
export const getSensors = async (
    productName,
    productUid,
    userEmail,
    offset,
    limit,
    abortController
) => {

    const res = await httpClient.get(
        constructURL(
           `${process.env.VUE_APP_API_URL}/sensors`,
            [
                {
                    name: 'productName',
                    value: productName
                },
                {
                    name: 'productUid',
                    value: productUid
                },
                {
                    name: 'userEmail',
                    value: userEmail
                }
            ],
            {
                offset,
                limit
            }
        ),
        {
            signal: abortController.signal
        }
    )

    return res.data
}

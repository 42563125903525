<template>
  <div class="contentContainer">
    <div class="mainContainer">
      <img :src="require(`../assets/flags/${country}.png`)" class="flagImage">
      <TextEnterModal
          ref="mEnterTextModal"
          @onDescription="onDescription"
      />
    </div>
    <v-text-field
        class="ml-2"
        :placeholder="$t('Description in', [$t(name)])"
        v-model="currentDescription"
        persistent-placeholder
        persistent-hint
        :hint="$t('Enter description in', [$t(name)])"
    />
    <v-icon class="iconContainer" :color="supported ? 'green' : ''" size=30>
      {{supported ? 'mdi-check' : ''}}
    </v-icon>
  </div>
</template>

<script>
import TextEnterModal from "@/components/TextEnterModal";
export default {
  name: "EasyDriverLanguageFlag",
  components: {TextEnterModal},
  props: ['country', 'name'],
  data: () => {
    return {
      currentDescription: ''
    }
  },
  methods: {
    onIconClick() {
      this.$refs.mEnterTextModal.showModal();
    },
    onDescription(description) {
      this.currentDescription = description
      this.$refs.mEnterTextModal.hideModal();
      this.$emit('onLanguage', {
        country: this.country,
        description: this.currentDescription
      })
    }
  },
  computed: {
    supported() {
      return this.currentDescription;
    }
  },
  watch: {
    currentDescription(newVal) {
      this.$emit('onLanguage', {
        country: this.country,
        description: newVal
      });
    }
  }
}
</script>

<style scoped>
.flagImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.supportedBorder {
  border: 4px solid green;
}

.nonSupportedBorder {
  border: 4px solid red;
}

.mainContainer {
  position: relative;
  display: flex;
  max-width: 60px;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  left: 5px;
  top: 13px;
}
.itemText {
  font-weight: 500;
  font-size: 0.8rem;
  position: absolute;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.5);
}
.contentContainer {
  display: flex;
  flex-direction: row;
}
</style>
<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-avatar
          color="grey"
          size="56"
          class="white --text"
      >
        {{comment.user_login[0].toUpperCase()}}
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>

        {{ comment.user_login }}
        <template v-if="!editMode">
          <v-icon small class="ml-2" @click="emitDelete">
            mdi-delete
          </v-icon>
          <v-icon small class="ml-2" @click="enterEditMode">
            mdi-pencil
          </v-icon>
        </template>
        <template v-else>
          <v-icon small class="ml-2" @click="emitEdited">
            mdi-check
          </v-icon>
          <v-icon small class="ml-2" @click="dismissChanges">
            mdi-cancel
          </v-icon>
        </template>
      </v-list-item-title>

      <v-list-item-subtitle>
        <v-rating
            v-if="!editMode"
            :value="comment.poi_rating_stars"
            color="amber"
            dense
            half-increments
            readonly
            size="15"
        >
        </v-rating>

        <v-rating
            v-else
            v-model="editedComment.poi_rating_stars"
            color="amber"
            dense
            size="15"
        >

        </v-rating>
      </v-list-item-subtitle>

      <v-list-item-action-text v-if="!editMode">
        {{comment.poi_rating_text}}
      </v-list-item-action-text>

      <v-list-item-action-text v-else>
        <v-textarea v-model="editedComment.poi_rating_text" style="font-size: 0.75rem;margin: 0;" row-height="10" rows="2">

        </v-textarea>
      </v-list-item-action-text>

    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "PoiComment",
  props: ['comment'],
  data: function () {
    return {
      editMode: false,
      editedComment: ''
    }
  },
  methods: {
    emitDelete() {
      this.$emit('delete', this.comment)
    },
    emitEdited() {
      this.$emit('edit', this.editedComment)
      this.editMode = false;
    },
    enterEditMode() {
      this.editMode = true
    },
    dismissChanges() {
      this.editedComment = {...this.comment}
      this.editMode = false
    }
  },
  mounted() {
    this.editedComment = {...this.comment}
  }
}
</script>

<style scoped>

</style>

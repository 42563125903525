<template>
  <v-dialog
      width="500"
      v-model="isDialogVisible"
  >

    <v-card>
      <v-card-title class="headline text-center">
        {{$t("Reset your password")}}
      </v-card-title>

      <v-card-text>
        {{$t("To reset your forgotten password, please enter your email address associated with the MyCaravan account")}}
      </v-card-text>

      <v-container class="form_container ml-5">
        <v-text-field
            v-model="email"
            :rules="emailRules"
            rounded
            outlined
            :placeholder="$t('Email address')"
        >
        </v-text-field>
      </v-container>

      <v-card-actions class="justify-center">
        <v-btn :disabled="validEmail" @click="sendEmailResetRequest">
          {{$t('RESET PASSWORD')}}
        </v-btn>

        <v-btn @click="isDialogVisible=false">
          {{$t('DENY')}}
        </v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "ForgotPassword",
  data: function () {
    return {
      email: "",
      emailRules: [
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('E-mail must be valid')
      ]
    }
  },
  props: ["dialogVisible"],
  computed: {
    isDialogVisible: {
      get: function () {
        return this.dialogVisible
      },
      set: function (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    validEmail : {
      get: function () {
        return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
      }
    }
  },
  methods: {
    sendEmailResetRequest: function () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/password-request?emailAddress=${this.email}`)
      .then(()=> {
        this.$store.dispatch('snackbar/fireAlert', {
          "message": this.$t('Email success password reset', [this.email]),
          "color": "success"
        })
      })
      .catch(() => {
        this.$store.dispatch('snackbar/fireAlert', {
          "message": this.$t('Email error password reset'),
          "color": "error"
        })
      })
    }
  }
}
</script>

<style scoped>
.form_container {
  width: 70%;
}

</style>

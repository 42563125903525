var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"height":"70vh"},attrs:{"column":""}},[_c('v-row',[_c('v-col',{staticClass:"col-md-5 col-5 mt-10"},[(_vm.useFilter)?_c('v-text-field',{attrs:{"placeholder":_vm.$t('Name')},on:{"input":_vm.debounceUserName}}):_vm._e()],1),_c('v-col',{staticClass:"col-md-5 col-5 mt-10"},[(_vm.useFilter)?_c('v-text-field',{attrs:{"placeholder":_vm.$t('Email')},on:{"input":_vm.debounceEmailAddress}}):_vm._e()],1)],1),_c('div',{staticClass:"mt-5",staticStyle:{"overflow":"auto","height":"60vh"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{class:_vm.useFilter ? ['elevation-1', 'cursorTable'].join(' ') : ['elevation-1', 'cursorTable', 'mt-11'].join(' '),attrs:{"headers":_vm.headers,"items":_vm.audience,"hide-default-footer":"","items-per-page":_vm.audience.length,"item-key":"user.user_guid"},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.firebaseAssociated",fn:function(ref){
var item = ref.item;
return [(item.firebaseAssociated)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('User will receive push notification')))])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('User will not receive push notification')))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog max-width="400px" v-model="dialog">
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: "DialogCustom",
  data: function () {
    return {
      dialog: false
    }
  },
  methods: {
    fireDialog() {
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-row class="justify-center mt-5 main_container">
    <EasyDriverFirmwareUploader
        v-if="fwEasyDriverTypes && easyDriverFwUpdateTypes"
        ref="mEasyDriverFirmwareDialog"
        :easy-driver-types="fwEasyDriverTypes"
        :firmware-update-types="easyDriverFwUpdateTypes"
        @newFw="onNewFirmware"
    />

    <v-col class="col-lg-6 pl-6">
      <span class="header_title">
        {{$t('Logs')}}
      </span>
      <EasydriverLogs
          :is-fetching="isFetchingEasyDriverLogs"
          :data="easyDriverLogs"
          :vehicle-id="easyDriverVehicleId"
          @nextPage="fetchNextLogsPage"
          @idChange="handleEasyDriverIdChange"
          @deleteLog="deleteLog"
      />
    </v-col>
    <v-divider vertical/>
    <v-col class="col-lg-6 pl-6 firmwareContainer">
      <span class="header_title">
        {{$t("Firmware")}}
      </span>
      <div class="uploadFirmwareButton">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="$refs.mEasyDriverFirmwareDialog.openForm()"
            >
              <v-icon>
                mdi-upload
              </v-icon>
            </v-btn>
          </template>
          <span class="uploadFirmwareText">
            {{$t('Upload new firmware')}}
          </span>
        </v-tooltip>
      </div>
      <EasyDriverFirmware
          v-if="easyDriverFwUpdateTypes"
          :easy-driver-types="fwEasyDriverTypes"
          :easy-driver-type="easyDriverSelectedType"
          :fw-data="easyDriverFirmware"
          @typeChange="handleItemTypeChange"
          @fwDelete="handleFwDelete"
          @fwDownload="handleFwDownload"
          @userReadyChange="handleUserReadyChange"
          @serviceReadyChange="handleServiceReadyChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import EasyDriverFirmwareUploader from "@/components/EasyDriverFirmwareUploader";
import EasydriverLogs from "@/components/EasydriverLogs";
import EasyDriverFirmware from "@/components/EasyDriverFirmware";
import {saveAs} from 'file-saver';
import {i18n} from "@/i18n/i18n";

export default {
  name: "Easydriver",
  components: {EasyDriverFirmware, EasydriverLogs, EasyDriverFirmwareUploader},
  async mounted() {
    const easyDriverPaginationInfo = this.$store.getters['easydriver/easyDriverLogsPaginationInfo'];
    const easyDriverLogsData = this.$store.getters['easydriver/easyDriverLogs'];
    const fetching = this.$store.getters['easydriver/isFetchingEasyDriverLogs'];
    const easyDriverUpdateTypes = this.$store.getters['easydriver/easyDriverUpdateTypes'];

    await this.$store.dispatch('easydriver/fetchEasyDriverTypes');
    if (!easyDriverUpdateTypes) {
      await this.$store.dispatch('easydriver/fetchEasyDriverFwUpdateTypes');
    }
    if (easyDriverPaginationInfo.offset === 0 && !fetching) {
      await this.$store.dispatch('easydriver/fetchEasyDriverLogs');
    } else if (easyDriverLogsData.length <= 0) {
      await this.$store.dispatch('easydriver/resetToDefault', '');
      await this.$store.dispatch('easydriver/fetchEasyDriverLogs');
    }
  },
  computed: {
    isFetchingEasyDriverLogs() {
      return this.$store.getters['easydriver/isFetchingEasyDriverLogs'];
    },
    easyDriverLogs() {
      return this.$store.getters['easydriver/easyDriverLogs'];
    },
    easyDriverLogsPaginationInfo() {
      return this.$store.getters['easydriver/easyDriverLogsPaginationInfo'];
    },
    easyDriverVehicleId() {
      return this.$store.getters['easydriver/easyDriverLogsVehicleId'];
    },
    easyDriverTypes() {
      return this.$store.getters['easydriver/easyDriverTypes'];
    },
    easyDriverSelectedType() {
      return this.$store.getters['easydriver/easyDriverSelectedType'];
    },
    easyDriverFwUpdateTypes() {
      return this.$store.getters['easydriver/easyDriverUpdateTypes'];
    },
    easyDriverFirmware() {
      return this.$store.getters['easydriver/easyDriverFirmware'];
    },
    easyDriverFwFetchInProgress() {
      return this.$store.getters['easydriver/easyDriverFwFetchInProgress'];
    },
    fwEasyDriverTypes() {
      if (!this.easyDriverTypes) return []
      // Firmware update cannot be easydriver
      return this.easyDriverTypes.filter((it) => it.id < 5)
    }
  },
  methods: {
    async fetchNextLogsPage() {
      if (!this.isFetchingEasyDriverLogs) {
        await this.$store.dispatch('easydriver/fetchEasyDriverLogs');
      }
    },
    async handleEasyDriverIdChange(id) {
      if (this.easyDriverVehicleId !== id) {
        await this.$store.dispatch('easydriver/resetToDefault', id);
        await this.$store.dispatch('easydriver/fetchEasyDriverLogs');
      }
    },
    async deleteLog(item) {
      await this.$store.dispatch('easydriver/deleteLog', item.fileName);
    },
    async handleItemTypeChange(item) {
      const currentType = this.easyDriverSelectedType;
      if ( (currentType && item && currentType.id === item.id) || (!currentType && !item) ) return

      await this.$store.dispatch('easydriver/setEasyDriverSelectedType', item);
    },
    async onNewFirmware(firmware) {
      this.$refs.mEasyDriverFirmwareDialog.closeForm();
      try {
        await this.$store.dispatch('easydriver/uploadFirmware', firmware);
        this.$refs.mEasyDriverFirmwareDialog.clearForm();
        await this.$store.dispatch('snackbar/fireAlert', {
          message: i18n.t('Your firmware was uploaded successfully !'),
          color: 'success'
        });
      } catch (e) {
        this.$refs.mEasyDriverFirmwareDialog.openForm();
      }
    },
    getSuffix(serviceReady, userReady) {
      if (userReady && serviceReady) {
        return "prod"
      } else if (userReady) {
        return "prod"
      } else if (serviceReady) {
        return "dev"
      } else {
        return 'none'
      }
    },
    async handleFwDelete(item) {
      await this.$store.dispatch('easydriver/deleteFirmwareVersion', item);
    },
    async handleFwDownload(item) {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/admin/easydriver/firmware/${item.id}`, {responseType: 'blob'});
      saveAs(res.data, `${item.version}-${item.easyDriverType.type}-${this.getSuffix(item.serviceReady, item.userReady)}.hex`);
    },
    async handleUserReadyChange(item) {
      await this.$store.dispatch('easydriver/toggleUserReady', item);
    },
    async handleServiceReadyChange(item) {
      await this.$store.dispatch('easydriver/toggleServiceReady', item)
    }
  }
}
</script>

<style scoped>
.header_title {
  font-weight: 100;
  font-size: 1.3rem;
}

.main_container {
  min-height: 90vh;
}

.firmwareContainer {
  position: relative;
}

.uploadFirmwareButton {
  position: absolute;
  right: 40px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadFirmwareText {
  font-size: 0.8rem;
  font-weight: 500;
}
</style>

<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="items.length"
        :loading="isFetching"
        hide-default-footer
        disable-filtering
        disable-sort
    >
        <template v-slot:item.extraInfo = "{ item }">
            <div class="extraInfoContainer">
                <template v-if="item.extraInfo">
                    <p
                        v-for="key in Object.keys(item.extraInfo)"
                        :key="`${key} - ${item.extraInfo[key]} - ${item.vehicleId}`"
                        class="ma-0 pa-0"
                    >
                        <b>
                            {{ key }}:
                        </b>
                        {{ item.extraInfo[key] }}
                    </p>
                </template>
                <template v-else>
                    <p>
                        {{ $t('No extra info available') }}
                    </p>
                </template>
            </div>
        </template>

        <template v-slot:item.userEmail="{ item }">
            <div>
                <FilteredField
                    :field-value="item.userEmail"
                    :applied-filter="emailFilter.toLowerCase()" />
            </div>
        </template>

        <template v-slot:item.productId="{ item }">
            <div>
                <FilteredField
                    :field-value="item.productId"
                    :applied-filter="sensorFilter.toLowerCase()" />
            </div>
        </template>

        <template v-slot:item.productName="{ item }">
            <div>
                <FilteredField
                    :field-value="item.productName"
                    :applied-filter="sensorFilter.toLowerCase()" />
            </div>
        </template>

        <template v-slot:item.actions="{ item }">
            <div>
                <v-btn
                    @click="$emit('caravanClick', item)"
                    icon
                >
                    <v-icon>
                        mdi-location-enter
                    </v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import FilteredField from "@/components/FilteredField.vue";

export default {
    name: "SensorsTable",
    components: {FilteredField},
    data: function () {
        return {
            headers: [
                {
                    text: this.$t('Vehicle ID'),
                    value: 'vehicleId'
                },
                {
                    text: this.$t('User email'),
                    value: 'userEmail'
                },
                {
                    text: this.$t('Product name'),
                    value: 'productName'
                },
                {
                    text: this.$t('Product ID'),
                    value: 'productId'
                },
                {
                    text: this.$t('Extra info'),
                    value: 'extraInfo'
                },
                {
                    text: this.$t('Actions'),
                    value: 'actions'
                }
            ]
        }
    },
    props: {
        items: {
            required: true,
            type: Array
        },
        isFetching: {
            required: false,
            default: true
        },
        emailFilter: {
            required: true
        },
        sensorFilter: {
            required: true
        }
    }
}
</script>

<style scoped>
.extraInfoContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
</style>

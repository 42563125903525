var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"loading":_vm.isFetching,"hide-default-footer":"","disable-filtering":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.extraInfo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"extraInfoContainer"},[(item.extraInfo)?_vm._l((Object.keys(item.extraInfo)),function(key){return _c('p',{key:(key + " - " + (item.extraInfo[key]) + " - " + (item.vehicleId)),staticClass:"ma-0 pa-0"},[_c('b',[_vm._v(" "+_vm._s(key)+": ")]),_vm._v(" "+_vm._s(item.extraInfo[key])+" ")])}):[_c('p',[_vm._v(" "+_vm._s(_vm.$t('No extra info available'))+" ")])]],2)]}},{key:"item.userEmail",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('FilteredField',{attrs:{"field-value":item.userEmail,"applied-filter":_vm.emailFilter.toLowerCase()}})],1)]}},{key:"item.productId",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('FilteredField',{attrs:{"field-value":item.productId,"applied-filter":_vm.sensorFilter.toLowerCase()}})],1)]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('FilteredField',{attrs:{"field-value":item.productName,"applied-filter":_vm.sensorFilter.toLowerCase()}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('caravanClick', item)}}},[_c('v-icon',[_vm._v(" mdi-location-enter ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
export const reichProducts = {
    EASYDRIVER: 'easydriver',
    SAFETYRE: 'safetyre',
    WATER_FILTER: 'water_filter',
    MPC: 'mpc',
    MOPEKA: 'mopeka',
    MB: 'mb'
}

export const hardwareTypeString = (item) => {
    switch (item) {
        case 'easydriver_ng_5_relays':
            return 'easydriver NG (5 relays)';
        case 'easydriver_ng_5_relays_2_inverted':
            return 'easydriver NG (5 relays, 2 inverted)';
        case 'easydriver_ng_h_bridge':
            return 'easydriver NG (H-bridge)';
        case 'easydriver_pro_h_bridge':
            return 'easydriver PRO (H-bridge)';
        case 'easydriver':
            return 'easydriver'
        default:
            return '';
    }
}

export const productString = (product) => {
    switch (product) {
        case 'easydriver':
            return 'EasyDriver'
        case 'water_filter':
            return 'WaterFilter'
        case 'etsplus':
            return 'ETSPlus'
        case 'safetyre':
            return 'SafeTyre'
        case 'mpc':
            return 'Mobility Power Case (MPC)'
        case 'mb':
            return 'MB'
        case 'mopeka':
            return 'Mopeka'
    }
}

export function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}

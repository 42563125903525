<template>
  <v-container>
    <v-expand-transition>

      <v-row v-if="editingPhase">
        <v-col class="col-lg-6">

          <v-expand-transition>
            <div  v-if="enabledPush">
              <div class="col-lg-5">
                <p class="header_title">{{$t('Notification preview')}} </p>
                <v-divider></v-divider>
              </div>
              <div class="mt-5 col-lg-9">
                <MobilePhone :notification-o-b-j="notificationOBJ"/>
              </div>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <v-col class="col-lg-9 mt-10" v-if="enabledEmail">
              <div class="col-lg-7">
                <p class="header_title">
                  {{$t('Email preview')}}
                </p>
                <v-divider></v-divider>
              </div>
              <div class="mt-5">
                <EmailTemplate :notification-o-b-j="notificationOBJ" />
              </div>
            </v-col>
          </v-expand-transition>
        </v-col>

        <v-col class="col-lg-6">
          <div class="col-lg-5">
            <p class="header_title">
              {{$t('Input parameters')}}
            </p>
            <v-divider></v-divider>
          </div>
          <div class="mt-5 col-lg-9">
            <v-form ref="notificationForm">
              <div class="optionsContainer">
                <v-checkbox :label="$t('Push notification')" class="ma-2" v-model="enabledPush" :disabled="enabledPush && isChoiceDisabled" />
                <v-checkbox :label="$t('Email notification')" class="ma-2" v-model="enabledEmail" :disabled="enabledEmail && isChoiceDisabled" />
              </div>
              <template v-if="enabledPush">
                <p class="header_title">
                  {{$t("Push notification")}}
                </p>
                <v-col class="col-lg-6 ma-0 pa-0">
                  <v-divider/>
                </v-col>
                <v-text-field
                    class="mt-2"
                    :rules="nonEmptyRule"
                    v-model="notificationOBJ.pushNotificationTitle"
                    :label="$t('Push notification title')"
                    required
                    background-color="white"
                    :placeholder="$t('Push notification title')"
                />

                <v-textarea
                    class="mt-2"
                    :rules="nonEmptyRule"
                    no-resize
                    height="100"
                    v-model="notificationOBJ.pushNotificationContent"
                    outlined
                    :label="$t('Push notification content')"
                    required
                    background-color="white"
                    :placeholder="$t('Push notification content')"
                />
              </template>
              <template v-if="enabledEmail">
                <p class="header_title">
                  {{$t('Email notification')}}
                </p>
                <v-col class="col-lg-6 ma-0 pa-0">
                  <v-divider/>
                </v-col>

                <v-text-field
                    class="mt-2"
                    v-model="notificationOBJ.emailNotificationSubject"
                    :label="$t('Email subject')"
                    :rules="nonEmptyRule"
                    background-color="white"
                    :placeholder="$t('Email subject')"
                />

                <v-text-field
                    class="mt-2"
                    v-model="notificationOBJ.emailNotificationTitle"
                    :label="$t('Email notification title')"
                    :rules="nonEmptyRule"
                    background-color="white"
                    :placeholder="$t('Email notification title')"
                />

                <v-textarea
                    class="mt-2"
                    :rules="nonEmptyRule"
                    no-resize
                    height="100"
                    v-model="notificationOBJ.emailNotificationContent"
                    outlined
                    :label="$t('Email content')"
                    required
                    background-color="white"
                    :placeholder="$t('Email content')"
                    :prepend-icon="null"
                />
                <v-checkbox
                    :label="$t('Same as Push notification content')"
                    v-model="sameAsPushNotificationRadio"
                >
                </v-checkbox>
              </template>
            </v-form>
          </div>
        </v-col>
      </v-row>

    </v-expand-transition>

    <v-expand-transition>
      <v-row>
        <DialogConfirmerPromise ref="dialog"/>
        <v-container class="mt-10">
          <v-row>
            <v-col class="col-lg-6">
              <v-col class="col-lg-8 ma-0 pa-0">
                <div class="headerContainer">
                  <p class="header_title pa-0 ma-0 ml-2">
                    {{$t('Audience available')}}
                  </p>
                  <v-switch
                      class="ml-2"
                      :label="$t('Send to all users')"
                      v-model="allUsers"
                      color="success"
                  />
                </div>
                <v-divider></v-divider>
              </v-col>
              <AudienceTable
                  class="mt-1"
                  :use-filter="true"
                  :audience="fetchedAudience"
                  @getNextPage="fetchNextPage"
                  @filterChange="onFilterChange"
                  @onParticipantAdd="onParticipantAdd"
              />
            </v-col>
            <v-col class="col-lg-6">
              <v-col class="col-lg-6 ma-0 pa-0">
                <div class="headerContainer pt-12">
                  <p class="header_title">
                    {{$t('Targeted audience')}}
                  </p>
                </div>
                <v-divider></v-divider>
              </v-col>
              <AudienceTable
                  v-if="!allUsers"
                  :use-filter="false"
                  :audience="receiveList"
                  @onParticipantAdd="onTargetAudienceClick"
              />
              <div class="allAudienceContainer" v-else>
                <p class="allAudienceTitle">
                  {{$t('Notifications will be sent to all registered users')}}
                  <v-icon>
                    mdi-information
                  </v-icon>
                </p>
                <template v-if="allUsers && basicStats">
                  <p v-if="enabledEmail"> <span style="font-weight: 100">{{$t('Number of targeted users')}}</span>: {{basicStats.totalUserCount}}</p>
                  <p v-if="enabledPush"> <span style="font-weight: 100">{{$t('Number of users receiving push notification')}}</span>: {{basicStats.firebaseAssociated}}</p>
                </template>
              </div>
            </v-col>
          </v-row>
          <v-col class="col-lg-12 notificationButtonContainer">
            <v-btn
                class="pa-6"
                rounded
                @click="onSendPushNotification"
            >
              {{$t('SEND NOTIFICATIONS')}}
            </v-btn>
          </v-col>
        </v-container>
      </v-row>
    </v-expand-transition>
  </v-container>
</template>

<script>
import MobilePhone from "@/components/MobilePhone";
import EmailTemplate from "@/components/EmailTemplate";
import AudienceTable from "@/components/AudienceTable";
import {constructURL} from "@/utils/URL";
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";

function initialState () {
  return {
    notificationOBJ: {
      'pushNotificationTitle': '',
      'pushNotificationContent': '',
      'emailNotificationContent': '',
      'emailNotificationSubject': '',
      'emailNotificationTitle': ''
    },
    enabledPush: true,
    enabledEmail: true,
    nonEmptyRule: [
      v => !!v || 'Field si required'
    ] ,
    editingPhase: true,
    sameAsPushNotificationRadio: false,
    fetchedAudience: [],
    filters: [],
    receiveList: [],
    allUsers: false,
    basicStats: null
  }
}

export default {
  name: "Messaging",
  components: {DialogConfirmerPromise, AudienceTable, EmailTemplate, MobilePhone},
  data: function () {
    return initialState();
  },
  watch: {
    notificationOBJ: {
      handler(val) {
        if (this.sameAsPushNotificationRadio) {
          this.notificationOBJ.emailNotificationContent = val.pushNotificationContent;
          this.notificationOBJ.emailNotificationTitle = val.pushNotificationTitle;
        }
      },
      deep: true
    },
    sameAsPushNotificationRadio: {
      handler(val) {
        if (val) {
          this.notificationOBJ.emailNotificationContent = this.notificationOBJ.pushNotificationContent;
          this.notificationOBJ.emailNotificationTitle = this.notificationOBJ.pushNotificationTitle;
        }
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('footer/hideFooter');
    this.fetchedAudience = await this.fetchAudience({
      offset: 0,
      limit: 20
    }, this.filters);

    this.basicStats = (await this.$http.get(`${process.env.VUE_APP_API_URL}/messaging/stats`)).data;
  },
  beforeDestroy() {
    this.$store.dispatch('footer/showFooter');
  },
  computed: {
    isChoiceDisabled () {
      return this.enabledEmail === false || this.enabledPush === false
    },
    validForm() {
      return this.$refs.notificationForm?.validate() || false;
    },
    sendNotificationAllowed() {
      return this.receiveList.length > 0 || this.allUsers;
    }
  },
  methods:  {
    endEditingPhase() {
      if (this.$refs.notificationForm.validate()) {
        this.editingPhase = false;
      }
    },
    async fetchNextPage({offset, limit}) {
      let fetch = await this.fetchAudience({offset, limit}, this.filters);

      this.fetchedAudience = [
          ...this.fetchedAudience,
          ...fetch
      ]
    },
    async fetchAudience({offset, limit}, filters) {
      const baseURL = constructURL(
          `${process.env.VUE_APP_API_URL}/messaging/audience`,
          filters,
          {offset, limit}
      );
      let fetch = (await this.$http.get(baseURL)).data;
      fetch = fetch.filter((it) => {
        const match = this.fetchedAudience.find((item) => it.user.user_guid === item.user.user_guid)
        return match === undefined;
      });
      fetch = fetch.filter((it) => {
        const match = this.receiveList.find((item) => it.user.user_guid === item.user.user_guid);
        return match === undefined;
      });
      return fetch;
    },
    async onFilterChange(filters) {
      this.fetchedAudience = [];
      this.fetchedAudience = await this.fetchAudience({
        offset: 0,
        limit: 20
      }, filters)
      this.filters = filters;
    },
    onParticipantAdd(participant) {
      // Remove participant from audience list and put him to receive list
      this.fetchedAudience = this.fetchedAudience.filter((it) => it.user.user_guid !== participant.user.user_guid);
      this.receiveList.push(participant);
    },
    onTargetAudienceClick(participant) {
      // Remove participant from receive list and put him to audience list
      this.fetchedAudience.push(participant);
      this.receiveList = this.receiveList.filter((it) => it.user.user_guid !== participant.user.user_guid)
    },
    async onSendPushNotification() {

      const pnFormCorrect =  this.$refs.notificationForm?.validate() || false;
      const audienceCorrect = this.receiveList.length > 0 || this.allUsers;

      if (!pnFormCorrect) {
        return await this.$store.dispatch('snackbar/fireAlert', {
          message: this.$t('Input parameters for not set correctly, please check the validity of the push notification form and email form'),
          color: 'error'
        });
      }
      if (!audienceCorrect) {
        return await this.$store.dispatch('snackbar/fireAlert', {
          message: this.$t('The target group is not set correctly, please check the validity of the form for the target group'),
          color: 'error'
        });
      }

      const res = await this.$refs.dialog.fireAlert(
          this.$t('Continue'),
          this.$t('Deny'),
          this.$t('Send messages'),
          this.$t('You are about to send messages to customers. Make sure the content of the messages is correct as this step cannot be undone')
      );
      if (!res) {
        return
      }
      if (this.allUsers) {
        await this.$http.post(`${process.env.VUE_APP_API_URL}/messaging/all/send`, {
          audience : [],
          pushNotification: this.enabledPush,
          emailNotification: this.enabledEmail,
          messageContent: {
            ...this.notificationOBJ
          }
        });
      } else {
        await this.$http.post(`${process.env.VUE_APP_API_URL}/messaging/send`, {
          audience: this.receiveList,
          pushNotification: this.enabledPush,
          emailNotification: this.enabledEmail,
          messageContent: {
            ...this.notificationOBJ
          }
        });
      }

      await this.$store.dispatch('snackbar/fireAlert', {
        color: 'success',
        message: this.$t('You have successfully sent messages')
      });

      await this.resetComponent();

    },
    async resetComponent() {
      Object.assign(
          this.$data,
          initialState()
      );
      this.basicStats = (await this.$http.get(`${process.env.VUE_APP_API_URL}/messaging/stats`)).data;
    }
  }
}
</script>

<style scoped>
.header_title {
  font-weight: 100;
  font-size: 1.3rem;
}
.fabButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.fabButtonContainerBack {
  display: flex;
  width: 100%;
  justify-content: center;
}
.optionsContainer {
  display: flex;
  flex-direction: row;
}
.notificationButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}
.headerContainer {
  display: flex;
  vertical-align: center;
  flex-direction: column;
}
.allAudienceContainer {
  margin-top: 2%;
}
.allAudienceTitle {
  font-weight: 100;
  font-size: 1.25rem;
}
</style>

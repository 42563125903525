<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
  >
    <v-card tile>
      <v-toolbar
          flat
          dark
          color="#282424"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{$t('Upload new version of firmware')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-6">
        <v-row class="">
          <v-col class="col-lg-6">
            <v-form ref="easyDriverFwForm" v-if="dialog">
              <v-text-field
                  v-model="version"
                  color="rgba(0, 0, 0, 0.5)"
                  :label="$t('Version name')"
                  :placeholder="$t('Version name')"
                  :hint="$t('Version name should be in following format example. 1.0')"
                  :rules="[v => /^[0-9]{1,2}\.[0-9]{1,2}$/.test(v) || $t('Version must be in correct format')]"
              />
              <v-select
                  :items="easyDriverTypes"
                  v-model="easydriver_type_id"
                  :placeholder="$t('Easydriver hardware type')"
                  label="Easydriver hardware type"
                  color="rgba(0, 0, 0, 0.5)"
                  item-text="type"
                  :rules="[v => !!v || $t('Easydriver hardware type must be selected')]"
                  return-object
              >
                <template v-slot:selection="{ item }">
                  {{getTypeString(item.type)}}
                </template>
                <template v-slot:item="{item}">
                  {{getTypeString(item.type)}}
                </template>
              </v-select>
              <v-select
                  :items="firmwareUpdateTypes"
                  v-model="firmware_update_type_id"
                  :placeholder="$t('Easydriver firmware update type')"
                  :label="$t('Easydriver firmware update type')"
                  color="rgba(0, 0, 0, 0.5)"
                  item-text="type"
                  :rules="[v => !!v || $t('Easydriver firmware update type must be selected')]"
                  return-object
              />
              <v-file-input
                  v-model="firmware"
                  accept=".hex"
                  color="rgba(0, 0, 0, 0.5)"
                  :placeholder="$t('Firmware binary file (HEX)')"
                  :label="$t('Firmware binary file (HEX)')"
                  :prepend-icon="null"
                  show-size
                  :rules="[
                       v => !!v || $t('File is required'),
                       v => (v && v.size > 0) || $t('File is required'),
                  ]"
              />
              <v-checkbox
                  v-model="userReady"
                  color="rgba(0, 0, 0, 0.5)"
                  :hint="$t('If firmware is user ready mark as selected')"
                  :label="$t('User ready')"
              />

              <v-checkbox
                  v-model="serviceReady"
                  color="rgba(0, 0, 0, 0.5)"
                  :hint="$t('If firmware is service ready mark as selected')"
                  :label="$t('Service ready')"
              />
            </v-form>
          </v-col>
          <v-col class="col-lg-6">
            <div>
              {{$t('Update description:')}}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey lighten-1"
                          v-bind="attrs"
                          v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <span>
                  {{$t('In order to add description for given language enter it into input fields down bellow')}}
                  </span>
              </v-tooltip>
            </div>
            <div class="supportedLanguagesForm mb-5 mt-2" v-if="dialog">
              <EasyDriverLanguageFlag
                  v-for="item in supportedLanguages"
                  :country="item.country"
                  class="ma-2"
                  :name="item.name"
                  :supported="langData[item.country] && langData[item.country].description"
                  :key="item.name"
                  @onLanguage="handleLanguage"
              />
            </div>
            <div class="errMsgContainer" v-if="langErr">
              {{$t('* German and English language support is necessary, please add them !')}}
            </div>
          </v-col>
        </v-row>

        <v-btn
            class="mt-4"
            color="rgba(0, 0, 0, 0.5)"
            dark
            @click="uploadFirmware"
        >
          {{$t("Upload firmware")}}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EasyDriverLanguageFlag from "@/components/EasyDriverLanguageFlag";
import {hardwareTypeString} from "@/utils/shared";

export default {
  name: "EasyDriverFirmwareUploader",
  components: {EasyDriverLanguageFlag},
  props: ['easyDriverTypes', 'firmwareUpdateTypes'],
  data: function () {
    return {
      dialog: false,
      version: '',
      serviceReady: false,
      userReady: false,
      firmware_update_type_id: '',
      firmware: '',
      easydriver_type_id: '',
      languageErr: '',
      supportedLanguages: [
        {
          country: 'EN',
          name: 'English'
        },
        {
          country: 'DE',
          name: 'German'
        },
        {
          country: 'SL',
          name: 'Slovenian'
        },
        {
          country: 'NL',
          name: 'Dutch'
        },
        {
          country: 'FR',
          name: 'French'
        }
      ],
      langData: {},
      langErr: false
    }
  },
  methods: {
    uploadFirmware() {
      const formValid = this.$refs.easyDriverFwForm.validate();
      const necessaryLangAdded = this.langData['EN'] && this.langData['DE']

      if (!necessaryLangAdded) {
        return this.langErr = true;
      } else {
        this.langErr = false;
      }

      if (formValid) {
        this.$emit('newFw', {
          version: this.version,
          service: this.serviceReady,
          user: this.userReady,
          firmware_update_type_id: this.firmware_update_type_id.id,
          firmware: this.firmware,
          easy_driver_type_id: this.easydriver_type_id.id,
          languageData: this.langData
        });
      }
    },
    clearForm() {
      this.version = '';
      this.userReady = false;
      this.serviceReady = false;
      this.firmware_update_type_id = '';
      this.firmware = '';
      this.easydriver_type_id = '';
      this.langErr = false;
      this.langData = {};
    },
    closeForm() {
      this.dialog = false;
    },
    openForm() {
      this.dialog = true;
    },
    handleLanguage({country, description}) {
      this.langData[country] = description;
    },
    getTypeString(item) {
      return hardwareTypeString(item)
    }
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.easyDriverFwForm.resetValidation();
      }
    }
  }
}
</script>

<style scoped>
.supportedLanguagesForm {
  display: flex;
  flex-direction: column;
}

.errMsgContainer {
  color: red;
  font-size: 0.65rem;
  font-weight: 500;
}
</style>

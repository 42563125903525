var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogConfirmerPromise',{ref:"mDecisionDialog"}),_c('DialogConfirmerPromise',{ref:"deleteDialog"}),_c('v-row',{staticClass:"align-center pa-0 ma-0 mt-3"},[_c('v-col',{staticClass:"col-lg-6"},[_c('v-select',{attrs:{"loading":!_vm.easyDriverTypes,"placeholder":_vm.$t('Easydriver hardware type'),"label":_vm.$t('Easydriver hardware type'),"color":"rgba(0, 0, 0, 0.5)","items":_vm.easyDriverTypes ? _vm.easyDriverTypes : [],"value":_vm.easyDriverType ? _vm.easyDriverType : '',"return-object":"","item-text":"type","clearable":""},on:{"change":_vm.handleEasyDriverChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeString(item.type))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeString(item.type))+" ")]}}])})],1),_c('v-col',{staticClass:"col-lg-5 side_title"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t("Select easy driver type"))+" ")])],1)],1),(_vm.fwData)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fwData,"items-per-page":_vm.fwData.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(item.version)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(_vm.getHumanReadableDate(item.date))+" ")])]}},{key:"item.easyDriverType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(_vm.getTypeString(item.easyDriverType.type))+" ")])]}},{key:"item.updateType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(item.updateType.type)+" ")])]}},{key:"item.userReady",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('ReichCheckbox',{attrs:{"value":item.userReady,"text":""},on:{"valueChange":function () { return _vm.handleUserReadyChange(item); }}})],1)]}},{key:"item.serviceReady",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('ReichCheckbox',{attrs:{"value":item.serviceReady,"text":""},on:{"valueChange":function () { return _vm.handleServiceReadyChange(item); }}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.issueFwDownload(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.issueFwDelete(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,466000787)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
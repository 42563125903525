<template>
<v-container v-if="issueData !== ''">

  <dialog-custom ref="dialog">
    <v-card>
      <v-card-title>
        {{$t('Detailed description')}}
      </v-card-title>
      <v-card-text>
        {{dialogMessage}}
      </v-card-text>
    </v-card>
  </dialog-custom>

  <div>
    <div class="text-center">
      <div style="width: 30%">
        <p style="text-align: left">
          {{$t('Filter by issue status')}}
        </p>
        <v-select :items="filters" v-model="paginationInfo.filter" @change="fetchNewPage(true)">

        </v-select>
      </div>
    </div>
  </div>
  <v-container>
    <v-layout column style="height: 75vh">
      <div style="overflow: auto" @scroll="onScroll">
        <v-data-table
            :headers="headers"
            :items="issueData"
            hide-default-footer
            class="elevation-1"
            :items-per-page="issueData.length"
        >

          <template v-slot:item.message="{ item }">
            <span v-if="item.message.length < 100">
              {{item.message}}
            </span>
            <span v-else>
              {{item.message | truncate}}
              <v-icon small @click="displayDialog(item)">mdi-dots-horizontal</v-icon>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
                class="ma-4"
                @click="fireDeleteDialog(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.resolver="{ item }">
            <div style="width: 150px">
              <span v-if="!item.issueResolver || !item.resolved">
                {{$t('This problem has not been solved by anyone')}}
              </span>
              <span v-else>{{item.issueResolver.user_email}}</span>
            </div>
          </template>

          <template v-slot:item.resolved="{item}">
            <v-switch
                style="width: 150px"
                :input-value="!item.resolved"
                color="red"
                :label="getStatus(item)"
                :success="item.resolved"
                @click="updateIssue(item)"
            >
            </v-switch>
          </template>

        </v-data-table>
      </div>
    </v-layout>
  </v-container>
  <v-row class="justify-center" v-if="issueToDelete">
    <v-dialog
        :value="true"
        persistent
        max-width="320"
    >
      <dialog-confirmer
          @result="(res) => onDialogResult(res)"
          :positive="$t('Delete issue')"
          :negative="$t('Dismiss')"
          :title="$t('Delete issue')"
          :text="$t('Do you really want to delete the problem?')"
      />
    </v-dialog>
  </v-row>
</v-container>
</template>

<script>
import DialogConfirmer from "@/components/DialogConfirmer";
import DialogCustom from "@/components/DialogCustom";
export default {
  name: "UserIssues",
  components: {DialogCustom, DialogConfirmer},
  data: function () {
    return {
      paginationInfo: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 40,
        filter: "all",
      },
      issueData: [],
      headers: [
        {
          text: this.$t('Subject'), value: 'subject'
        },
        {
          text: this.$t('Message'), value: 'message'
        },
        {
          text: this.$t('Resolved'), value: 'resolved'
        },
        {
          text: this.$t('Resolver'), value: 'resolver'
        },
        {
          text: this.$t('Published by'), value: 'user.user_email'
        },
        {
          text: this.$t('Actions'), value: 'actions'
        }
      ],
      filters: ["all", "resolved", "unresolved"],
      issueToDelete: '',
      dialog: false,
      dialogMessage: ''
    }
  },
  methods: {
    async fetchNewPage (filterChange) {
      if (filterChange) {
        this.paginationInfo.page = 1
        this.issueData = []
      }
      const offset = (this.paginationInfo.page - 1) * this.paginationInfo.itemsPerPage
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-contact?offset=${offset}&limit=${this.paginationInfo.itemsPerPage}&filter=${this.paginationInfo.filter}`)

      this.issueData = this.issueData.concat(res.data)

      this.paginationInfo.pageCount = Math.ceil(parseInt(res.headers['x-total-count']) / this.paginationInfo.itemsPerPage)
      this.paginationInfo.page += 1
    },
    async deleteIssue(item) {
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-contact/${item.issueUUID}`)
      this.issueData = this.issueData.filter(issue => item.issueUUID !== issue.issueUUID)
      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('User problem cleared successfully'),
        color: 'success'
      })
    },
    async updateIssue(item) {
      const res = await this.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-contact/${item.issueUUID}`, {
        issue_resolved: !item.resolved
      })
      const data = res.data
      this.issueData = this.issueData.map((issue) => {
        if (data.issueUUID === issue.issueUUID) {
          return data
        }else {
          return issue
        }
      })
    },
    getStatus (item) {
      return item.resolved ? this.$t('Fixed') : this.$t('Pending')
    },
    fireDeleteDialog(item) {
      this.issueToDelete = item
    },
    onDialogResult(result) {
      if (result) {
        this.deleteIssue(this.issueToDelete)
      }
      this.issueToDelete = ''
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.fetchNewPage()
      }
    },
    displayDialog(item) {
      this.$refs.dialog.fireDialog();
      this.dialogMessage = item.message
    }
  },
  mounted() {
    this.fetchNewPage()
  },
  filters: {
    truncate(item) {
      return item.substring(0, 50)
    }
  }
}
</script>

<style scoped>

</style>

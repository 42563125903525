<template>
    <v-container>
        <DialogConfirmerPromise ref="dialog"/>
        <v-row>
            <v-col class="col-12 col-sm-10 col-md-8 col-lg-6">
                <v-card ref="form" class="mt-10 mb-10" v-if="manufacturersList !== '' && manufacturer !== ''">
                    <v-card-text>
                        <v-card-title>
                            {{ $t("Caravan") }}
                            <v-icon class="ml-2">
                                mdi-rv-truck
                            </v-icon>
                        </v-card-title>
                        <v-checkbox
                                :label="$t('Remote maintenance')"
                                v-model="updateVehicleData.remote_maintenance_enabled"
                                @change="handleRemoteAccessUpdate"
                                :disabled="!editMode"
                        />

                        <v-text-field
                                :label="$t('Profile UUID')"
                                v-model="updateVehicleData.caravan_profile_uuid"
                                outlined
                                disabled
                        />
                        <v-text-field
                                :label="$t('Profile Title')"
                                v-model="updateVehicleData.caravan_profile_title"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile Type')"
                                v-model="updateVehicleData.caravan_profile_type"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Axle Type')"
                                v-model="updateVehicleData.caravan_profile_axle_type"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('License Plate')"
                                v-model="updateVehicleData.caravan_profile_license_plate"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile Fin')"
                                v-model="updateVehicleData.caravan_profile_fin"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile Length')"
                                v-model="updateVehicleData.caravan_profile_length"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile Width')"
                                v-model="updateVehicleData.caravan_profile_width"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile Height')"
                                v-model="updateVehicleData.caravan_profile_height"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Profile tare weight')"
                                v-model="updateVehicleData.caravan_profile_tare_weight"
                                outlined
                                @keydown="changeDetected = true"
                                :disabled="!editMode"
                        />
                        <v-text-field
                                :label="$t('Create profile time')"
                                :value="updateVehicleData.caravan_profile_time_create | toDate "
                                outlined
                                @keydown="changeDetected = true"
                                disabled
                        />
                        <v-text-field
                                outlined
                                :label="$t('Manufacturer date')"
                                disabled
                                @keydown="changeDetected = true"
                                :value="updateVehicleData.caravan_profile_manufacture_date"/>
                    </v-card-text>
                    <v-autocomplete
                            class="pl-4 pr-4"
                            outlined
                            :disabled="!editMode"
                            v-model="manufacturer"
                            item-text="name"
                            :rules="[() => !!manufacturer|| 'This field is required']"
                            :items="manufacturersList"
                            :label="$t('Manufacturer')"
                            @change="changeDetected = true"
                            return-object
                    ></v-autocomplete>

                    <v-card-actions v-if="editMode" class="justify-center">
                        <v-btn :disabled="!changeDetected" @click="handleUpdate">
                            {{ $t('SAVE') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="form_user" v-if="vehicleData.user">
                    <v-card-text>
                        <v-card-title>
                            {{ $t('User') }}
                            <v-icon class="ml-2">
                                mdi-account
                            </v-icon>
                        </v-card-title>
                        <v-text-field
                                :label="$t('User GUID')"
                                v-model="vehicleData.user_guid"
                                outlined
                                disabled
                        />
                        <v-text-field
                                :label="$t('User email')"
                                v-model="vehicleData.user.user_email"
                                outlined
                                disabled
                        />
                        <v-text-field
                                :label="$t('User email')"
                                v-model="vehicleData.user.login"
                                outlined
                                disabled
                        />

                        <v-text-field
                                :label="$t('User language')"
                                v-model="vehicleData.user.lang"
                                outlined
                                disabled
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12 col-sm-10 col-md-8 col-lg-6">
                <v-card class="mb-10 mt-10" v-if="false">
                    <v-card-text>
                        <v-card-title>
                            Logs
                            <v-icon>
                                file-document-alert
                            </v-icon>
                        </v-card-title>
                        <v-btn
                                @click="logDialog=true"
                        >
                            Easydriver
                        </v-btn>
                    </v-card-text>
                </v-card>

                <v-card class="mb-10 mt-10">
                    <v-card-text>

                        <v-card-title>
                            {{ $t('Caravan products') }}
                            <v-icon>
                                mdi-cog
                            </v-icon>
                        </v-card-title>
                        <div v-if="vehicleData.products.length > 0">
                            <product-info v-for="(product, index) in vehicleData.products" :key="index"
                                          :product="product" @onDelete="() => deleteProduct(product)"/>
                        </div>
                        <v-card-subtitle v-if="vehicleData.products.length === 0">
                            {{ $t('No products available') }}
                        </v-card-subtitle>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        absolute
                                        class="mt-10"
                                        color="grey"
                                        dark
                                        right
                                        top
                                        fab
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="dialog=true"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>
                {{ $t('Add new sensor for vehicle') }}
              </span>
                        </v-tooltip>
                    </v-card-actions>

                </v-card>

            </v-col>

            <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="500px"
            >
                <product-registration-form v-if="vehicleData"
                                           :vehicle-data="vehicleData"
                                           @onProduct="pairSensor"
                                           @onClose="dialog=false"
                />
            </v-dialog>
            <v-dialog
                    v-model="logDialog"
                    max-width="600px"
                    style="margin: 10px"
            >
                <logs-modal v-if="vehicleData" :caravanId="vehicleData.caravan_profile_uuid"
                            @onClose="logDialog=false"
                />
            </v-dialog>


        </v-row>

    </v-container>
</template>

<script>
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
import {getDateString} from "@/utils/date";
import ProductRegistrationForm from "@/components/ProductRegistrationForm";
import LogsModal from "@/components/LogsModal";
import ProductInfo from "@/components/ProductInfo";
import {getEasydriverCaravanProfile} from "@/services/caravanService";

export default {
    name: "VehicleInfo",
    components: {ProductInfo, ProductRegistrationForm, DialogConfirmerPromise, LogsModal},
    props: ['vehicleOBJ'],
    data: function () {
        return {
            dialog: false,
            logDialog: false,
            vehicleData: this.vehicleOBJ,
            updateVehicleData: this.vehicleOBJ,
            editMode: true,
            changeDetected: false,
            manufacturersList: '',
            manufacturer: '',
            isLoading: false
        }
    },
    mounted() {
        const setManufacturersCall = () => {
            if (this.$store.getters["caravan/getManufacturersList"] != null) {
                this.manufacturersList = this.$store.getters["caravan/getManufacturersList"]
                this.setManufacturer()
            } else {
                this.$store.dispatch('caravan/getManufacturers')
                    .then((res) => {
                        this.manufacturersList = res.data
                        this.setManufacturer()
                    })
            }
        }

        if (!this.vehicleData || !this.updateVehicleData) {
            getEasydriverCaravanProfile(this.$route.params.vehicleId)
                .then((res) => {
                    this.vehicleData = {...res}
                    this.updateVehicleData = {...res}
                    setManufacturersCall()
                })
                .finally(() => {
                    this.isLoading = false
                })
        } else {
            setManufacturersCall()
            this.isLoading = false
        }
    },
    methods: {
        async pairSensor(productBody) {
            this.$store.dispatch('caravan/registerProduct', productBody)
                .then((product) => {
                    this.$store.dispatch('snackbar/fireAlert', {
                        "message": this.$t("New product successfully added to the vehicle"),
                        "color": "success"
                    });
                    this.vehicleData.products.push(product);
                    this.dialog = false;
                })
        },
        async deleteProduct(item) {
            const res = await this.$refs.dialog.fireAlert(
                this.$t('Delete product'),
                this.$t('Deny'),
                this.$t('Delete product'),
                this.$t('Do you really want to delete this product?')
            );
            if (!res) {
                return
            }

            const payload = {
                "product_name": item.product_name,
                "product_code": item.product_uid,
                "caravan_profile_uuid": this.vehicleData.caravan_profile_uuid
            }
            this.$store.dispatch('caravan/deleteProduct', payload)
                .then(() => {
                    this.$store.dispatch('snackbar/fireAlert', {
                        "message": this.$t("Product successfully deleted from vehicle"),
                        "color": "success"
                    })
                    this.vehicleData.products = this.vehicleData.products.filter((product) => product.product_uid !== item.product_uid)
                })
        },
        setManufacturer() {
            this.manufacturersList.forEach((manufacturer) => {
                if (manufacturer.id === this.vehicleData.manufacturer_id) {
                    this.manufacturer = manufacturer
                }
            })
        },
        handleRemoteAccessUpdate() {
            const OBJ = {
                "UUID": this.updateVehicleData.caravan_profile_uuid,
                "enabled": this.updateVehicleData.remote_maintenance_enabled ? "1" : "0"
            }

            this.$store.dispatch("caravan/updateRemoteMaintenance", OBJ)
        },
        handleUpdate() {
            const caravanOBJ = {
                "caravan_profile_title": this.updateVehicleData.caravan_profile_title,
                "caravan_profile_type": this.updateVehicleData.caravan_profile_type,
                "caravan_profile_axle_type": this.updateVehicleData.caravan_profile_axle_type,
                "caravan_profile_license_plate": this.updateVehicleData.caravan_profile_license_plate,
                "caravan_profile_fin": this.updateVehicleData.caravan_profile_fin,
                "manufacturer_id": this.manufacturer.id,
                "caravan_profile_manufacture_date": this.updateVehicleData.caravan_profile_manufacture_date || null,
                "caravan_profile_dot_no": this.updateVehicleData.caravan_profile_dot_no,
                "caravan_profile_uuid": this.updateVehicleData.caravan_profile_uuid,
                "remote_maintenance_enabled": this.updateVehicleData.remote_maintenance_enabled,
                "dirty": true
            }
            this.$store.dispatch('caravan/updateVehicle', caravanOBJ)
                .then(() => {
                    this.$store.dispatch('snackbar/fireAlert', {
                        "message": this.$t('Vehicle updated successfully'),
                        "color": "success"
                    })
                    this.editMode = true
                    this.changeDetected = false
                    this.vehicleData = {products: this.vehicleData.products, ...this.updateVehicleData}
                })
        },
        closeDialog() {
            this.dialog = false;
            this.$refs.form.resetValidation();
        },
        closeLogDialog() {
            this.logDialog = false;
        }
    },
    filters: {
        toDate: function (seconds) {
            return getDateString(seconds)
        }
    }
}
</script>

<style scoped>
.form_caravan {
    height: 80vh;
    overflow-y: auto;
}

.form_products {
    height: 40vh;
    overflow-y: auto;
}

.form_user {
    margin-bottom: 10vh;
    overflow-y: auto;
}
</style>

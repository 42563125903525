<template>
    <v-card class="pa-3 ma-3">
        <v-card-title>
            {{ $t('Product') }}
            <v-btn
                    color="grey"
                    fab
                    x-small
                    dark
                    class="ml-5"
                    @click="deleteProduct(product)"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-title>
        <v-text-field
                class="ma-3"
                :label="$t('Product ID')"
                v-model="product.product_uid"
                outlined
                disabled
        />

        <v-text-field
                class="ma-3"
                :label="$t('Product name')"
                v-model="product.product_name"
                outlined
                disabled
        />

        <div v-if="product.extra_info" class="ma-3">

            <v-card-title style="color: dimgray">
                {{ $t("Extra info") }}
            </v-card-title>

            <template v-if="product.product_name === products.SAFETYRE">
                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Position')"
                        v-model="product.extra_info.position"
                />

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Display name')"
                        v-model="product.extra_info.anzeigename"
                />
            </template>

            <template v-if="product.product_name === products.EASYDRIVER">
                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Type')"
                        v-model="product.extra_info.type"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Name')"
                        v-model="product.extra_info.name"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Address')"
                        v-model="product.extra_info.address"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Easydriver hardware type')"
                        v-model="product.extra_info.hardwareType"/>
            </template>

            <template v-if="product.product_name === products.WATER_FILTER">
                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('ID')"
                        v-model="product.extra_info.id"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Type')"
                        v-model="product.extra_info.type"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('ID')"
                        v-model="product.extra_info.batch"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('UpdateTimeUTC')"
                        :value="(new Date(product.extra_info.updateTimeUTC)).toDateString()"/>
            </template>

            <template v-if="product.product_name === products.MPC">
                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('ID')"
                        v-model="product.extra_info.id"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('Name')"
                        v-model="product.extra_info.name"/>

                <v-text-field
                        disabled
                        class="ma-3"
                        outlined
                        :label="$t('UpdateTimeUTC')"
                        :value="(new Date(product.extra_info.updateTimeUTC)).toDateString()"/>
            </template>

          <template v-if="product.product_name === products.MB">
            <v-text-field
                disabled
                class="ma-3"
                outlined
                :label="$t('ID')"
                v-model="product.extra_info.id"/>

            <v-text-field
                disabled
                class="ma-3"
                outlined
                :label="$t('Name')"
                v-model="product.extra_info.name"/>

            <v-text-field
                disabled
                class="ma-3"
                outlined
                :label="$t('UpdateTimeUTC')"
                :value="(new Date(product.extra_info.updateTimeUTC)).toDateString()"/>
          </template>

            <template v-if="product.product_name === products.MOPEKA">

              <v-text-field
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Mac address')"
                  v-model="product.extra_info.mac_address"/>

              <v-text-field
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Sensor type')"
                  v-model="product.extra_info.sensor_type"/>

              <v-text-field
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Bottle height')"
                  v-model="product.extra_info.set_bottle_height"/>

              <v-text-field
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Calibration orientation pitch')"
                  v-model="product.extra_info.calibration_orientation_pitch"/>

              <v-text-field
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Calibration orientation roll')"
                  v-model="product.extra_info.calibration_orientation_roll"/>

              <v-switch
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Gas Level Notification enabled')"
                  v-model="product.extra_info.gas_level_notifications_enabled"/>
              <v-switch
                  disabled
                  class="ma-3"
                  outlined
                  :label="$t('Battery notification enabled')"
                  v-model="product.extra_info.battery_notifications_enabled"
              />
            </template>
        </div>

        <v-card-subtitle v-else>
            {{ $t('Extra info not available', [product.product_name]) }}
        </v-card-subtitle>

    </v-card>
</template>

<script>
import {reichProducts} from "@/utils/shared";

export default {
    name: "ProductInfo",
    props: ['product'],
    data: () => {
        return {
            products: reichProducts
        }
    },
    methods: {
        deleteProduct() {
            this.$emit('onDelete');
        }
    }
}
</script>

<style scoped>

</style>

export const paginationMixin = {
    data: function () {
        return {
            offset: 0,
            limit: 20
        }
    },
    methods: {
        getNextPage() {
            this.offset += this.limit;
        },
        resetToDefaults() {
            this.offset = 0;
        }
    }
}

<template>
  <v-dialog
      v-model="modal"
      max-width="400"
      persistent
  >
    <v-card tile>
      <v-toolbar
          flat
          dark
          color="#282424"
      >
        <v-toolbar-title>
          {{$t("Enter language description")}}
        </v-toolbar-title>
      </v-toolbar>
      <div class="pa-3">
        <v-form ref="myForm">
          <v-textarea
              v-model="currentDescription"
              clearable
              :placeholder="$t('Description')"
              :hint="$t('Enter description for firmware')"
          />
        </v-form>
        <v-btn class="mt-4"
               dark
               color="rgba(0, 0, 0, 0.5)"
               @click="handleDescriptionAdd"
        >
          {{$t('Add language description')}}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TextEnterModal",
  props: ['description'],
  data: () => {
    return {
      modal: false,
      currentDescription: ''
    }
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
    handleDescriptionAdd() {
      this.$emit('onDescription', this.currentDescription);
    }
  },
  watch: {
    description(newVal) {
      this.currentDescription = newVal;
    }
  }
}
</script>

<style scoped>

</style>
<template>
    <v-snackbar v-model="show" :top="top" :color="color" :timeout="timeout" style="display: flex">
        <v-alert :type="color">
            {{ message }}
        </v-alert>
    </v-snackbar>
</template>

<script>
export default {
    name: "Snackbar",
    data: function () {
        return {
            show: false,
            top: true,
            message: "",
            color: "success",
            timeout: 3500
        }
    },
    created() {
        this.$store.watch(
            state => state.snackbar.snack,
            () => {
                const message = this.$store.state.snackbar.snack.message
                if (message) {
                    this.show = true
                    this.message = message

                    this.color = this.$store.state.snackbar.snack.color
                }
            }
        )
    }
}
</script>

<style scoped>
</style>

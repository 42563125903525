<template>
    <v-card style="min-height: 475px">
        <v-card-title>
            {{ title }}
        </v-card-title>
        <v-card-text>
            <div class="legend">
                <ul>
                    <li v-for="(item, index) in data" :key="index">
                        <span class="legend-color" :style="{ backgroundColor: colors[index] }"></span>
                        {{ item.name }}: {{ item.count }}
                    </li>
                </ul>
            </div>

            <canvas ref="chart"></canvas>
        </v-card-text>
    </v-card>
</template>

<script>
import Chart from 'chart.js';

export default {
    name: 'KpiPie',
    props: {
        data: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data: function () {
      return {
          colors: [
              '#AFB42B',
              '#FF6F00',
              '#43A047',
              '#00897B',
              '#689F38',
              '#FF8C00',
          ]
      }
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const labels = this.data.map(item => item.name);
            const data = this.data.map(item => item.count);

            new Chart(this.$refs.chart, {
                type: 'pie',
                data: {
                    labels,
                    datasets: [{
                        data,
                        backgroundColor: this.colors
                    }]
                },
                options: {
                    responsive: true,
                    legend: {
                        display: false
                    }
                },
            });
        }
    }
};
</script>
<style scoped>
.legend {
    margin-top: 20px;
}

.legend ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.legend li {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 14px;
}

.legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    display: inline-block;
}
</style>

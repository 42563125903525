<template>
  <v-row justify="center" class="modal">
    <v-dialog v-model="dialog" persistent max-width="340px">
      <v-card>
        <v-card-title>
          {{title}}
        </v-card-title>
        <v-card-text>
          {{text}}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="ma-2" @click="emitResult(true)" color="green darken-1" text>
            {{positive}}
          </v-btn>
          <v-btn class="ma-2" @click="emitResult(false)" color="red darken-1" text>
            {{negative}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogConfirmerPromise",
  methods: {
    emitResult(res) {
      this.inputVal = false;
      this.dialog = false;
      this.resolver(res);
    },
    fireAlert(positive, negative, title, text) {
      return new Promise((resolve) => {
        this.dialog = true
        this.resolver = resolve
        this.positive = positive
        this.negative = negative
        this.title = title
        this.text = text
      })
    }
  },
  data: function () {
    return {
      resolver: '',
      positive: '',
      negative: '',
      title: '',
      text: '',
      dialog: false
    }
  }
}
</script>

<style scoped>
.modal {
  z-index: 1000;
}
</style>

<template>
    <v-card>
      <v-card-title>
        {{title}}
      </v-card-title>
      <v-card-text>
        {{text}}
      </v-card-text>
      <v-card-actions>
        <v-btn class="ma-2" @click="emitResult(true)">
          {{positive}}
        </v-btn>
        <v-btn class="ma-2" @click="emitResult(false)">
          {{negative}}
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: "DialogConfirmer",
  props: ['positive', 'negative', 'title', 'text'],
  methods: {
    emitResult(res) {
      this.$emit('result', res)
    }
  }
}
</script>

<style scoped>

</style>

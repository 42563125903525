<template>
  <span @click="(e) => e.stopPropagation()" class="table_entry">
    <slot/>
  </span>
</template>

<script>
export default {
  name: "TableEntry",
  props: ['value']
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
      v-model="inputVal"
      max-width="500px"
  >
    <v-card rounded>
      <v-card-title style="background-color: gray; color: white; text-align: center">
        <span class="headline text-center font-weight-thin">
          {{$t('Register manufacturer')}}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
              ref="form"
          >
            <v-row class="justify-center">
              <v-col
                  cols="7"
                  md="7"
              >
                <v-text-field
                    :rules="rules.nameRule"
                    v-model="manufacturer.manufacturer_name"
                    rquired
                    :label="$t('Name')"
                    validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col
                  cols="7"
                  md="7"
              >
                <v-file-input
                    :rules="rules.fileRule"
                    v-model="manufacturer.manufacturer_image"
                    :prepend-icon="null"
                    accept="image/*"
                    required
                    :label="$t('Picture')"
                    validate-on-blur
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
            class="ma-2"
            @click="inputVal=false"
        >
          {{$t('Cancel')}}
        </v-btn>
        <v-btn
            class="ma-2"
            @click="() => emitManufacturer()"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {resizeImage} from "@/utils/fileHandler";

export default {
  name: "ManufacturerModal",
  props: ['value'],
  data: function () {
    return {
      manufacturer: {
        "manufacturer_name": '',
        "manufacturer_image": []
      },
      rules: {
        nameRule: [
            v => !!v || this.$t('Please provide name'),
        ],
        fileRule: [
            f => !!f || this.$t('Please upload file')
        ]
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    async emitManufacturer() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return
      }
      const base64 = await this.readImage(this.manufacturer.manufacturer_image)

      this.inputVal = false;
      this.$emit('manufacturer', {
        'manufacturer_name': this.manufacturer.manufacturer_name,
        'manufacturer_image': await resizeImage(base64)
      })
      this.$refs.form.reset();
    },
    readImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(file)
      })
    }
  }
}
</script>

<style scoped>

</style>

export const footer = {
    namespaced:true,
    state: () => ({
        displayFooter: true
    }),
    mutations: {
        toggleFooter(state, footer) {
            state.displayFooter = footer;
        }
    },
    actions: {
        hideFooter(context) {
            context.commit('toggleFooter', false);
        },
        showFooter(context) {
            context.commit('toggleFooter', true);
        }
    },
    getters: {
        footer: state => {
            return state.displayFooter;
        }
    }
}

<template>
  <div>
    <DialogCustom ref="dialog">
      <v-card rounded>
        <v-card-title style="background-color: gray; color: white; text-align: center">
          <span class="headline text-center font-weight-thin">
            {{$t('Vehicles cannot be deleted, the following vehicles are still assigned to the manufacturer')}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container style="max-height: 150px;overflow-y: scroll;text-align: center;width: 100%">
            <v-list-item  v-for="(vehicle, index) in associatedVehicles" :key=" `${vehicle} - ${index}` ">
              <b>{{vehicle}}</b>
            </v-list-item>
          </v-container>
        </v-card-text>
      </v-card>
    </DialogCustom>

    <DialogConfirmerPromise ref="dialogConfirmer"/>

    <ManufacturerModal v-model="modal" @manufacturer="registerNewManufacturer" />
    <v-container v-if="this.manufacturersList" class="justify-center">
      <v-row class="justify-center">
        <v-col class="col-md-2 col-12"  v-for="manufacturer in this.manufacturersList" :key="manufacturer.id">
          <v-card min-height="200px" max-height="200px">
            <v-card-title>
              <span class="title font-weight-light" style="{font-size: 30px}">{{manufacturer.name}} </span>
            </v-card-title>
            <v-card-text>
            </v-card-text>
            <div class="trash_container">
              <v-icon
                  @click="deleteManufacturer(manufacturer)"
              >
                mdi-delete
              </v-icon>
            </div>

            <div class="image_container">
              <v-img :src="manufacturer.image" max-height="130px" max-width="130px">

              </v-img>
            </div>
          </v-card>
        </v-col>
        <v-fab-transition>
          <div class="btn_container">
            <v-btn
                class="mt-5"
                color="grey"
                dark
                fab
                @click="openDialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-fab-transition>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ManufacturerModal from "@/components/ManufacturerModal";
import DialogCustom from "@/components/DialogCustom";
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
export default {
  name: "Manufacturers",
  components: {DialogConfirmerPromise, DialogCustom, ManufacturerModal},
  data: function () {
    return {
      "manufacturersList": null,
      "modal": false,
      'associatedVehicles': []
    }
  },
  methods: {
    openDialog() {
      this.modal = true
    },
    async registerNewManufacturer(manufacturer) {
      const res = await this.$http.post(`${process.env.VUE_APP_API_URL}/easydriver-manufacturer`, manufacturer);
      this.manufacturersList.push(res.data);
      await this.$store.dispatch('snackbar/fireAlert', {
        "message": this.$t('You have successfully added a new manufacturer'),
        "color": "success"
      })
    },
    async deleteManufacturer(item) {
      const associations = (await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-manufacturer/${item.id}/associations`)).data;
      if (associations.count > 0) {
        this.associatedVehicles = associations.caravans
        this.$refs.dialog.fireDialog();
      } else {
        const res = await this.$refs.dialogConfirmer.fireAlert(this.$t('Delete manufacturer'),
            this.$t('Deny'),
            this.$t('Delete manufacturer'),
            this.$t('Do you really want to delete this manufacturer?')
        )
        if (res) {
          await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-manufacturer/${item.id}`);
          this.manufacturersList = this.manufacturersList.filter((it) => it.id !== item.id);
        }
      }
    }
  },
  mounted() {
    // Check if cached !
    if (this.$store.getters["caravan/getManufacturersList"] != null) {
      this.manufacturersList = this.$store.getters["caravan/getManufacturersList"]
    }
    else {
      this.$store.dispatch('caravan/getManufacturers')
          .then((res) => {
            this.manufacturersList = res.data
          })
    }
  },
  filters: {
    truncate (val) {
      const length = val.length > 10 ? 10 : val.length
      return `${val.substring(0, length)}...`
    }
  }

}
</script>

<style scoped>
.btn_container{
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 5%;
}
.image_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.trash_container {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

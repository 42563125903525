var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"Vehicles"},[_c('div',{staticClass:"mt-15"},[_c('v-row',[_c('v-col',{staticClass:"col-md-3 col-8"},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('Title'),"value":_vm.namePattern},on:{"input":_vm.nameFilterDebounce}})],1),_c('v-col',{staticClass:"col-md-3 col-8"},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('SensorUID'),"value":_vm.sensorPattern},on:{"input":_vm.sensorFilterDebounce}})],1),_c('v-col',{staticClass:"col-md-3 float-right col-2"},[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 ml-15",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("To search for vehicles by title or sensorUID, simply enter them in the input field and the query will be triggered automatically fire"))+" ")])])],1)],1),_c('v-container',[_c('v-layout',{staticStyle:{"height":"75vh"},attrs:{"column":""}},[_c('div',{ref:"myScroll",staticStyle:{"overflow":"auto"},on:{"scroll":_vm.onScroll}},[_c('v-data-table',{staticClass:"elevation-1 cursorTable",attrs:{"headers":_vm.headers,"items":_vm.vehicles,"items-per-page":_vm.vehicles.length,"hide-default-footer":"","selectable-key":"id"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.fireDialogDelete(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.showInfo(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.caravan_profile_uuid",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_uuid"])+" ")])]}},{key:"item.caravan_profile_title",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_title"])+" ")])]}},{key:"item.caravan_profile_type",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["caravan_profile_type"])+" ")])]}},{key:"item.user.user_guid",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["user"]["user_guid"])+" ")])]}},{key:"item.user_email",fn:function(ref){
var item = ref.item;
return [_c('table-entry',[_vm._v(" "+_vm._s(item["user"]["user_email"])+" ")])]}}])})],1)])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"310"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('Do you really want to delete the vehicle?'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('Do you really want to delete the vehicle with the ID?', [this.vehicleToDeleteID]))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false; _vm.vehicleToDeleteID=''}}},[_vm._v(" "+_vm._s(_vm.$t('Disagree'))+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteVehicle}},[_vm._v(" "+_vm._s(_vm.$t('Agree'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
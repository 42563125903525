<template>
    <v-container>
        <h1 class="text-center dashboard_header mt-1">
            {{$t('Welcome to the easydriver community')}}
        </h1>

        <v-row class="mt-10">
            <v-col class="col-md-4 col-12">
                <KpiCard
                    icon="mdi-account"
                    :text="basicStatsOBJ ? basicStatsOBJ.registeredUsers.toString()  : ''"
                    :title="$t('Registered users')"
                    :loading="!basicStatsOBJ"
                />
            </v-col>
            <v-col class="col-md-4 col-12">
                <KpiCard
                    icon="mdi-rv-truck"
                    :text="basicStatsOBJ ? basicStatsOBJ.registeredVehicles.toString() : ''"
                    :title="$t('Registered vehicles')"
                    :loading="!basicStatsOBJ"
                />
            </v-col>
            <v-col class="col-md-4 col-12">
                <KpiCard
                    icon="mdi-broadcast"
                    :text="basicStatsOBJ ? basicStatsOBJ.registeredSensors.toString()  : ''"
                    :title="$t('Registered sensors')"
                    :loading="!basicStatsOBJ"
                />
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col class="col-md-6 col-12">
                <KpiPie
                    v-if="sensorChartData"
                    :title="$t('Sensors by type')"
                    :data="sensorChartData"
                />
            </v-col>

            <v-col class="col-md-6 col-12">
                <KpiPie
                    v-if="vehicleChartData"
                    :title="$t('Vehicles by type')"
                    :data="vehicleChartData"
                />
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import KpiCard from "@/components/KpiCard.vue";
import KpiPie from "@/components/KpiPie.vue";
import {capitalize, productString} from "@/utils/shared";

export default {
    name: "HomeScreen",
    components: {KpiPie, KpiCard},
    data: function () {
        return {
            basicStatsOBJ: undefined,
            chartData: [
                { count: 50, name: 'Easydriver' },
                { count: 25, name: 'Water Filter' },
                { count: 15, name: 'Safe Tyre' },
                { count: 10, name: 'Sensor fo4' }
            ],
            vehicleChartData: undefined,
            sensorChartData: undefined
        }
    },
    methods: {
        async fetchBasicStats() {
            const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/stats-basic`)
            this.basicStatsOBJ = res.data
        },
        async fetchBasicVehicleStats() {
            const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/vehicles/stats-basic`)
            this.vehicleChartData = res.data.map((it) => {
                return {
                    ...it,
                    name: capitalize(it.name)
                }
            })
        },
        async fetchBasicSensorStats() {
            const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/sensors/stats-basic`)
            this.sensorChartData = res.data.map((it) => {
                return {
                    ...it,
                    name: productString(it.name)
                }
            })
        }
    },
    async created() {
        await this.fetchBasicStats()
        await this.fetchBasicVehicleStats()
        await this.fetchBasicSensorStats()
    }
}
</script>

<style scoped>
.dashboard_header {
    font-weight: 400;
    color: grey;
    font-size: 3em;
}
</style>

export const user = {
    namespaced: true,
    state: () => ({
        counter: 0,
        // This map is used for pagination and as keys it holds
        // Pages and for each page an array of user objects
        fetchedUsers: new Map(),
        userPaginationInfo: {
            "page": 0,
            "pageCount": 0,
            "itemPerPage": 40,
            "totalCount": 0,
            "initialized": false
        },
        userRoles: null,
        // This map is used for caravan userID associating
        // hence it has ID-s of users as keys an user objects as value
        fetchedUsersByID: new Map(),
        userEasydriverExperienceMap: {},
        searchQuery: {
            sortBy: {
                'user_guid': null,
                'user_login': null,
                'user_email': null,
                'user_active': null,
                'user_time_create': null,
                'user_time_update': null
            },
            namePattern: null,
            emailPattern: null,
            limit: null,
            offset: null
        },
        cachedUsers: [],
        scrollY: 0
    }),
    mutations: {
        increment(state) {
            state.counter += 1
        },
        setUsers(state, payload) {
            // const numberOfPages = totalNumberOfUsers / limit
            const currentPage = Math.ceil((payload.offset - 1) / payload.limit) + 1

            state.userPaginationInfo.page = currentPage

            state.userPaginationInfo.pageCount = Math.ceil(payload.totalCount / payload.limit)
            state.userPaginationInfo.totalCount = payload.totalCount
            state.userPaginationInfo.initialized = true
            state.userPaginationInfo.itemPerPage = payload.limit

            state.fetchedUsers[currentPage] = payload.users
        },
        setRoles(state, roles) {
            state.userRoles = roles
        },
        setUser(state, user) {
            state.fetchedUsersByID[user.user_guid] = user
        },
        setUserEasyDriverMapItem(state, {userId, mapEntry}) {
            const map = {...state.userEasydriverExperienceMap}
            if (map[userId]) {
                map[userId] = {
                    ...map[userId],
                    ...mapEntry
                }
            } else {
                map[userId] = {
                    ...mapEntry
                }
            }
            state.userEasydriverExperienceMap = map;
        },
        setSearchQuery(state, obj) {
            state.searchQuery = {...obj}
        },
        cleanCache(state) {
            state.fetchedUsers = new Map()
            state.cachedUsers = []
            state.searchQuery = {
                sortBy: {
                    'user_guid': null,
                    'user_login': null,
                    'user_email': null,
                    'user_active': null,
                    'user_time_create': null,
                    'user_time_update': null
                },
                namePattern: null,
                emailPattern: null,
                limit: null,
                offset: null
            }
            state.scrollY = 0
        },
        setCachedUsers(state, users) {
            state.cachedUsers = users
        },
        setScrollPosition(state, scroll) {
            state.scrollY = scroll
        }
    },
    actions: {
        getUsers(context, payload) {
            return new Promise((resolve, reject) => {
                const emailPattern = payload.emailPattern ? payload.emailPattern : ''
                const namePattern = payload.namePattern ? payload.namePattern : ''
                const sortBy = payload.sortBy ? payload.sortBy : {};
                let URL = `${process.env.VUE_APP_API_URL}/coreuser-user?offset=${payload.offset}&limit=${payload.limit}&email=${emailPattern}&name=${namePattern}`

                for (const [key, value] of Object.entries(sortBy)) {
                    URL += `&${key}=${value}`
                }
                this._vm.$http.get(URL, {withCredentials: true})
                    .then((res) => {
                        context.commit('setUsers', {
                            offset: payload.offset,
                            limit: payload.limit,
                            users: res.data,
                            totalCount: parseInt(res.headers['x-total-count'])
                        })

                        context.commit('setSearchQuery', {
                            offset: payload.offset,
                            limit: payload.limit,
                            emailPattern: payload.emailPattern,
                            namePattern: payload.namePattern,
                            sortBy: payload.sortBy
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getUserRoles(context) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/role`, {withCredentials: true})
                    .then((res) => {
                        context.commit('setRoles', res.data)
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateUser(context, payload) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(`${process.env.VUE_APP_API_URL}/coreuser-user/${payload.guid}`, payload.userDTO, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateUserRole(context, payload) {
          return new Promise((resolve, reject) => {
              this._vm.$http.put(`${process.env.VUE_APP_API_URL}/coreuser-user/${payload.guid}/role`, payload.updateUserRoleDTO, {withCredentials:true})
                  .then((res) => {
                      resolve(res)
                  })
                  .catch((err) => {
                      reject(err)
                  })
          })
        },
        deleteUser(context, uuid) {
            return new Promise((resolve, reject) => {
                this._vm.$http.delete(`${process.env.VUE_APP_API_URL}/coreuser-user/${uuid}`, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        toggleUser(context, uuid) {
            return new Promise((resolve, reject) => {
                this._vm.$http.put(`${process.env.VUE_APP_API_URL}/coreuser-user/${uuid}/status`, {}, {withCredentials: true})
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getUserByUUID(context, uuid) {
            return new Promise((resolve, reject) => {
                this._vm.$http.get(`${process.env.VUE_APP_API_URL}/coreuser-user/${uuid}`, {withCredentials: true})
                    .then((res) => {
                        const userOBJ = res.data
                        context.commit('setUser', userOBJ)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        async getUserExperience(context, userId) {
            const map = context.getters.getUserExperienceMap;
            if (map[userId] && (map[userId].isFetching || map[userId].content)) return;
            context.commit('setUserEasyDriverMapItem', {userId, mapEntry: {isFetching: true}});

            const res = await this._vm.$httpQuite.get(`${process.env.VUE_APP_API_URL}/coreuser-user/${userId}/is-experienced-easydriver-user`);
            context.commit('setUserEasyDriverMapItem', {userId, mapEntry: {isFetching: false, content: res.data}});
        },
        async updateUserExperience(context, {userId, experience}) {
            const map = context.getters.getUserExperienceMap;
            if (map[userId] && map[userId].isFetching) return;
            context.commit('setUserEasyDriverMapItem', {userId, mapEntry: {isFetching: true}});

            await this._vm.$http.put(`${process.env.VUE_APP_API_URL}/coreuser-user/${userId}/is-experienced-easydriver-user`, {
                experienced: experience
            });
            context.commit('setUserEasyDriverMapItem', {userId, mapEntry: {isFetching: false, content: experience}});
        },
        async cleanCache(context) {
            context.commit('cleanCache')
        },
        async setCachedUsers(context, users){
            context.commit('setCachedUsers', users)
        },
        setScrollPosition(context, scroll) {
            context.commit('setScrollPosition', scroll)
        }
    },
    getters: {
        getUserPaginationInfo: state => {
            return state.userPaginationInfo
        },
        getUserRoles: state => {
            return state.userRoles
        },
        getUserByID: (state) => (ID) => {
            if (state.fetchedUsersByID.has(ID)) {
                return state.fetchedUsersByID[ID]
            } else {
                return null
            }
        },
        getUserExperienceMap: state => {
            return state.userEasydriverExperienceMap
        },
        getSearchQuery: state => {
            return state.searchQuery
        },
        getCachedUsers: state => {
            return state.cachedUsers
        },
        getScrollPositionY: state => {
            return state.scrollY
        }
    }
}

export const endIntersectMixin = {
    data: function () {
        return {
            onScrollCallback: null
        }
    },
    methods: {
        onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.onScrollCallback()
            }
        }
    }
}

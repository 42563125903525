<template>
    <v-container fluid>
        <DialogConfirmerPromise ref="dialogConfirmer"/>
        <div v-if="initialized" class="mt-1">
            <div class="text-center pt-2">
                <div class="filter_container">
                    <div class="filter_item">
                        <p style="text-align: left; font-weight: lighter">{{ $t("Filter by POI status") }}</p>
                        <v-select
                                :items="filters"
                                v-model="paginationInfo.filter"
                                @change="getNewPage(true)"
                                :hint="$t('Filter by POI status')"
                        >

                        </v-select>
                    </div>
                    <div class="filter_item">
                        <p style="text-align: left; font-weight: lighter">{{ $t("Filter by POI name") }}</p>
                        <v-text-field
                                :label="$t('Poi-Name')"
                                :hint="$t('Nach POI-Namen filtern')"
                                @input="debouncePoiName"
                                v-model="poiNameFilter" v-on:keyup.enter="getNewPage(true)">

                        </v-text-field>
                    </div>

                    <div class="filter_item">
                        <p style="text-align: left; font-weight: lighter">
                            {{ $t("Filter by email address of POI author") }}
                        </p>
                        <v-text-field
                                :label="$t('E-mail address of the poi author')"
                                :hint="$t('Filter by email address of POI author')"
                                @input="debouncePoiAuthor"
                                v-model="poiAuthorFilter" v-on:keyup.enter="getNewPage(true)">

                        </v-text-field>
                    </div>
                </div>
            </div>

            <v-container fluid>
                <v-layout column style="height: 75vh">
                    <div style="overflow: auto" @scroll="onScroll" ref="scrollY">

                        <v-data-table
                                :calculate-widths="true"
                                :headers="headers"
                                :items="poiArray"
                                :items-per-page="poiArray.length"
                                hide-default-footer
                                class="elevation-1 cursorTable"
                                :loading="!initialized"
                                @click:row="handleClick"
                        >

                            <template v-slot:item.actions="{ item }">
                                <v-switch v-if="item.poi_approved_user"
                                          :input-value="!item.poi_approved"
                                          color="red"
                                          :label=" item.poi_approved ? $t('Approved') : $t('Declined')"
                                          :success="item.poi_approved"
                                          @click.stop="togglePOI(item, !item.poi_approved)"
                                >
                                </v-switch>
                                <template v-else>
                                    <v-icon
                                            @click.stop="togglePOI(item, true)"
                                    >
                                        mdi-check
                                    </v-icon>
                                    <v-icon
                                            @click.stop="togglePOI(item, false)"
                                    >
                                        mdi-cancel
                                    </v-icon>
                                </template>

                            </template>

                            <template v-slot:item.poi_create_user_email="{ item }">
                                <table-entry>
                                    {{ item["poi_create_user_email"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.poi_approved_user_email="{ item }">
                                <table-entry>
                                    {{ item["poi_approved_user_email"] }}
                                </table-entry>
                            </template>

                            <template v-slot:item.poi_desc="{ item }">
                                <table-entry>
                                    {{ item.poi_desc | truncate(40, ' ...') }}
                                </table-entry>
                            </template>

                            <template v-slot:item.map="{ item }">
                                <v-icon

                                        @click="navigateToInformation(item)"
                                >
                                    mdi-map-marker-outline
                                </v-icon>
                            </template>

                            <template v-slot:item.delete="{ item }">
                                <table-entry>
                                    <v-icon
                                            @click="deletePoi(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </table-entry>
                            </template>

                            <template v-slot:item.poi_uuid="{ item }">
                                <table-entry>
                                    {{ item["poi_uuid"] }}
                                </table-entry>
                                <span :ref="`poi-row-${item.poi_uuid}`">

                </span>
                            </template>

                            <template v-slot:item.poi_name="{ item }">
                                <table-entry>
                                    {{ item["poi_name"] }}
                                </table-entry>
                            </template>

                        </v-data-table>

                    </div>
                </v-layout>
            </v-container>
        </div>

    </v-container>
</template>

<script>
import axios from 'axios'
import TableEntry from "@/components/TableEntry";
import DialogConfirmerPromise from "@/components/DialogConfirmerPromise";
import _ from 'lodash';

export default {
    name: "POI",
    components: {DialogConfirmerPromise, TableEntry},
    data: function () {
        return {
            initialized: false,
            headers: [
                {text: this.$t('ID'), value: 'poi_uuid'},
                {text: this.$t('Name'), value: 'poi_name'},
                {text: this.$t('Description'), value: 'poi_desc'},
                {text: this.$t('Actions'), value: 'actions'},
                {text: this.$t('Reviewed by'), value: 'poi_approved_user_email'},
                {text: this.$t('Added by'), value: 'poi_create_user_email'},
                {text: this.$t('Map'), value: 'map'},
                {text: this.$t('Delete'), value: 'delete'}
            ],
            paginationInfo: {
                page: 1,
                pageCount: 1,
                itemsPerPage: 40,
                filter: "all"
            },
            poiArray: [],
            filters: ["all", "approved", "unapproved"],
            poiNameFilter: '',
            poiAuthorFilter: '',
            poiNameFilterDebounced: '',
            poiAuthorFilterDebounced: ''
        }
    },
    methods: {
        getNewPage(filterChanged) {
            if (filterChanged) {
                this.paginationInfo.page = 1
                this.poiArray = []
            }

            this.$store.dispatch('poi/getPoi', {
                offset: (this.paginationInfo.page - 1) * this.paginationInfo.itemsPerPage,
                limit: this.paginationInfo.itemsPerPage,
                filter: this.paginationInfo.filter,
                poiName: this.poiNameFilterDebounced,
                authorName: this.poiAuthorFilterDebounced
            })
                .then(async (res) => {
                    this.poiArray = this.poiArray.concat(res.data.map((it) => {
                        return {
                            ...it,
                            poi_approved_user_email: it["poi_approved_user"] ? it["poi_approved_user"]["user_email"] : "No user has reviewed this POI",
                            poi_create_user_email: it["poi_create_user"] ? it["poi_create_user"]["user_email"] : "No data available for POI author"
                        }
                    }))
                    const paginationInfo = await this.$store.getters["poi/paginationInfo"]

                    this.paginationInfo.page += 1
                    this.paginationInfo.pageCount = paginationInfo.pageCount

                    this.initialized = true
                })
                .catch(e => console.log(e))
        },
        togglePOI(POI, status) {
            axios.get(`${process.env.VUE_APP_API_URL}/easydriver-poi/${POI.poi_uuid}/approve?status=${status ? 'approve' : 'cancel'}`, {withCredentials: true})
                .then((res) => {
                    this.poiArray = this.poiArray.map((poi) => {
                        if (poi.poi_uuid === POI.poi_uuid) {
                            return {
                                ...res.data,
                                poi_approved_user_email: res.data["poi_approved_user"] ? res.data["poi_approved_user"]["user_email"] : "No user has reviewed this POI",
                                poi_create_user_email: res.data["poi_create_user"] ? res.data["poi_create_user"]["user_email"] : "No data available for POI author"
                            }
                        }
                        return poi
                    })
                })
        },
        openGoogleMaps(lat, lon) {
            window.open(`http://www.google.com/maps/place/${lat},${lon}`, "_blank")
        },
        navigateToInformation(item) {
            const scrollY = this.$refs.scrollY.scrollTop

            this.$store.dispatch('poi/setChosenPOI', {
                chosenPOI: item
            })
            this.$store.dispatch('poi/setScrollY', scrollY)
            this.$router.push({
                name: 'PoiInformation',
                params: {
                    'poiOBJ': item,
                    'useCache': true
                }
            });
        },
        onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.getNewPage()
            }
        },
        handleClick(item) {
            this.navigateToInformation(item)
        },
        async deletePoi(item) {
            const res = await this.$refs.dialogConfirmer.fireAlert(this.$t('Delete POI'),
                this.$t('Deny'),
                this.$t('Delete POI'),
                this.$t('Do you really want to delete this POI?')
            );

            if (res) {
                await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-poi/${item.poi_uuid}`);
                this.poiArray = this.poiArray.filter((it) => it.poi_uuid !== item.poi_uuid);
            }
        },

        debouncePoiAuthor: _.debounce(function (e) {
            this.poiAuthorFilterDebounced = e;
        }, 500),

        debouncePoiName: _.debounce(function (e) {
            this.poiNameFilterDebounced = e;
        })
    },
    async mounted() {
        if (this.$store.getters["poi/currentPOIs"].length > 0) {
            const paginationInfo = this.$store.getters['poi/paginationInfo'];
            const filters = this.$store.getters['poi/filters']
            const scrollY = this.$store.getters['poi/scrollY']

            this.paginationInfo.page = paginationInfo.page
            this.paginationInfo.pageCount = paginationInfo.pageCount
            this.paginationInfo.filter = paginationInfo.filter
            this.initialized = true
            this.poiArray = this.$store.getters['poi/currentPOIs'];

            this.poiNameFilter = filters.poiNameFilter
            this.poiAuthorFilter = filters.poiAuthorFilter

            this.poiNameFilterDebounced = filters.poiNameFilter
            this.poiAuthorFilterDebounced = filters.poiAuthorFilter

            setTimeout(() => {
                this.$refs.scrollY.scrollTo(0, scrollY)
            }, 100)

        } else {
            await this.getNewPage(false)
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix
            } else {
                return text
            }
        }
    },
    watch: {
        poiNameFilterDebounced(author, oldAuthor) {
            if (author !== oldAuthor) {
                this.getNewPage(true);
            }
        },
        poiAuthorFilterDebounced(name, oldName) {
            if (name !== oldName) {
                this.getNewPage(true);
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('poi/setPOIs', {
            currentPOIs: this.poiArray
        });
        const obj = {
            poiNameFilter: this.poiNameFilterDebounced,
            poiAuthorFilter: this.poiAuthorFilterDebounced
        }

        console.log(obj, 'obj')
        this.$store.dispatch('poi/setFilters', obj)
    }
}
</script>

<style scoped>
.v-table tr:hover:not(.v-table__expanded__content) {
    background: red !important;
}

.filter_container {
    display: flex;
    flex-direction: row;
}

.filter_item {
    margin-left: 2%;
}
</style>

<template>
  <v-container>
      <v-row>
          <v-col class="col-md-3 col-8">
              <v-text-field
                      :placeholder="$t('Sensor UID or Sensor name')"
                      @input="sensorFilterDebounce"
                      :value="sensorFilter"
              />
          </v-col>
          <v-col class="col-md-3 col-8">
              <v-text-field
                      :placeholder="$t('Email address')"
                      @input="emailFilterDebounce"
                      :value="email"
              />
          </v-col>
          <v-col class="col-md-3 float-right col-2">
              <v-tooltip

              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                              class="mt-2 ml-15"
                              icon
                              v-bind="attrs"
                              v-on="on"
                      >
                          <v-icon color="grey lighten-1">
                              mdi-information
                          </v-icon>
                      </v-btn>
                  </template>
                  <span>
              {{$t("To search for sensors by Sensor UID, Sensor name or sensor owner Email address simply enter them in the input field and the query will be triggered automatically")}}
            </span>
              </v-tooltip>
          </v-col>

          <v-col class="col-md-3 col-8 totalCount">
              <p> {{ $t('Sensors matching filter:') }} <b>{{ totalCount }}</b> </p>
          </v-col>

      </v-row>

      <div
          ref="myScroll"
          class="mt-15 sensorsTable"
          @scroll="onScroll"
      >
          <SensorsTable
              :items="sensorData"
              :is-fetching="isFetching"
              :email-filter="email"
              :sensor-filter="sensorFilter"
              @caravanClick="onCaravanClick"
          />
      </div>
  </v-container>
</template>

<script>
import SensorsTable from "@/components/SensorsTable.vue";
import _ from "lodash";
export default {
    name: "Sensors",
    components: {SensorsTable},
    data: function () {
        return {

        }
    },
    methods: {
        emailFilterDebounce: _.debounce(function (e) {
            this.$store.dispatch('sensors/setEmail', e)
        }, 500),
        sensorFilterDebounce: _.debounce(function (e) {
            this.$store.dispatch('sensors/setSensor', e)
        }, 500),
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.$store.dispatch('sensors/fetchSenors')
            }
        },
        async onCaravanClick(item) {
            const vehicleRes = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-caravan-profile/${item.vehicleId}`)
            const scrollY = this.$refs.myScroll.scrollTop
            this.$store.dispatch('sensors/setScrollY', scrollY)

            await this.$router.push({
                name: 'VehicleInfo',
                params: {'vehicleOBJ': vehicleRes.data}
            })
        }
    },
    computed: {
        sensorData: function () {
            return this.$store.getters['sensors/sensorData']
        },
        email: function () {
            return this.$store.getters['sensors/email']
        },
        sensorFilter: function () {
            return this.$store.getters['sensors/sensorFilter']
        },
        isFetching: function () {
            return this.$store.getters['sensors/isFetching']
        },
        totalCount: function () {
            return this.$store.getters['sensors/totalCount']
        }
    },
    watch: {
        sensorFilter (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$store.dispatch('sensors/fetchSenors', true)
            }
        },
        email (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$store.dispatch('sensors/fetchSenors', true)
            }
        }
    },
    created() {
        let cachedValues = this.$store.getters['sensors/sensorData']
        let scrollY = this.$store.getters['sensors/scrollY']

        if (cachedValues.length > 0) {
            // Move to scroll Y position
            setTimeout(() => {
                this.$refs.myScroll.scrollTo(0, scrollY)
            }, 100)
        } else {
            this.$store.dispatch('sensors/fetchSenors')

        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.sensorsTable {
    height: 75vh;
    overflow: auto;
}
.totalCount {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

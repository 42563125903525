<template>
  <v-app v-if="this.$store.getters['auth/isLoggedIn']">
    <Spinner/>
    <Snackbar/>
    <v-navigation-drawer
        app
        color="#282424"
        v-model="drawer"
        :permanent="show"
        :temporary="!show"
        >


      <v-list-item-title class="ml-2 mt-2">
        <span class="navigation_drawer_title">RK Reich </span>
      </v-list-item-title>

      <v-list-item-group v-model="group" class="mt-5 ml-2" nav dense>
        <v-list-item-title
            v-for="(item, index) in navigationDrawerItems"
            :key="item.title"
            link
            active-class="nonselected"
            @click="$store.dispatch('routerLocation/changePosition', index)" :class="routerIndex === index ? 'selected' : 'nonselected'"
        >

          <router-link :to="item.link" v-if="item.link" tag="div" style="flex-shrink: 0; display: flex; width: 120%">
            <v-list-item-icon>
              <v-icon color="white">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="navigation_drawer_item">{{$t(item.title)}}</v-list-item-title>
            </v-list-item-content>
          </router-link>

          <div v-else style="flex-shrink: 0; display: flex; width: 120%" @click="item.click">
            <v-list-item-icon>
              <v-list-item-icon>
                <v-icon color="white">{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="navigation_drawer_item"> {{$t(item.title)}} </v-list-item-title>
              </v-list-item-content>
            </v-list-item-icon>
          </div>



        </v-list-item-title>
      </v-list-item-group>
    </v-navigation-drawer>

    <v-app-bar app dark v-if="!show">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" small></v-app-bar-nav-icon>
      <v-toolbar-title class="ma-auto">
        <h1 class="app_bar_title">{{navigationDrawerItems[routerIndex].title}}</h1>
      </v-toolbar-title>
    </v-app-bar>

    <v-main :class="{login_background: !this.$store.getters['auth/isLoggedIn']}">

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>

      <div class="loaderContainer" v-if="firmwareUploadPending">
        <Loader
            title="Your firmware is being uploaded ..."
            :progress="firmwareUploadProgress"
        />
      </div>
    </v-main>
    <v-expand-transition v-if="show && footer">
      <v-footer
          app
          color="#282424"
          width="110vw"
          class="ma-auto"
      >
          <div class="logo_container">
            <v-img src="@/assets/solvesall_logo.png" max-height="200px" max-width="200px" class="ma-auto"></v-img>
          </div>
      </v-footer>
    </v-expand-transition>

  </v-app>

  <v-app v-else>
    <ForgotPassword/>
    <v-main>
      <Snackbar/>
      <router-view> </router-view>
    </v-main>
  </v-app>

</template>

<script>
import Snackbar from "@/components/Snackbar";
import Spinner from "@/components/Spinner";
import ForgotPassword from "@/components/ForgotPassword";
import Loader from "@/components/Loader";
export default {
  name: 'App',

  components: {
    Loader,
    ForgotPassword,
    Spinner,
    Snackbar
  },

  data() {
    return {
      drawer: true,
      navigationDrawerItems: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/dashboard'},
        {title: 'Users', icon: 'mdi-account-multiple', link: '/benutzer'},
        {title: 'Manufacturer', icon: 'mdi-factory', link:'/hersteler'},
        {title: 'Document', icon: 'mdi-text-box-multiple-outline', link: '/documente'},
        // {title: 'Account', icon: 'mdi-account', link: '/account'},
        // {title: 'Configuration', icon: 'mdi-cogs', link: '/configuration'},
        {title: 'User Issues', icon: 'mdi-bug', link: '/issues'},
        {title: 'Places', icon: 'mdi-map-marker', link: '/orte'},
        {title: 'Comments', icon: 'mdi-comment-text-multiple-outline', link: '/kommentare'},
        {title: 'Vehicle profiles', icon: 'mdi-rv-truck', link: '/fahrzeugprofile'},
        {title: 'Sensors', icon: 'mdi-leak', link: '/sensors'},
        {title: 'Messaging', icon: 'mdi-message-reply-text', link: '/messaging'},
        {title: 'Easydriver', icon: 'mdi-cogs', link: "/easydriver"},
        {title: 'Log out', icon: 'mdi-logout', click: this.logoutUser},
      ],
      group: null,
      selectedIndex: 0
    }
  },
  beforeMount() {
    // Before we mount root component we send request to backend to get session information
    // This happens when user reloads the page or closes the browser
      this.$store.dispatch('auth/getUserFromSession')
          .then(() => {
            this.$router.push({
              path: "/dashboard"
            })
          })
  },
  methods: {
    logoutUser:function () {
      this.$store.dispatch('auth/logoutUser')
      .catch(() => {
        this.$store.dispatch('snackbar/fireAlert', {
          "message": "Unexpected server error has occurred",
          "color": "error"
        })
      })
    },
    read_cookie: function (name)
    {
      return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    },
  },
  watch: {
    group() {
      this.drawer = false
    }
  },
  mounted() {
  },
  computed: {
    show() {
      const screen = this.$vuetify.breakpoint.name;
      return screen !== 'xs' && screen !== 'sm'
    },
    routerIndex() {
      return this.$store.getters['routerLocation/position']
    },
    footer() {
      return this.$store.getters["footer/footer"];
    },
    firmwareUploadPending() {
      return this.$store.getters['easydriver/easyDriverFwUploadPending'];
    },
    firmwareUploadProgress() {
      return this.$store.getters['easydriver/easyDriverFwUploadProgress'];
    },
  }
};
</script>
<style scoped>
a{
  text-decoration: none;
}

.login_background {
  background-image: url("assets/background.jpeg");
}
.navigation_drawer_title {
  color: white;
  font-size: 2em;
  font-weight: lighter;
}
.navigation_drawer_item {
  color: white;
}
.app_bar_title{
  text-align: center;
}
.selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.nonselected {
  background-color: rgba(0, 0, 0, 0.0);
  transition: 0.5s;
}
.image {
  max-width: 200px;
}
.footer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo_container {
  width: 100%;
}
.selected:hover{
  cursor: pointer;
}
.nonselected:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.loaderContainer {
  position: absolute;
  bottom: 50px;
  right: 25px;
}
</style>

<template>
  <table border="0" cellpadding="0" cellspacing="0" class="body">
    <tr>
      <td>&nbsp;</td>
      <td class="container">
        <div class="content">
          <span class="preheader"></span>
          <table class="main">

            <!-- START MAIN CONTENT AREA -->
            <tr>
              <td class="wrapper">
                <table border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td>
                      <table class="photo">
                        <tr>
                          <td>
                            <h1 class="header">MyCaravan</h1>
                          </td>
                        </tr>
                      </table>
                      <h1 style="font-size: 1rem">{{notificationOBJ.emailNotificationTitle !== '' ? notificationOBJ.emailNotificationTitle : this.$t('Email Notification Title')}}</h1>
                      <p style="font-size: 0.75rem; max-width: 80% ">
                        {{notificationOBJ.emailNotificationContent !== '' ? notificationOBJ.emailNotificationContent : this.$t('Email Notification Content')}}
                      </p>

                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <!-- END MAIN CONTENT AREA -->
          </table>


          <!-- START FOOTER -->
          <div class="footer">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>

              </tr>
              <tr>
                <td class="content-block powered-by">
                  If you received this email by mistake, simply delete it.
                </td>
              </tr>
            </table>
          </div>
          <!-- END FOOTER -->

          <!-- END CENTERED WHITE CONTAINER -->
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "EmailTemplate",
  props: [
      'notificationOBJ'
  ]
}
</script>

<style scoped>
*{
  font-family: 'Roboto', sans-serif;
}
.header {
  margin-top: 20%;
  font-size: 1.5rem;
  font-weight: lighter;
  color: white;
  text-align: center;
}
.photo {
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size:  700px;
  background-image:url('../assets/background.jpeg');
}
img {
  border: none;
  -ms-interpolation-mode: bicubic;
  max-width: 100%; }
body {
  background-color: #f6f6f6;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
table {
  border-collapse: separate;
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
  width: 100%; }
table td {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  vertical-align: top; }
/* -------------------------------------
    BODY & CONTAINER
------------------------------------- */
.body {
  background-color: #f6f6f6;
  width: 100%; }
/* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
.container {
  display: block;
  Margin: 0 auto !important;
  /* makes it centered */
  max-width: 400px;
  padding: 10px;
  width: 400px; }
/* This should also be a block element, so that it will fill 100% of the .container */
.content {
  box-sizing: border-box;
  display: block;
  Margin: 0 auto;
  max-width: 400px;
  padding: 10px; }
/* -------------------------------------
    HEADER, FOOTER, MAIN
------------------------------------- */
.main {
  background: #fff;
  border-radius: 3px;
  width: 100%; }
.wrapper {
  box-sizing: border-box;
  padding: 20px; }
.footer {
  clear: both;
  padding-top: 10px;
  text-align: center;
  width: 100%; }
.footer td,
.footer p,
.footer span,
.footer a {
  color: #999999;
  font-size: 12px;
  text-align: center; }
/* -------------------------------------
    TYPOGRAPHY
------------------------------------- */
h1,
h2,
h3,
h4 {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  Margin-bottom: 30px; }
h1 {
  font-size: 35px;
  font-weight: 300;
  text-align: center;
  text-transform: capitalize; }
p,
ul,
ol {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  Margin-bottom: 15px; }
p li,
ul li,
ol li {
  list-style-position: inside;
  margin-left: 5px; }
a {
  color: #3498db;
  text-decoration: underline; }
/* -------------------------------------
    BUTTONS
------------------------------------- */
.btn {
  box-sizing: border-box;
  width: 100%; }
.btn > tbody > tr > td {
  padding-bottom: 15px; }
.btn table {
  width: auto; }
.btn table td {
  background-color: #ffffff;
  border-radius: 2px;
  text-align: center; }
.btn a {
  background-color: #ffffff;
  border: solid 1px #3498db;
  border-radius: 5px;
  box-sizing: border-box;
  color: #3498db;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 12px 25px;
  text-decoration: none;
  text-transform: capitalize; }
.btn-primary table td {
  background-color: #282424 }
.btn-primary a {
  background-color: #282424;
  border-color: #282424;
  color: #ffffff; }
/* -------------------------------------
    OTHER STYLES THAT MIGHT BE USEFUL
------------------------------------- */
.last {
  margin-bottom: 0; }
.first {
  margin-top: 0; }
.align-center {
  text-align: center; }
.align-right {
  text-align: right; }
.align-left {
  text-align: left; }
.clear {
  clear: both; }
.mt0 {
  margin-top: 0; }
.mb0 {
  margin-bottom: 0; }
.preheader {
  color: transparent;
  display: none;
  height: 0;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  mso-hide: all;
  visibility: hidden;
  width: 0; }
.powered-by a {
  text-decoration: none; }
hr {
  border: 0;
  border-bottom: 1px solid #f6f6f6;
  Margin: 20px 0; }
/* -------------------------------------
    RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */
@media only screen and (max-width: 620px) {
  table[class=body] h1 {
    margin-bottom: 10px !important; }
  table[class=body] p,
  table[class=body] ul,
  table[class=body] ol,
  table[class=body] td,
  table[class=body] span,
  table[class=body] a {
    font-size: 16px !important; }
  table[class=body] .wrapper,
  table[class=body] .article {
    padding: 10px !important; }
  table[class=body] .content {
    padding: 0 !important; }
  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important; }
  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important; }
  table[class=body] .btn table {
    width: 100% !important; }
  table[class=body] .btn a {
    width: 100% !important; }
  table[class=body] .img-responsive {
    height: auto !important;
    max-width: 100% !important;
    width: auto !important; }}
@media all {
  .ExternalClass {
    width: 100%; }
  .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
    line-height: 100%; }
  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important; }
  .btn-primary table td:hover {
    background-color: #34495e !important; }
  .btn-primary a:hover {
    background-color: #34495e !important;
    border-color: #34495e !important; } }
</style>

import {getSensors} from "@/services/sensorService";

export const sensors = {
    namespaced: true,
    state: () => ({
        sensors: [],
        sensorsPaginationInfo: {
            offset: 0,
            limit: 40
        },
        sensorsFetchInProgress: false,
        sensorsFetchController: undefined,
        emailAddressFilter: '',
        sensorFilter: '',
        count: 0,
        scrollY: 0
    }),
    mutations: {
        setFetching(state, fetching) {
            state.sensorsFetchInProgress = fetching
        },
        setFetchController(state, controller) {
            state.sensorsFetchController = controller
        },
        setSensorData(state, sensors) {
            state.sensors = [
                ...state.sensors,
                ...sensors
            ]
        },
        setSensorFilter(state, sensor) {
            state.sensorFilter = sensor
        },
        setEmail(state, val) {
            state.emailAddressFilter = val
        },
        setCountData(state, val) {
            state.count = val
        },
        markNextPage(state) {
            state.sensorsPaginationInfo = {
                ...state.sensorsPaginationInfo,
                offset: state.sensorsPaginationInfo.offset + state.sensorsPaginationInfo.limit
            }
        },
        paginationToDefault(state) {
            state.sensorsPaginationInfo = {
                offset: 0,
                limit: 40
            }
            state.sensors = []
        },
        cleanCache(state) {
            state.sensors = []
            state.sensorsPaginationInfo = {
                offset: 0,
                limit: 40
            }
            state.sensorsFetchInProgress = false
            state.emailAddressFilter = ''
            state.sensorFilter = ''
            state.count = 0
            state.scrollY = 0
        },
        setScrollY(state, scrollY) {
            state.scrollY =  scrollY
        }
    },
    actions: {
        async fetchSenors(context, clear) {
            if (clear) {
                context.commit('paginationToDefault')
            }
            if (context.state.sensorsFetchInProgress && !clear) {
                return
            }

            try {
                if (context.state.sensorsFetchController && clear) {
                    context.state.sensorsFetchController.abort()
                    await context.commit('setFetchingController', undefined)
                }
                const controller = new AbortController()
                await context.commit('setFetching', true)
                await context.commit('setFetchController', controller)
                const data = await getSensors(
                    context.state.sensorFilter,
                    context.state.sensorFilter,
                    context.state.emailAddressFilter,
                    context.state.sensorsPaginationInfo.offset,
                    context.state.sensorsPaginationInfo.limit,
                    controller
                )

                const sensors = data.result.map((it) => it.extraInfo ? {...it} : {
                    ...it,
                    extraInfo: undefined
                })
                context.commit('setSensorData', sensors)
                context.commit('setCountData', data.count)
            } catch (e) {
                console.log(e)
            } finally {
                await context.commit('setFetching', false)
                await context.commit('setFetchController', undefined)
                await context.commit('markNextPage')
            }
        },
        setSensor(context, val) {
            context.commit('setSensorFilter', val)
        },
        setEmail(context, val) {
            context.commit('setEmail', val)
        },
        setScrollY(context, scrollY) {
            context.commit('setScrollY', scrollY)
        },
        cleanCache(context) {
            context.commit('cleanCache')
        }
    },
    getters: {
        sensorData: state => state.sensors,
        email: state => state.emailAddressFilter,
        sensorFilter: state => state.sensorFilter,
        isFetching: state => state.sensorsFetchInProgress,
        totalCount: state => state.count,
        scrollY: state => state.scrollY
    }
}

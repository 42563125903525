import {errorHandler} from "@/utils/errorHandler";
const actionScope = 'snackbar';
const logoutScope = 'auth'

export const setupInterceptor = (httpClient) => ({ dispatch }, quite) => {
    let requestsPending = 0

    const req = {
        pending: () => {
            requestsPending ++;
            dispatch(`${actionScope}/startLoading`)
        },
        done: () => {
            requestsPending --;
            if (requestsPending <= 0) {
                dispatch(`${actionScope}/stopLoading`)
            }
        }
    };

    httpClient.interceptors.request.use(
        config => {
            if (!quite) req.pending()
            return config
        },
        error => {
            requestsPending --;
            if(!quite) req.done();
            return Promise.reject(error)
        }
    )
    httpClient.interceptors.response.use(
        (res) => {
            req.done()
            return Promise.resolve(res)
        },
        (error)=> {
            req.done()
            const errOBJ = errorHandler(error)

            if (errOBJ.code === 1037) {
                // Logout user - as session has expired
                dispatch(`${logoutScope}/logoutUser`)
                    .then(() => {
                        dispatch(`${actionScope}/fireAlert`, {
                            message: errOBJ.message,
                            color: errOBJ.color
                        })
                    })
            } else {
                if (!quite) {
                    dispatch(`${actionScope}/fireAlert`, {
                        message: errOBJ.message,
                        color: errOBJ.color
                    })
                }
            }

            return Promise.reject(error)
        }
   )
}


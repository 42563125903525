<template>
  <div class="checkBoxMainContainer" @click="check">
    <div class="checkBoxContainer">
      <div class="checkBoxFill" :class="`checkBoxFill${text.split(' ').join('')}-${id}`">
        <v-icon size="12px" color="white">
          mdi-check-bold
        </v-icon>
      </div>
    </div>
    <div class="checkBoxText">
      {{text}}
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
export default {
  name: "ReichCheckbox",
  props: ['value', 'text'],
  data: () => {
    return {
      id: ''
    }
  },
  beforeMount() {
    this.id = Math.random().toString(36).slice(2, 7);
  },
  methods: {
    check() {
      this.$emit('valueChange', !this.value)
    },
    animateSelection() {
      anime({
        targets: `.checkBoxFill${this.text.split(" ").join("")}-${this.id}`,
        scale: this.value ? 1 : 0,
      })
    }
  },
  watch: {
    value() {
      this.animateSelection();
    }
  },
  mounted() {
    this.animateSelection();
  }
}
</script>

<style>
.checkBoxContainer {
  border: 2px solid rgba(0, 0, 0, 0.5);
  height: 15px;
  width: 15px;
}
.checkBoxMainContainer {
  display: flex;
  align-items: center;
}
.checkBoxText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 3px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
}
.checkBoxMainContainer:hover {
  cursor: pointer;
}
.checkBoxFill {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  transform: scale(0);
  justify-content: center;
  align-items: center;
  display: flex;
}

</style>
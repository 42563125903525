<template>
  <div class="tableHeaderContainer" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="handleClick">
    <div class="tableHeaderText">
      <span :class="activeClass">
         {{ this.tableHeader }}
      </span>
    </div>
    <div class="tableHeaderIcon">
      <SortIcon
          :sort="sort"
          :activated="activated"
          :hovered="hovered"
      />
    </div>
  </div>
</template>

<script>
import SortIcon from "@/components/SortIcon";

export default {
  name: "TableHeaderItem",
  components: {SortIcon},
  props: ['tableHeader', 'activated'],
  data: function () {
    return {
      hovered: false,
      sort: 'DESC'
    }
  },
  methods: {
    onMouseEnter() {
      this.hovered = true
    },
    onMouseLeave() {
      this.hovered = false
    },
    handleClick() {
      if (!this.activated) {
        this.$emit('onSortActivated');
        this.$emit('onSortChange', this.sort === 'DESC');
        return;
      }

      if (this.sort === 'DESC') {
        this.sort = 'ASC';
      } else {
        this.sort = 'DESC';
      }
      // if desc emit true else emit false
      this.$emit('onSortChange', this.sort === 'DESC');
    }
  },
  computed: {
    activeClass() {
      return this.activated || this.hovered ? 'activeText' : 'inactiveText';
    }
  },
  watch: {
    activated(newVal) {
      if (!newVal) {
        this.sort = 'ASC'
      }
    }
  }
}
</script>

<style scoped>
.tableHeaderContainer {
  display: flex;
  align-items: center;
}

.tableHeaderContainer:hover {
  cursor: pointer;
}

.inactiveText {
  transition: 0.5s ease-in;
}

.activeText {
  color: black;
  transition: 0.5s ease-in;
}
</style>

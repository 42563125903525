import VueRouter from 'vue-router'
import Vue from 'vue'


Vue.use(VueRouter)

import Login from "@/components/Login";
import Users from "@/components/Users";
import Vehicles from "@/components/Vehicles";
import EditVehicle from "@/components/EditVehicle";
import Manufacturers from "@/components/Manufacturers";
import VehicleInfo from "@/components/VehicleInfo";
import PointOfInterest from "@/components/PointOfInterest";
import PoiInfo from "@/components/PoiInfo";
import PoiComments from "@/components/PoiComments";
import Documents from "@/components/Documents";
import DocumentInfo from "@/components/DocumentInfo";
import UserIssues from "@/components/UserIssues";
import UserVehicles from "@/components/UserVehicles";
import Messaging from "@/components/Messaging";
import Easydriver from "@/components/Easydriver.vue";
import Sensors from "@/components/Sensors.vue";
import HomeScreen from "@/components/HomeScreen.vue";

const routes = [
    {
        path: "/login",
        component: Login,
        name: "Login"
    },
    {
        path: "/dashboard",
        component: HomeScreen,
        name: "Dashboard"
    },
    {
        path: "/benutzer",
        component: Users,
        name: "Users"
    },
    {
        path: "/fahrzeugprofile",
        component: Vehicles,
        name: "Vehicles"
    },
    {
        path: '/fahrzeugprofile/bearbeiten',
        component: EditVehicle,
        name: "EditVehicle",
        props: true
    },
    {
        path: '/hersteler',
        component: Manufacturers,
        name: 'Manufacturers'
    },
    {
        path: '/fahrzeugprofile/:vehicleId/information',
        component: VehicleInfo,
        name: 'VehicleInfo',
        props: true
    },
    {
        path: '/orte',
        component: PointOfInterest,
        name: 'PointOfInterest',
        props: true
    },
    {
        path: '/orte/information',
        component: PoiInfo,
        name: 'PoiInformation',
        props: true
    },
    {
        path: '/kommentare',
        component: PoiComments,
        name: 'PoiComments',
        props: true
    },
    {
        path: '/documente',
        component: Documents,
        name: 'Documents',
        props: true
    },
    {
        path: '/documente/info',
        component: DocumentInfo,
        name: 'DocumentInfo',
        props: true
    },
    {
        path: '/issues',
        component: UserIssues,
        name: 'UserIssues',
        props: true
    },
    {
        path:'/benutzer/:userId/info',
        component: UserVehicles,
        name: 'UserVehicles'
    },
    {
        path: '/messaging',
        component: Messaging,
        name: 'Messaging'
    },
    {
        path: "/easydriver",
        component: Easydriver,
        name: "Easydriver"
    },
    {
        path: '/sensors',
        component: Sensors,
        name: 'Sensors'
    }
]



export const router = new VueRouter({
    routes
})

const userCacheValid = (from, to) => {
    let fromRoute = from.name
    let toRoute = to.name

    return (fromRoute === "Users" && toRoute === "UserVehicles") || (fromRoute === "UserVehicles" && toRoute === "Users") || (
        fromRoute === "UserVehicles" && toRoute === "VehicleInfo") || (fromRoute === 'VehicleInfo' && toRoute === 'UserVehicles')
        ||(fromRoute === "VehicleInfo" && toRoute === "Users")
}

const poiCommentsCacheValid = (from, to) => {
    let fromRoute = from.name
    let toRoute = to.name

    return (fromRoute === "PoiComments" && toRoute === "PoiInformation") ||
        (fromRoute === "PoiInformation" && toRoute === "PoiComments")
}

const vehicleCacheValid = (from, to) => {
    let fromRoute = from.name
    let toRoute = to.name

    return (fromRoute === "Vehicles" && toRoute === "VehicleInfo") || (fromRoute === "VehicleInfo" && toRoute === "Vehicles")
}

const sensorCacheValid = (from, to) => {
    let fromRoute = from.name
    let toRoute = to.name

    return (fromRoute === "Sensors" && toRoute === "VehicleInfo") || (fromRoute === "VehicleInfo" && toRoute === "Sensors")
}

const poiCacheValid = (from, to) => {
    let fromRoute = from.name
    let toRoute = to.name

    return (fromRoute === "PointOfInterest" && toRoute === "PoiInformation") || (fromRoute === "PoiInformation" && toRoute === "PointOfInterest")
}

router.beforeEach((to, from, next) => {

    if (to && from) {
        // Check if you should use user cache
        const validUserCache = userCacheValid(from, to)
        if (!validUserCache) {
            router.app.$store.dispatch('user/cleanCache')
        }

        const validPoiCommentsCache = poiCommentsCacheValid(from, to)
        if (!validPoiCommentsCache) {
            router.app.$store.dispatch('poiComments/cleanCache')
        }

        const validVehicleCache = vehicleCacheValid(from, to)
        if (!validVehicleCache) {
            router.app.$store.dispatch('caravan/cleanCache')
        }

        const validSensorCache = sensorCacheValid(from, to)
        if (!validSensorCache) {
            router.app.$store.dispatch('sensors/cleanCache')
        }

        const validPoiCache = poiCacheValid(from, to)
        if (!validPoiCache) {
            router.app.$store.dispatch('poi/cleanCache')
        }
    }

    if (!router.app.$store.getters["auth/isLoggedIn"] && to.path !== "/login") {
        next("/login")
    }
    else if (router.app.$store.getters["auth/isLoggedIn"] && to.path === "/login"){
        next("/dashboard")
    }
    else {
        next()
    }
})

router.afterEach(() => {
    Vue.nextTick(() => {
        document.title = "RK Reich"
    })
})

export default router

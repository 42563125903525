<template>
  <span>
    <template v-if="!appliedFilter || matchStart === -1">
      {{ fieldValue }}
    </template>
    <template v-else>
      {{ fieldValue.slice(0, matchStart) }}<span style="font-weight: bold; color: #1565C0;">{{ fieldValue.slice(
          matchStart,
          matchStart + appliedFilter.length
      )}}</span>{{ fieldValue.slice(matchStart + appliedFilter.length, fieldValue.length) }}
    </template>
  </span>
</template>

<script>
export default {
    name: 'FilteredField',
    props: {
        fieldValue: {
            type: String,
            required: true,
        },
        appliedFilter: {
            type: String,
            default: '',
        },
    },
    computed: {
        matchStart() {
            return this.fieldValue.toLowerCase().indexOf(this.appliedFilter.toLowerCase())
        }
    }
};
</script>

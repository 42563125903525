var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogConfirmerPromise',{ref:"decisionModal"}),_c('v-row',{staticClass:"align-center pa-0 ma-0 mt-3"},[_c('v-col',{staticClass:"col-lg-6"},[_c('v-form',{ref:"myForm"},[_c('v-text-field',{attrs:{"label":_vm.$t('Vehicle ID'),"placeholder":_vm.$t('Vehicle ID'),"color":"rgba(0, 0, 0, 0.5)","value":_vm.vehicleId ? _vm.vehicleId : ''},on:{"input":_vm.debounceVehicleId}})],1)],1),_c('v-col',{staticClass:"col-lg-5 side_title"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t("Search by vehicle ID"))+" ")])],1)],1),_c('div',{staticClass:"mt-5 logsTable",on:{"scroll":_vm.onScroll}},[(_vm.data)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":_vm.data.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(item.caravanId)+" ")])]}},{key:"item.moverId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(item.moverId)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(_vm.getHumanReadableDate(item.date))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"idItem"},[_vm._v(" "+_vm._s(_vm.getTypeString(item.type.type))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.issueLogDownload(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.issueDeleteLog(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,3217232272)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="pa-0 ma-0" :loading="loading">
      <v-card-title class="pa-0 ma-0">
          <v-card-title>
              {{ title }}
          </v-card-title>
      </v-card-title>
      <v-card-text class="text-center mt-5">
          <span class="text-center mainText">
              {{ loading ? '?' : text.toLocaleString() }}
          </span>
          <div class="iconContainer mt-5">
              <v-icon size="55">
                  {{ icon }}
              </v-icon>
          </div>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
    name: "KpiCard",
    props: {
        title: {
            required: true,
            type: String
        },
        text: {
            required: true,
            type: String
        },
        icon: {
            required: true,
            type: String
        },
        loading: {
            required: false,
            default: true
        }
    }
}
</script>

<style scoped>
.mainText {
    font-size: 3.4rem;
    font-weight: bold;
}
.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<template>
  <v-container v-if="initialized">
    <v-data-table
        :headers="headers"
        :items="documentsArrData"
    >

      <template v-slot:item.actions="{ item }">

        <v-icon
            @click="openConfirmDialog(item)">
          mdi-delete
        </v-icon>
        <v-icon
            class="ml-2"
            @click="updateDoc(item)"
        >
          mdi-pencil
        </v-icon>
      </template>

      <template v-slot:item.document_time_create="{ item }">
        {{new Date(item.document_time_create)}}
      </template>

      <template v-slot:item.document_lang_iso="{ item }">
        <v-select
            @change="item.detectedChange = true"
            :items="langArr"
            v-model="item.updateOBJ.iso"
        >
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.iso }} - {{data.item.name}}
            <country-flag :country="data.item.iso" size="small"></country-flag>
          </template>

          <template slot='selection' slot-scope='{ item }'>
            {{item.iso}} - {{item.name ? item.name : $t('Name not available')}}
            <country-flag :country="item.iso" size="normal" class="ml-2"></country-flag>
          </template>

        </v-select>
      </template>

      <template v-slot:item.thumbnail="{ item }">
        <v-icon
            class="ma-5"
            @click="previewDocument(item)">
          mdi-eye
        </v-icon>
      </template>

      <template v-slot:item.document="{ item }">
        <v-icon
            class="ma-5"
            @click="previewPDF(item)">
          mdi-eye
        </v-icon>
      </template>

    </v-data-table>

    <v-dialog v-model="dialog.preview" max-width="500px">
      <v-dialog-bottom-transition>
        <v-card max-width="500px">
          <v-divider/>
          <v-img :src="thumbnailSrc()" v-if="dialog.preview">

          </v-img>
        </v-card>
      </v-dialog-bottom-transition>
    </v-dialog>

    <v-dialog v-model="dialog.addDocument" max-width="500px">
      <v-dialog-bottom-transition>
        <v-card class="overflow-y-auto overflow-x-hidden">
          <v-card-title>
            {{$t('Add new document')}}
          </v-card-title>
          <v-row class="justify-center">
            <v-col class="col-lg-8" cols="12">
              <v-form ref="createDocumentForm">
                <v-file-input
                    accept="image/png, image/jpeg, image/jpg"
                    :placeholder="$t('Choose a thumbnail')"
                    prepend-icon="mdi-camera"
                    label="Thumbnail"
                    v-model="newDocumentData.thumbnail"
                />
                <v-select
                    prepend-icon="mdi-web"
                    :items="langArr"
                    v-model="newDocumentData.iso"
                >

                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.iso }} - {{data.item.name}}
                    <country-flag :country="data.item.iso" size="small"></country-flag>
                  </template>

                  <template slot='selection' slot-scope='{ item }'>
                    {{item.iso}} - {{item.name ? item.name : $t('Name not available')}}
                    <country-flag :country="item.iso" size="normal" class="ml-2"></country-flag>
                  </template>

                </v-select>

                <v-file-input
                    v-model="newDocumentData.document"
                    accept=".pdf"
                    :placeholder="$t('Pick a document')"
                    prepend-icon="mdi-pdf-box"
                    :label="$t('Document')"
                />
              </v-form>

            </v-col>
            <v-card-actions class="justify-center">
               <v-btn class="ma-2" :disabled="!validDocument" @click="uploadDocument">
                 {{$t('ADD NEW DOCUMENT')}}
               </v-btn>
              <v-btn class="ma-2" @click="dialog.addDocument = false">
                {{$t('DISMISS')}}
              </v-btn>
            </v-card-actions>
          </v-row>
          <v-card-actions class="justify-center mt-5" v-if="loadingValue>0">
            <v-progress-circular
                class="ma-auto"
                :rotate="360"
                :size="100"
                :width="15"
                :value="loadingValue"
                color="teal"
            >
              {{loadingValue}}
            </v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-dialog-bottom-transition>
    </v-dialog>
    <div class="button_container">
      <v-btn
          @click="dialog.addDocument = true"
          class="mx-2"
          fab
          dark
          color="#282424"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>

    <v-dialog v-model="dialog.confirm" persistent max-width="290">
      <v-dialog-bottom-transition>
        <dialog-confirmer
            :title="$t('Do you really want to delete this document?')"
            :text="$t('This means that you can delete this document and no longer retrieve it')"
            :positive="$t('DELETE')"
            :negative="$t('DISMISS')"
            @result="(res) => deleteDocument(res)"
        >

        </dialog-confirmer>
      </v-dialog-bottom-transition>
    </v-dialog>

    <v-dialog v-model="dialog.updateDocument" max-width="500px">
      <v-dialog-top-transition>
        <uploader-modal
            @success="(item) => onUpdateSuccess(item)"
            :doc-o-b-j="documentUpdate"
            @close="dialog.updateDocument=false"
        >

        </uploader-modal>
      </v-dialog-top-transition>
    </v-dialog>

  </v-container>
</template>

<script>
import DialogConfirmer from "@/components/DialogConfirmer";
import UploaderModal from "@/components/UploaderModal";
export default {
  name: "DocumentInfo",
  components: {UploaderModal, DialogConfirmer},
  props: ['documentsArr', 'documentUUID'],
  data: function () {
    return {
      headers: [
        {text: this.$t('Filename'), value: 'document_filename'},
        {text: this.$t('Language'), value: 'document_lang_iso'},
        {text: this.$t('Create time'), value: 'document_time_create'},
        {text: this.$t('Thumbnail'), value: 'thumbnail'},
        {text: this.$t('Document'), value: 'document'},
        {text: this.$t('Actions'), value: 'actions'}
      ],
      langArr: [],
      selected: '',
      documentsArrData: this.documentsArr,
      initialized: false,
      testVal: {
        'document_lang_iso':  {
          "iso": 'bg'
        }
      },
      dialog: {
        preview: false,
        addDocument: false,
        confirm: false,
        updateDocument: false
      },
      selectedForPreview: '',
      newDocumentData: {
        'thumbnail': '',
        'document': '',
        'iso': ''
      },
      documentDelete: '',
      documentUpdate: '',
      loadingValue: 0
    }
  },
  async mounted() {
    await this.getLangArr()
    this.initialized = true
    this.documentsArrData = this.documentsArrData.map((item) => {
      return {
        ...item,
        'updateOBJ': {
          'iso': item.document_lang_iso,
          'id': item.document_uuid,
          'document_filename': item.document_filename
        }
      }
    })
  },
  methods: {
    previewDocument(item) {
      this.selectedForPreview = item
      this.dialog.preview = true
    },
    async deleteDocument(permission) {
      if (!permission) {
        this.dialog.confirm = false
        return
      }

      const documentUUID = this.documentDelete.document_uuid
      const documentISO = this.documentDelete.document_lang_iso.iso
      const documentLangUUID = this.documentDelete.document_lang_uuid
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-documents/${documentUUID}/${documentISO}`)
      this.documentDelete = ''
      this.dialog.confirm = false
      this.documentDelete = false

      this.documentsArrData = this.documentsArrData.filter((item) => item.document_lang_uuid !== documentLangUUID)

      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('Delete document successfully'),
        color: 'success'
      })
    },
    previewPDF(item) {
      const documentISO = item.document_lang_iso.iso

      this.openInNewTab(`${process.env.VUE_APP_API_URL}/easydriver-documents/${item.document_uuid}/${documentISO}/${item.document_filename}`)
    },
    openInNewTab(url) {
      window.open(url, '_blank').focus();
    },
    async getLangArr() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-documents/lang`)
      this.langArr = res.data
    },
    thumbnailSrc() {
      const documentISO = this.selectedForPreview.document_lang_iso.iso
      return `${process.env.VUE_APP_API_URL}/easydriver-documents/${this.selectedForPreview.document_uuid}/${documentISO}/preview`
    },
    async uploadDocument() {
      const iso = this.newDocumentData.iso.iso

      const formData = new FormData()
      formData.append('preview', this.newDocumentData.thumbnail)
      formData.append('document', this.newDocumentData.document)

      const res = await this.$http.post(`${process.env.VUE_APP_API_URL}/easydriver-documents/${this.documentUUID}/${iso}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          const currentProgress = Math.round( (progressEvent.loaded * 100) / totalLength )
          this.loadingValue = currentProgress
        }
      })
      await this.$store.dispatch('snackbar/fireAlert', {
        message: this.$t('New document successfully added'),
        color: 'success'
      })
      this.dialog.addDocument = false
      this.documentsArrData.push({...res.data,
        'updateOBJ': {
          'iso': res.data.document_lang_iso,
          'preview': '',
          'document': ''
        }
      })
      this.resetToDefault()

    },
    openConfirmDialog(item) {
      this.dialog.confirm = true
      this.documentDelete = item
    },
    resetToDefault() {
      this.loadingValue = 0
      this.$refs.createDocumentForm.reset()
    },
    updateDoc(item) {
      this.dialog.updateDocument = true
      this.documentUpdate = item
    },
    onUpdateSuccess(updated) {
      this.documentsArrData = this.documentsArrData.map((item) => {
        if (item.document_lang_uuid === updated.document_lang_uuid) {
          return {
            ...updated,
            'updateOBJ': {
              'iso': updated.document_lang_iso,
              'preview': '',
              'document': ''
            }
          }
        }else {
          return item
        }
      })
    }
  },
  computed: {
    validDocument: function () {
      return this.newDocumentData.document && this.newDocumentData.iso && this.newDocumentData.thumbnail
    }
  }
}
</script>

<style scoped>
.button_container {
  position: fixed;
  bottom: 50px;
  right: 10px;
}
</style>

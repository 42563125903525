<template>
  <v-container v-if="initialized">
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card max-width="500px">
        <v-card-title>
          {{$t('Add new document group')}}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col class="col-lg-8" cols="12">
                <v-text-field
                    v-model="documentGroup.title"
                    required
                    :label="$t('Title of the document group')"
                />
                <v-text-field
                    @keyup="snakeCase"
                    v-model="documentGroup.filename"
                    required
                    :label="$t('Document file name Document')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="ma-4" :disabled="!legalDocument" @click="addNewDocumentGroup">
            {{$t('ADD NEW GROUP')}}
          </v-btn>
          <v-btn class="ma-4" @click="dialog=false">
            {{$t('DISMISS')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-container>
      <v-layout column style="height: 85vh">
        <div style="overflow:auto;" @scroll="onScroll">
          <v-data-table
              fixed-header
              :headers="headers"
              :items="documentArray"
              :items-per-page="documentArray.length"
              hide-default-footer
              class="elevation-1 cursorTable"
              :loading="!initialized"
              @click:row="handleClick"
          >

            <template v-slot:item.title="{ item }">
              <table-entry>
                <v-text-field v-model="item.title" @keydown="item.change=true">

                </v-text-field>
              </table-entry>
            </template>

            <template v-slot:item.id="{ item }">
              <table-entry>
                {{item["id"]}}
              </table-entry>
            </template>

            <template v-slot:item.filename="{ item }">
              <table-entry>
                {{item["filename"]}}
              </table-entry>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                  @click.stop="editDocument(item)"
                  v-if="!item.change">
                mdi-pencil
              </v-icon>

              <v-icon
                  @click.stop="fireDialogConfirm(item)"
                  v-if="!item.change">
                mdi-delete
              </v-icon>

              <v-icon
                  class="ml-2"
                  v-if="item.change"
                  @click.stop="updateDocumentGroup(item)"
              >
                mdi-content-save-outline
              </v-icon>
            </template>

          </v-data-table>
        </div>
      </v-layout>
    </v-container>

    <div class="button_container">
      <v-btn
          @click="dialog = true"
          class="mx-2"
          fab
          dark
          color="#282424"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>

    <v-dialog v-model="dialogConfirm" persistent max-width="290">
      <v-dialog-bottom-transition>
        <dialog-confirmer
            :title="$t('Are you sure you want to delete the entire document group?')"
            :text="$t('Group delete warning')"
            :positive="$t('DELETE')"
            :negative="$t('DISMISS')"
            @result="(res) => deleteDocumentGroup(res)"
        >
        </dialog-confirmer>
      </v-dialog-bottom-transition>
    </v-dialog>
  </v-container>
</template>

<script>
import DialogConfirmer from "@/components/DialogConfirmer";
import TableEntry from "@/components/TableEntry";
import {i18n} from "@/i18n/i18n";
export default {
  name: "Documents",
  components: {TableEntry, DialogConfirmer},
  data: function () {
    return {
      headers: [
        {
          text: i18n.t('ID'), value: 'id'
        },
        {
          text: i18n.t('Title'), value: 'title'
        },
        {
          text: i18n.t('Filename'), value: 'filename'
        },
        {
          text: i18n.t('Actions'), value: 'actions'
        }
      ],
      paginationInfo: {
        page: 0,
        pageCount: 1,
        itemsPerPage: 40
      },
      documentArray: [],
      initialized: false,
      dialog: false,
      dialogConfirm: false,
      documentGroup: {
        title: '',
        filename: ''
      },
      documentGroupDelete: ''
    }
  },
  methods: {
    async getNewPage(){
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-documents?offset=${(this.paginationInfo.page) * this.paginationInfo.itemsPerPage}&limit=${this.paginationInfo.itemsPerPage}`)
      this.documentArray = this.documentArray.concat(res.data)
      this.paginationInfo.page += 1
      const totalCount = parseInt(res.headers['x-total-count'])
      this.paginationInfo.pageCount = Math.ceil(totalCount / this.paginationInfo.itemsPerPage)
    },
    async editDocument(item) {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/easydriver-documents/${item.id}`)
      const documentArr = res.data
      await this.$router.push({
        name: 'DocumentInfo',
        params: {
          documentsArr: documentArr,
          documentUUID: item.id
        }
      })
    },
    snakeCase() {
      this.documentGroup.filename = (this.documentGroup.filename.replace(/[^a-zA-Z-_ ]/g, "")).toLowerCase().split(" ").join("_");
    },
    async addNewDocumentGroup() {
      const res = await this.$http.post(`${process.env.VUE_APP_API_URL}/easydriver-documents`, this.documentGroup)
      this.dialog = false
      await this.$store.dispatch("snackbar/fireAlert", {
        "message": i18n.t('New document group successfully added'),
        "color": "success"
      })
      this.documentArray.push(res.data)
    },
    async deleteDocumentGroup (permission) {

      if(!permission) {
        this.dialogConfirm = false
        return
      }

      await this.$http.delete(`${process.env.VUE_APP_API_URL}/easydriver-documents/${this.documentGroupDelete.id}`)
      this.documentArray = this.documentArray.filter(doc => doc.id !== this.documentGroupDelete.id )
      await this.$store.dispatch('snackbar/fireAlert', {
        message: i18n.t('Successfully delete document group'),
        color: 'success'
      })
      this.documentGroupDelete =  ''
      this.dialogConfirm = false
    },
    fireDialogConfirm (item) {
      this.dialogConfirm = true
      this.documentGroupDelete = item
    },
    async updateDocumentGroup(item) {
      const res = await this.$http.put(`${process.env.VUE_APP_API_URL}/easydriver-documents/${item.id}`, {
        title: item.title
      })
      this.documentArray = this.documentArray.map(item => {
        if (item.id === res.data.id) {
          item = res.data
        }
        return item
      })

      await this.$store.dispatch('snackbar/fireAlert', {
        color: 'success',
        message: i18n.t('Document group title changed successfully')
      })
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getNewPage()
      }
    },
    handleClick(item) {
      this.editDocument(item)
    }
  },
  async mounted() {
    await this.getNewPage()
    this.initialized = true
  },
  computed: {
    legalDocument: function () {
      return this.documentGroup.title && this.documentGroup.filename
    }
  }
}
</script>

<style scoped>
.button_container {
  position: fixed;
  bottom: 50px;
  right: 10px;
}
</style>

const getDefaultState = () => {
    return {};
};
export const snackbar = {
    namespaced: true,
    name: "snackbar",
    state: {
        snack: {},
        loading: true
    },
    mutations: {
        setSnack(state, showSnack) {
            state.snack = { ...showSnack };
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        }
    },
    actions: {
        fireAlert(context, snackOBJ) {
            context.commit('setSnack', snackOBJ)
        },
        startLoading (context) {
            context.commit('startLoading')
        },
        stopLoading (context) {
            context.commit('stopLoading')
        }
    }
};

export const poiComments = {
    namespaced: true,
    state: () => ({
        cachedValues: [],
        positionY: 0,
        paginationInfo: {
            page: 1,
            pageCount: 1,
            itemsPerPage: 40
        }
    }),
    mutations: {
        setCachedValue(state, payload) {
            state.cachedValues = payload
        },
        setScrollPosition(state, payload) {
            state.positionY = payload
        },
        setPaginationInfo(state, payload) {
            state.paginationInfo = payload
        },
        cleanCache(state) {
            state.cachedValues = []
            state.positionY = 0
            state.paginationInfo = {
                page: 1,
                pageCount: 1,
                itemsPerPage: 40
            }
        }
    },
    actions: {
        setCachedValue(context, payload) {
            context.commit('setCachedValue', payload)
        },
        setScrollPosition(context, payload) {
            context.commit('setScrollPosition', payload)
        },
        setPaginationInfo(context, payload) {
            context.commit('setPaginationInfo', payload)
        },
        cleanCache(context) {
            context.commit('cleanCache')
        }
    },
    getters: {
        getScrollPositionY: state => state.positionY,
        getCachedValues: state => state.cachedValues,
        paginationInfo: state => state.paginationInfo
    }
}
